import './SupplierPage.scss';

import GenericButton from '../generic_button/GenericButton';
import TopBarSuppliers from './TopBarSuppliers';
import VerifiedBadge from './imgs/badge.svg';
import { useEffect, useState } from 'react';

import SupplierCard from './SupplierCard';

import CarbonNegative from './imgs/sustainability/carbonNegative.svg';
import CarbonNeutral from './imgs/sustainability/carbonNeutral.svg';
import EthicallyMade from './imgs/sustainability/ethicallyMade.svg';
import GreenEnergy from './imgs/sustainability/greenEnergy.svg';
import LowVoc from './imgs/sustainability/lowVoc.svg';
import MadeLocally from './imgs/sustainability/madeLocally.svg';
import Recyclable from './imgs/sustainability/recyclable.svg';
import Recycled from './imgs/sustainability/recycled.svg';
import Regenerative from './imgs/sustainability/regenerative.svg';
import Sustainable from './imgs/sustainability/sustainable.svg';
import SustainablySourced from './imgs/sustainability/sustainablySourced.svg';
import { useUserSupplier } from '../../queries/use_user_supplier';
import { useNavigate } from 'react-router-dom';
import { useUserProducts } from '../../queries/use_user_products';
import PlaceholderImage from './imgs/placeholder.png';
import RightIcon from './imgs/right-arrow.svg';
import LeftIcon from './imgs/left-arrow.svg';
import { useInView } from 'react-intersection-observer';
import Spinner from '../spinner/Spinner';

const sustainabilityDict = {
  'Carbon Negative': CarbonNegative,
  'Carbon Neutral': CarbonNeutral,
  'Ethically Made': EthicallyMade,
  'Green Energy': GreenEnergy,
  'Low VOC': LowVoc,
  'Made Locally': MadeLocally,
  Recyclable: Recyclable,
  recycled: Recycled,
  Regenerative: Regenerative,
  Sustainable: Sustainable,
  'Sustainability Sourced': SustainablySourced,
};

export default function SupplierPage({ setQuickViewVisible }) {
  const navigate = useNavigate();
  const { data: activeSupplier } = useUserSupplier({});
  const {
    data: products,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    meta: productMeta,
  } = useUserProducts({});

  const { ref, inView } = useInView();
  const [isHovered, setIsHovered] = useState(false);
  const images = activeSupplier?.gallery ?? [];

  const [activeImage, setActiveImage] = useState(images?.[0]);
  const numImages = images?.length;
  const sustainabilityArr = activeSupplier?.Sustainability?.split(',') ?? [];

  const handleLeftArrowClick = () => {
    const index = images?.indexOf(activeImage);
    let newIndex = index - 1;

    if (newIndex < 0) {
      newIndex = numImages - 1;
    }

    setActiveImage(images?.[newIndex]);
  };

  const handleRightArrowClick = () => {
    const index = images?.indexOf(activeImage);
    let newIndex = index + 1;

    if (newIndex >= numImages) {
      newIndex = 0;
    }

    setActiveImage(images?.[newIndex]);
  };

  useEffect(() => {
    setActiveImage(images?.[0]);
  }, [activeSupplier]);

  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, hasNextPage]);

  return (
    <div>
      <TopBarSuppliers onClick={() => navigate(-1)} />
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          gap: '39px',
          paddingLeft: '18px',
          paddingRight: '18px',
          marginTop: '20px',
        }}>
        <div
          style={{
            marginBottom: '30px',
            display: 'flex',
            flexShrink: 1,
            flexDirection: 'column',
            width: '60vw',
            height: 'max-content',
          }}>
          <div
            style={{
              position: 'relative',
              height: '35vw',
            }}
            onMouseOver={() => {
              setIsHovered(true);
            }}
            onMouseOut={() => {
              setIsHovered(false);
            }}>
            <img
              width="100%"
              alt="product"
              src={activeImage ?? activeSupplier?.Hero ?? PlaceholderImage}
              style={{ backgroundColor: '#F5f5f5', height: '100%', objectFit: 'cover' }}
            />
            {images?.length > 1 && (
              <div
                className="arrow-carrousel__wrapper"
                style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                <div className="arrow-carrousel arrow-left" onClick={handleLeftArrowClick}>
                  <img alt="left" src={LeftIcon} />
                </div>
                <div className="arrow-carrousel arrow-right" onClick={handleRightArrowClick}>
                  <img alt="left" src={RightIcon} />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: '13px',
              display: 'flex',
              gap: '13.3px',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
            {images?.map((element, i) => (
              <img
                key={i}
                style={{
                  backgroundColor: '#F5f5f5',
                  cursor: 'pointer',
                  opacity: activeImage === element ? 0.5 : 1,
                  outlineOffset: '3px',
                  width: '72px',
                  height: '72px',
                  objectFit: 'contain',
                }}
                alt="product "
                src={element}
                onClick={(e) => {
                  setActiveImage(element);
                }}
              />
            ))}
          </div>
        </div>
        <div style={{ marginBottom: '20px', width: '40%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '20px',
            }}>
            {activeSupplier?.['Membership'] === 'Verified (Paying)' ||
              (activeSupplier?.['Membership'] === 'Verified (Free)' && (
                <img src={VerifiedBadge} alt="verified" />
              ))}{' '}
            <div style={{ fontSize: '24px' }}>{activeSupplier['Company Name']}</div>{' '}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: '20px',
            }}>
            <div
              style={{
                fontSize: '18px',
                paddingRight: '20px',
                // borderRight: '1px solid #E5E5E5',
              }}>
              <span style={{ fontFamily: 'Object Sans Medium' }}>Products:</span>{' '}
              {productMeta?.totalLength}
            </div>
            <div style={{ paddingLeft: '20px', fontSize: '18px' }}>
              <span style={{ fontFamily: 'Object Sans Medium' }}>Price guide: </span>
              {[...Array(4)].map((_, i) => (
                <span
                  key={i}
                  className={`message-details__price-guide ${
                    i < activeSupplier['Price Guide']?.length ? '' : 'shadow'
                  }`}>
                  £
                </span>
              ))}
            </div>
          </div>
          <div style={{ fontSize: '16px', lineHeight: '24px' }}>
            {activeSupplier['Blurb']
              ? activeSupplier['Blurb']
                  .replace(/<p>/g, '')
                  .replace(/<\/p>/g, '')
                  .replace(/<li>/g, '')
                  .replace(/<\/li>/g, '')
                  .replace(/<ul>/g, '')
                  .replace(/<\/ul>/g, '')
                  .replace(/<h3>/g, '')
                  .replace(/<\/h3>/g, '')
              : ''}
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '25px',
              justifyContent: 'space-between',
              gap: '10px',
            }}>
            <div>
              <GenericButton
                styles={{ padding: '11px 39px', borderRadius: '0' }}
                label="Message supplier"
                disabled
              />
            </div>
          </div>
          {sustainabilityArr?.length > 0 && (
            <div style={{ marginTop: '40px' }}>
              <div>Sustainability</div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  gap: '30px',
                  marginTop: '15px',
                }}>
                {sustainabilityArr &&
                  sustainabilityArr.map((el) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        {' '}
                        <div>
                          <img src={sustainabilityDict[el]} alt="Sustainability" />
                        </div>{' '}
                        <div style={{ fontSize: '10px' }}>{el}</div>{' '}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          borderBottom: '1px solid #f2f2f2',
          margin: '20px',
        }}></div>
      <div
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat( auto-fill, minmax(225px, max-content))',
          gap: '20px',
          paddingLeft: '18px',
          paddingRight: '18px',
          justifyContent: 'space-between',
        }}>
        {products.map((el, i) => {
          return <SupplierCard key={i} product={el} setQuickViewVisible={setQuickViewVisible} />;
        })}
        <div ref={ref} />
      </div>
      {(isFetchingNextPage || isLoading) && (
        <Spinner styles={{ marginTop: '150px', marginBottom: '50px' }} />
      )}
    </div>
  );
}
