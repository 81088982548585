import { memo } from 'react';
import { SortableFileCard } from './FileCard';

export const FilesList = memo(function FilesList({ files, onRemoveFile }) {
  return files.map((file, index) => {
    const handleRemoveImage = async () => await onRemoveFile(file);
    const fileName = file.split('/').pop();

    return (
      <SortableFileCard
        file={file}
        index={index}
        key={file}
        id={file}
        fileName={fileName}
        onRemove={handleRemoveImage}
      />
    );
  });
});
