const PICKER_CONDITIONS = [
  { name: 'is exactly', id: '$eq' },
  { name: 'not equals', id: '$ne' },
  { name: 'has any of', id: '$in' },
  { name: 'has none of', id: '$nin' },
  { name: 'is empty', id: '$exists', value: '$in' },
  { name: 'is not empty', id: '$exists', value: '$nin' },
];

const STRING_CONDITIONS = [
  { name: 'equals', id: '$eq' },
  { name: 'not equals', id: '$ne' },
  { name: 'is empty', id: '$exists', value: '$in' },
  { name: 'is not empty', id: '$exists', value: '$nin' },
];

const NUMBER_CONDITIONS = [
  { name: 'equals', id: '$eq' },
  { name: 'not equals', id: '$ne' },
  { name: 'grater than', id: '$gt' },
  { name: 'grater than or equal', id: '$gte' },
  { name: 'less than', id: '$lt' },
  { name: 'less than or equal', id: '$lte' },
  { name: 'is empty', id: '$exists', value: '$in' },
  { name: 'is not empty', id: '$exists', value: '$nin' },
];

const STATUS_CONDITIONS = [
  { name: 'is not', id: '$nin' },
  { name: 'is', id: '$in' },
];

const BOOL_CONDITIONS = [
  { name: 'is selected', id: '$eq', value: true },
  { name: 'is not selected', id: '$ne', value: true },
];

const FILE_CONDITIONS = [
  { name: 'is empty', id: '$exists', value: '$in' },
  { name: 'is not empty', id: '$exists', value: '$nin' },
];

const PARSED_CONDITIONS = {
  $eq: 'equals',
  $ne: 'not equals',
  $gt: 'grater than',
  $gte: 'grater than or equal',
  $lt: 'less than',
  $lte: 'less than or equal',
  $in: 'has any of',
  $nin: 'has none of',
};

const PARSED_PICKER_CONDITIONS = {
  $eq: 'is exactly',
  $nin: 'has none of',
  $in: 'has any of',
};

const PARSED_STATUS_CONDITIONS = {
  $nin: 'is not',
  $in: 'is',
};

const PARSED_BOOLEAN_CONDITIONS = {
  $eq: 'is selected',
  $ne: 'is not selected',
};

export const getParsedCondition = (fieldName, condition, value) => {
  const lookForExists = condition === '$exists';
  const arrayType = [
    'Categories',
    'Sustainability',
    'Suitability',
    'Styles',
    'Material',
    'Finish',
    'Colour',
    'Lead Time',
    'Locatioin',
    'Unit',
    'Currency',
  ].includes(fieldName);

  if (lookForExists) {
    return value === '$nin' ? 'is not empty' : 'is empty';
  }

  if (arrayType) {
    return PARSED_PICKER_CONDITIONS[condition];
  }

  if (fieldName === 'Status') {
    return PARSED_STATUS_CONDITIONS[condition];
  }

  const booleanType = ['Exclusive', 'Customisable', 'Sample', 'VAT'].includes(fieldName);

  if (booleanType) {
    return PARSED_BOOLEAN_CONDITIONS[condition];
  }

  return PARSED_CONDITIONS[condition];
};

export const PREFIX = [
  { id: '$and', label: 'and' },
  { id: '$or', label: 'or' },
];

export const PARSED_PREFIX = {
  $and: 'and',
  $or: 'or',
};

export const CONDITIONS = {
  STRING: STRING_CONDITIONS,
  NUMBER: NUMBER_CONDITIONS,
  PICKER: PICKER_CONDITIONS,
  STATUS: STATUS_CONDITIONS,
  BOOL: BOOL_CONDITIONS,
  FILE: FILE_CONDITIONS,
};

export const AVAILABLE_COLUMNS = {
  Categories: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Colour: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Currency: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Customisable: { fieldType: 'bool', conditions: CONDITIONS.BOOL },
  Description: { fieldType: 'text', conditions: CONDITIONS.STRING },
  Discount: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
  Downloads: { fieldType: 'file', conditions: CONDITIONS.FILE },
  Exclusive: { fieldType: 'bool', conditions: CONDITIONS.BOOL },
  Finish: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  'Image Alt Text': { fieldType: 'text', conditions: CONDITIONS.STRING },
  'Lead Time': { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Location: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Material: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  'Meta Description': { fieldType: 'text', conditions: CONDITIONS.STRING },
  'Product Group': { fieldType: 'text', conditions: CONDITIONS.STRING },
  'Product Images': { fieldType: 'file', conditions: CONDITIONS.FILE },
  'Product Name': { fieldType: 'text', conditions: CONDITIONS.STRING },
  'Product URL': { fieldType: 'text', conditions: CONDITIONS.STRING },
  'Price - Retail': { fieldType: 'text', conditions: CONDITIONS.STRING },
  'Price - Trade': { fieldType: 'text', conditions: CONDITIONS.STRING },
  Sample: { fieldType: 'bool', conditions: CONDITIONS.BOOL },
  Status: { fieldType: 'select', conditions: CONDITIONS.STATUS },
  Styles: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Sustainability: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  Suitability: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  'Technical Drawings or PDFs': { fieldType: 'file', conditions: CONDITIONS.FILE },
  Unit: { fieldType: 'select', conditions: CONDITIONS.PICKER },
  VAT: { fieldType: 'bool', conditions: CONDITIONS.BOOL },
  Diameter: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
  Thickness: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
  Height: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
  Length: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
  Weight: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
  Width: { fieldType: 'number', conditions: CONDITIONS.NUMBER },
};
