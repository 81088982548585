import { useCallback, useState } from 'react';

// used to handle stateful usages of Refs
export const useCallbackRef = () => {
  const [ref, setRef] = useState();

  const handleRef = useCallback((node) => {
    setRef(node);
  }, []);

  return { ref, handleRef };
};
