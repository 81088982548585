import GalleryImage from './GalleryImage';
import PlusIcon from './imgs/add-gray.svg';

export default function GalleryImages({ userData, onUpdateSupplierData, onUploadImage }) {
  const onUpload = async (e) => {
    if (e.length) {
      let galleryImagesCopy = [...userData.gallery];

      for (const file of e) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const imageResponse = await onUploadImage(formData);
          galleryImagesCopy.push(imageResponse.data);
          await onUpdateSupplierData({ gallery: galleryImagesCopy });
        } catch (e) {
          console.error('[UPDATE USER DATA ERROR]', e);
        }
      }
    }
  };

  const handleDelete = async (image) => {
    let images = [...userData.gallery];

    images = images.filter((item) => {
      return item != image;
    });

    try {
      await onUpdateSupplierData({ gallery: images });
    } catch (e) {
      console.error('[UPDATE USER DATA ERROR]', e);
    }
  };

  return (
    <div className="gallery-images">
      <p>Gallery images</p>

      <div className="gallery-images__wrapper">
        {userData?.gallery?.map((image, idx) => {
          return (
            <GalleryImage
              key={idx}
              image={image}
              onDelete={() => {
                handleDelete(image);
              }}
            />
          );
        })}
        {userData?.gallery?.length < 10 && (
          <div style={{ marginTop: '0', height: '70px' }}>
            <label htmlFor="galleryImage" style={{ display: 'inline-block' }}>
              <div
                className={`account-profile__avatar`}
                style={{ width: '70px', height: '70px', borderRadius: '2px' }}>
                <img src={PlusIcon} alt="Avatar blank" />

                <div className="account-profile__avatar--overlay">
                  <img src={PlusIcon} alt="Avatar blank" className="account-profile__plus-icon" />
                </div>
                <input
                  type="file"
                  id="galleryImage"
                  accept="image/png, image/jpeg"
                  multiple
                  hidden
                  onChange={(e) => {
                    onUpload(e.target.files);
                  }}
                />
              </div>
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
