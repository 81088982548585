import toast from 'react-hot-toast';

export const useNotifications = () => {
  /**
   *
   * @param {*} message string message to display
   * @param {*} type 'success' | 'error'
   *
   */
  const notify = (message, type = 'success', options = {}) => {
    const { duration = 3000, ...rest } = options;
    toast[type](message, { duration, ...rest });
  };

  return { notify };
};
