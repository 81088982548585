import { useState } from 'react';
import { FilesList } from './parts/FilesList';
import { UploadMore } from './parts/UploadMore';
import CloseButton from './imgs/close.svg';
import './FilesUploader.css';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

export default function FilesUploader({ files = [], onUploadMore, onClose, onRemoveFile }) {
  const [items, setItems] = useState([...files]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      activationConstraint: {
        distance: 5,
      },
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const hasItems = Boolean(active?.id) && Boolean(over?.id);
    if (hasItems && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleCloseModal = async () => {
    await onClose(items);
  };

  const handleUploadMore = async () => {
    await onUploadMore(items);
  };

  const handleRemoveFile = async (file) => {
    setItems((prev) => prev.filter((i) => i !== file));
    await onRemoveFile(file);
  };

  return (
    <div>
      <div
        onClick={handleCloseModal}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          top: '-12px',
          right: '-12px',
          zIndex: 1000,
        }}>
        <img src={CloseButton} alt="close" />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
        }}>
        <div className="upload_file_title">Downloads</div>
        <UploadMore onUploadMore={handleUploadMore} />
      </div>
      <div className="file_drop_zone">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <FilesList files={items} onRemoveFile={handleRemoveFile} />
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
