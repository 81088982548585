import Resize from '../imgs/resize.svg';
import { useTextAreaCell } from './TextAreaCell.hook';
import TextModal from '../../text-modal/TextModal';

import { useClickOutside } from '../../../hooks/useClickOutside';
import { useFocus } from '../../../hooks/useFocus';
import Cell from './Cell';
import { useActionsContext } from '../../../context/actions.context';
import { useTableNavigationContext } from '../../../context/table_navigation.context';

export default function TextAreaCell({
  onBlur,
  defaultValue,
  fieldName,
  productId,
  product,
  index,
  rowIndex,
  columnIndex,
}) {
  const { copyValue, pasteValue } = useActionsContext();

  const { focused, expanded, setExpanded, showModal, hideModal, modalOpen, removeFocus } =
    useTextAreaCell();
  const { setIsEditing } = useTableNavigationContext();
  const [inputRef, setInputFocus, removeInputFocus] = useFocus();
  const { ref } = useClickOutside(() => {
    if (focused) {
      removeFocus();
      !modalOpen && onBlur(textValue);
    }
    if (expanded) {
      setExpanded(false);
    }
  });
  const baseStyle = expanded ? 'expandable_cell--focused' : 'textarea_cell';
  const styleOverrides = expanded ? { position: 'relative', outline: 'none' } : {};
  const textValue = inputRef?.current?.value;

  const handleTextChange = (e) => {
    inputRef.current.value = e?.target?.value;
  };

  const handleCloseModal = (value) => {
    inputRef.current.value = value;
    onBlur(value);
    hideModal();
  };

  const handleOpenModal = () => {
    showModal();
    setIsEditing(true);
  };

  return (
    <Cell
      id={index}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      defaultValue={defaultValue}
      fieldName={fieldName}
      onDoubleClick={(e) => {
        if (!modalOpen) {
          setExpanded(true);
          setInputFocus();
        }
      }}
      onBlur={removeInputFocus}
      onFocus={setInputFocus}
      onKeyDown={async (e) => {
        if (e.key === 'Escape') {
          setExpanded(false);
        }
        if (e.key === 'Enter' && !expanded) {
          setExpanded(true);
        }

        if (e.key === 'Tab' && expanded) {
          setExpanded(false);
        }

        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'c') {
            return copyValue({ data: { [fieldName]: defaultValue } });
          }
          if (e.key === 'v') {
            await pasteValue({
              fieldName,
              productId,
              previousData: { [fieldName]: defaultValue },
              product,
            });
            return;
          }

          setExpanded(false);
        } else if (!expanded) {
          return setInputFocus();
        }
      }}
      styleOverrides={styleOverrides}>
      <div
        className={baseStyle}
        ref={ref}
        style={expanded ? { display: 'flex', height: '200px' } : {}}
        onBlur={removeInputFocus}
        onFocus={setInputFocus}>
        <textarea
          readOnly={!expanded}
          key={defaultValue}
          ref={inputRef}
          rows={1}
          style={{
            resize: 'none',
            whiteSpace: 'pre-wrap',
            overflowY: expanded && !modalOpen ? 'visible' : 'hidden',
            cursor: focused || expanded ? 'text' : 'pointer',
          }}
          className="text_cell_input"
          defaultValue={defaultValue}
          disabled={modalOpen}
          onChange={handleTextChange}
          onBlur={(e) => onBlur(e.target.value)}
        />
        {expanded && (
          <img src={Resize} className="resize_handle" alt="resize" onClick={handleOpenModal} />
        )}
      </div>

      <TextModal
        key={`${modalOpen}-${textValue}`}
        defaultValue={textValue}
        onClose={handleCloseModal}
        open={modalOpen}
        onChange={handleTextChange}
      />
    </Cell>
  );
}
