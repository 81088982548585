import Fuse from 'fuse.js';
import { v4 as uuid } from 'uuid';
import { useState, createRef, useCallback, useEffect } from 'react';
import TagGroup from '../../tag_group/TagGroup';
import Search from '../imgs/search.svg';
import Insert from '../imgs/insert.svg';
import Selected from '../imgs/selected.svg';
import { useNavigation } from './FuseSearch.hooks';

export default function FuseSearch({
  options,
  selected,
  open,
  creatable,
  onOptionSelect,
  fuseConfig = { threshold: 0, keys: ['name'], matchAllOnEmptyQuery: true },
  onCreateOption,
  useCombined = false,
}) {
  const [searchOutput, setSearchOutput] = useState(options);

  const refs = searchOutput?.reduce((acc, item) => {
    acc[item._id] = createRef();
    return acc;
  }, {});

  const { activeIndex, setActiveIndex } = useNavigation(
    searchOutput.length,
    onCreateOption,
    onOptionSelect,
    refs,
    searchOutput,
    open
  );
  const fuse = new Fuse(options, fuseConfig);

  const handleSearch = (e) => {
    const search = e.target.value;

    if (!search) {
      return setSearchOutput(options);
    }
    const result = fuse.search(search);

    setActiveIndex(-1);
    if (result.length < 4 && creatable) {
      return setSearchOutput([...result.map((el) => el.item), { name: search, _id: 'creatable' }]);
    }

    return setSearchOutput(result.map((el) => el.item));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '200px' }}>
      <div
        style={{
          display: 'flex',
        }}>
        <input
          placeholder="Search"
          style={{
            outline: 'none',
            border: 'none',
            fontSize: '12px',
            marginBottom: '4px',
            width: '100%',
            padding: '8px',
          }}
          autoFocus
          onChange={handleSearch}
        />
        <img
          width="12px"
          style={{ marginRight: '4px', marginBottom: '4px' }}
          src={Search}
          alt="Search icon"
        />
      </div>
      <div style={{ overflowY: 'auto' }} onMouseLeave={() => setActiveIndex(-1)}>
        {searchOutput.map((option, index) => {
          const itemSelected = selected?.length > 0 && selected.some((o) => o === option.name);

          if (option._id === 'creatable') {
            return (
              <div
                ref={refs[option._id]}
                key={option.name}
                tabIndex={0}
                className="group-picker-item"
                style={{
                  padding: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                  backgroundColor: index === activeIndex ? '#E4F0F6' : '#ffffff',
                }}
                onMouseOver={() => setActiveIndex(index)}
                onClick={async () => {
                  onCreateOption(option.name);
                  await onOptionSelect({ name: option.name, _id: uuid() });
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onCreateOption(option.name);
                    onOptionSelect({ name: option.name, _id: uuid() });
                  }
                }}>
                Create {option.name}
                <img src={Insert} style={{ height: '14px' }} alt="selected" />
              </div>
            );
          }
          return (
            <div
              tabIndex={0}
              ref={refs?.[option._id]}
              onClick={() => onOptionSelect(option)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onOptionSelect(option);
                }
              }}
              key={option?._id ?? option}
              style={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
                pointerEvents: useCombined && option?.disabled ? 'none' : 'auto',
                opacity: useCombined && option?.disabled ? 0.7 : 1,
                backgroundColor: index === activeIndex ? '#E4F0F6' : '#ffffff',
              }}
              onMouseOver={() => setActiveIndex(index)}
              className="group-picker-item">
              {useCombined ? (
                <TagGroup
                  showCloseHandles={false}
                  backspaceRemoves={false}
                  separator={'+'}
                  variant="secondary"
                  tags={option?.tags}
                />
              ) : (
                option.name
              )}
              {activeIndex === index && !itemSelected && (
                <img src={Insert} style={{ height: '14px' }} alt="select" />
              )}
              {itemSelected && <img src={Selected} style={{ height: '8px' }} alt="selected" />}
            </div>
          );
        })}
      </div>
    </div>
  );
}
