import { useState } from 'react';
import NotSavedIcon from './imgs/notsaved.svg';
import ReconnectingIcon from './imgs/reconnecting.svg';
import SavedIcon from './imgs/saved.svg';
import SavingIcon from './imgs/saving.svg';
import SavedSmall from './imgs/saved_small.svg';
import SavingSmall from './imgs/saving_small.svg';

export default function SaveStatus({ statusSaved }) {
  const [iconState, setIconState] = useState('idle');
  const statusIcon = {
    Saved: {
      idle: SavedSmall,
      hover: SavedIcon,
    },
    Saving: {
      idle: SavingSmall,
      hover: SavingIcon,
    },
  };
  return (
    <div
      style={{ display: 'flex', height: '16px' }}
      onMouseOver={() => setIconState('hover')}
      onMouseOut={() => setIconState('idle')}>
      <img src={statusIcon[statusSaved][iconState]} alt="save status" />
    </div>
  );
}
