import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const LOCATION_QUERY_KEY = 'LOCATION_QUERY';

const getLocation = () => authApiClient.get('/tag/location').then((res) => res.data);

export const useLocation = () => {
  const { data = [], isLoading } = useQuery([LOCATION_QUERY_KEY], getLocation, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
