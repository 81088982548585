import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { authApiClient } from '../api';

const LEAD_QUERY_KEY = 'LEAD_QUERY';

const getLead = () => authApiClient.get('/tag/lead').then((res) => res.data);

export const useLead = () => {
  const { data = [], isLoading } = useQuery([LEAD_QUERY_KEY], getLead, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export const useUpdateLead = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.post(`tag/lead`, data?.lead),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LEAD_QUERY_KEY]);
      },
    }
  );

  return {
    updateCategoryData: mutateAsync,
    isLoading,
  };
};
