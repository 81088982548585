import Cell from './Cell';
import { setSelectionRange } from '../utils';
import { useActionsContext } from '../../../context/actions.context';
import { useCallbackRef } from '../../../hooks/useCallbackRef';
import { useEffect, useState } from 'react';

export default function TextCell({
  value,
  index,
  defaultValue,
  onChange,
  onBlur,
  children,
  onClick,
  sticky,
  setShowMenu,
  productId,
  rowsSelected,
  setMenuPosition,
  setRowToDelete,
  setRowsSelected,
  type = 'text',
  fieldName,
  product,
  autoFocus,
  rowIndex,
  columnIndex,
  ...cellProps
}) {
  const { copyValue, pasteValue } = useActionsContext();
  const { ref: inputRef, handleRef: handleInputRef } = useCallbackRef(); // For setting/ unsetting editing
  const [isMounted, setIsMounted] = useState(false);

  const handleContextMenu = (event) => {
    setShowMenu(true);
    event.preventDefault();
    setRowToDelete(productId);
    setMenuPosition({ x: event.clientX, y: event.clientY });
    onClick(event);
  };

  useEffect(() => {
    if (!isMounted) {
      return setIsMounted(true);
    }
    return inputRef?.focus();
  }, [inputRef]);

  return (
    <Cell
      {...cellProps}
      id={index}
      fieldName={fieldName}
      defaultValue={defaultValue}
      sticky={sticky}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      onContextMenu={handleContextMenu}
      onBlur={() => inputRef.blur()}
      onFocus={() => {
        inputRef.focus();
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          inputRef.focus();
        }

        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'c') {
            copyValue({ data: { [fieldName]: defaultValue } });
          }
          if (e.key === 'v') {
            await pasteValue({
              productId,
              previousData: { [fieldName]: defaultValue },
              product,
              fieldName,
            });
          }
        }
      }}>
      {children}
      <input
        ref={handleInputRef}
        key={defaultValue}
        type={type}
        autoFocus={autoFocus}
        className="text_cell_input"
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={(e) => setSelectionRange(e)}
      />
    </Cell>
  );
}
