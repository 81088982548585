import ContactItem from './ContactItem';
import { useUser } from '../../api/auth';
import { useConversations } from '../../queries/useConversations';

import { useEffect, useLayoutEffect, useState, useContext } from 'react';
import Spinner from '../spinner/Spinner';

export default function Contacts({ setContact }) {
  const { userData } = useUser();

  const { data: contactsArr, isLoading } = useConversations(userData.SupplierId);
  const [search, setSearch] = useState('');

  return (
    <div className="contacts__wrapper">
      <div className="contacts__search">
        {/* <input type="text" placeholder="Search" value={search} />
        <div className="contacts__search-icon">
          <img src={PenIcon} alt="Pen icon" />
        </div> */}
      </div>
      {!isLoading ? (
        contactsArr.length > 0 ? (
          <div className="contacts__chat">
            {contactsArr.map((contact) => {
              return <ContactItem contact={contact} setContact={setContact} />;
            })}
          </div>
        ) : (
          <div className="contacts__chat-empty-text">No chats to show yet</div>
        )
      ) : (
        <Spinner label="Loading your contacts..." />
      )}
    </div>
  );
}
