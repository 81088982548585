/**
 *
 * @param {*} variant - primary, secondary
 * @returns
 */
export default function Tag({ label, onClose, showCloseHandles = true, variant = 'primary' }) {
  return (
    <span className={`tag_wrapper tag_wrapper--${variant}`}>
      <span title={label}>{label}</span>
      {showCloseHandles && (
        <span title="remove" role="button" onClick={onClose} className="tag_close_handle">
          X
        </span>
      )}
    </span>
  );
}
