import { useRef } from 'react';

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setElementFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  const removeElementFocus = () => {
    htmlElRef.current && htmlElRef.current.blur();
  };

  return [htmlElRef, setElementFocus, removeElementFocus];
};
