import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ALL_PRODUCTS_QUERY } from './use_products';
import { authApiClient } from '../api';

const PRODUCT_GROUPS_QUERY_KEY = 'PRODUCT_GROUPS_QUERY';
const VARIANTS_QUERY_KEY = 'VARIANTS_QUERY';

// TODO: use product groups query

const getProductGroups = (supplierId) =>
  authApiClient.get(`/productgroups/persupplier/${supplierId}`).then((res) => res.data);

const getProductGroup = (groupId) =>
  authApiClient.get(`/productgroups/${groupId}`).then((res) => res.data);

export const useProductGroups = (supplierId, enabled) => {
  const { data = [], isLoading } = useQuery(
    [PRODUCT_GROUPS_QUERY_KEY],
    () => getProductGroups(supplierId),
    {
      refetchOnWindowFocus: true,
      enabled: enabled && Boolean(supplierId),
    }
  );

  return { data, isLoading };
};

export const useAddProductGroup = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.post(`/productgroups`, { name: data.name, Supplier: data.supplierId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY]);
      },
    }
  );

  return {
    addProductGroup: mutateAsync,
    isLoading,
  };
};

export const useProductGroupData = (groupId, enabled = true) => {
  const { data, isLoading, isRefetching } = useQuery(
    [PRODUCT_GROUPS_QUERY_KEY, groupId],
    () => getProductGroup(groupId),
    {
      refetchOnWindowFocus: true,
      enabled,
    }
  );

  return { data, isLoading, isRefetching };
};

export const useUpdateProductGroups = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    (data) => authApiClient.put(`productgroups/${data.id}`, data.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [ALL_PRODUCTS_QUERY],
        });
        queryClient.invalidateQueries({
          queryKey: [PRODUCT_GROUPS_QUERY_KEY],
        });
        queryClient.invalidateQueries({
          queryKey: [VARIANTS_QUERY_KEY],
        });
      },
    }
  );

  return {
    updateGroupsData: mutateAsync,
  };
};
