import './TagGroup.css';
import Tag from './parts/Tag.js';

/**
 *
 * @param {*} tags - this should ALWAYS be an array, event if it's a single tag, i.e [{_id: "123", name: "test"}]
 */
export default function TagGroup({
  onTagRemove,
  showCloseHandles = false,
  tags = [],
  backspaceRemoves = true,
  separator = null,
  variant,
}) {
  const hasSeparator = Boolean(separator);

  return (
    <div
      className="tag_row"
      onKeyDown={(e) => {
        if (backspaceRemoves && e.key === 'Backspace' && tags.length > 0) {
          onTagRemove(tags[tags.length - 1]);
        }
      }}>
      {tags
        .filter((t) => Boolean(t))
        .map((t, index) => {
          const isLast = index === tags.length - 1;
          return (
            <div key={t?._id ?? t}>
              <Tag
                label={t?.name ?? t}
                showCloseHandles={showCloseHandles}
                onClose={() => onTagRemove(t)}
                variant={variant}
              />
              {hasSeparator && !isLast && separator}
            </div>
          );
        })}
    </div>
  );
}
