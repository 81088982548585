import { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { useDebouncedValue } from '../hooks/useDebouncedValue';

import { useProductsData } from '../queries/use_products';
import { useUser } from '../api/auth';

const ProductContext = createContext();

export function ProductProvider(props) {
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState({});
  const { hasUser } = useUser();

  useEffect(() => {
    const savedFilters = localStorage.getItem('productFilters');
    const savedSort = localStorage.getItem('productSort');
    if (savedFilters) {
      setFilters(JSON.parse(savedFilters));
    }
    if (savedSort) {
      setSort(JSON.parse(savedSort));
    }
  }, []);

  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const debouncedSearchText = useDebouncedValue(searchText, 200);

  const {
    data: productsData,
    meta,
    isReady,
    hasNextPage,
    hasPreviousPage,
    isPreviousData,
  } = useProductsData({
    itemsPerPage,
    page,
    searchText: debouncedSearchText,
    filters,
    sort,
    enabled: hasUser,
  });

  const [localProducts, setLocalProducts] = useState([...productsData]);
  const [rowsSelected, setRowsSelected] = useState([]);

  const saveFilters = (newFilters) => {
    localStorage.setItem('productFilters', JSON.stringify(newFilters));
    setFilters(newFilters);
    setPage(0);
  };

  const saveSort = (newSort) => {
    localStorage.setItem('productSort', JSON.stringify(newSort));
    setSort(newSort);
  };

  const sortByNewest = () => {
    const prev = localStorage.getItem('productSort');
    let current = { createdAt: -1 };
    if (prev) {
      current = { ...current, ...JSON.parse(prev) };
    }

    saveSort(current);
  };

  const clearFilters = () => {
    setSearchText('');
    saveFilters({});
  };

  const parsedFilters = useCallback(() => {
    const conditions = [];

    const filtersKey = Object.keys(filters);
    if (filtersKey.length === 0) {
      return conditions;
    }

    const condition = filtersKey[0];

    const generateConditions = (prefix, remainingConditions) => {
      remainingConditions.forEach((con) => {
        if (con?.$and || con?.$or) {
          generateConditions(con.$and ? '$and' : '$or', con[con.$and ? '$and' : '$or']);
        }
        const name = Object.keys(con)[0];
        const byCondition = Object.keys(con[name])[0];
        const value =
          byCondition === '$exists' ? Object.keys(con[name])[1] : con[name][byCondition];

        conditions.push({
          name,
          byCondition,
          value,
          prefix,
        });
      });
    };

    filters[condition].forEach((condition) => {
      const hasOrCondition = Boolean(condition?.$or);
      const hasAndCondition = Boolean(condition?.$and);
      const prefix = hasAndCondition ? '$and' : '$or';

      if (!(hasAndCondition || hasOrCondition)) {
        const name = Object.keys(condition)[0];
        const byCondition = Object.keys(condition[name])[0];
        const value =
          byCondition === '$exists'
            ? Object.keys(condition[name])[1]
            : condition[name][byCondition];

        conditions.push({
          name,
          byCondition,
          value,
          prefix,
        });
      } else {
        generateConditions(prefix, condition[prefix]);
      }
    });

    return conditions.reverse();
  }, [filters]);

  const calculateItemsPerPage = (newItemsPerPage) => {
    const newPage = Math.floor((page * itemsPerPage) / newItemsPerPage);
    setPage(newPage);
    setItemsPerPage(newItemsPerPage);
  };

  return (
    <ProductContext.Provider
      value={{
        setPage,
        allProducts: productsData,
        localProducts,
        setLocalProducts,
        meta,
        rowsSelected,
        setRowsSelected,
        setSearchText,
        total: meta.totalLength,
        itemsPerPage,
        page,
        hasNextPage,
        hasPreviousPage,
        searchText: debouncedSearchText,
        searchTextInput: searchText,
        totalPages: meta.totalPages,
        calculateItemsPerPage,
        isReady,
        isPreviousData,
        filters: parsedFilters(),
        rawFilters: filters,
        saveFilters,
        sort,
        saveSort,
        sortByNewest,
        clearFilters,
      }}>
      {props.children}
    </ProductContext.Provider>
  );
}

export const useProductsContext = () => useContext(ProductContext);
