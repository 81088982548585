import CloseWhiteImg from './imgs/close-white.svg';
import AddImg from './imgs/add-gray.svg';

export default function GalleryImage({ blank, image, onClick, onDelete }) {
  return (
    <>
      <div
        className={`gallery-image ${blank ? 'gallery-image--blank' : ''}`}
        onClick={blank && onClick}>
        <img src={image} alt="Selcted" />

        <span className="gallery-image__close" onClick={onDelete}>
          <img src={CloseWhiteImg} alt="Close" />
        </span>

        <span className="gallery-image__add">
          <img src={AddImg} alt="Add" />
        </span>
      </div>
    </>
  );
}
