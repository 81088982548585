import { useContext, createContext, useReducer } from 'react';
import { useUpdateProduct } from '../queries/use_products';
import { useNotifications } from '../components/notifications/useNotifications';

const ActionsContext = createContext();

const COPY_VALUE = 'COPY_VALUE';
const UNDO_ACTION = 'UNDO_ACTION';
const PASTE_VALUE = 'PASTE_VALUE';
const EDIT_VALUE = 'EDIT_VALUE';
const CLEAR_VALUE = 'CLEAR_VALUE';

/**
 * @typedef {Object} State
 * @property {object} copiedValue {field: string, value: string | array | object, index: number | undefined <- number will be used for the drag feature}
 * @property {array} actions Array<{data: {fieldName: string, value: string | object | boolean}, productId: string}>
 */

const INITIAL_STATE = {
  copiedValue: null,
  actions: [],
};

function actionsManager(state, action) {
  const { type, payload } = action;

  switch (type) {
    case COPY_VALUE:
      return {
        ...state,
        copiedValue: payload.copiedValue,
      };
    case UNDO_ACTION:
      return {
        ...state,
        actions: payload.actions,
      };
    case PASTE_VALUE:
      return {
        ...state,
        actions: [payload.action, ...state.actions],
      };
    case EDIT_VALUE:
      return {
        ...state,
        actions: [payload.action, ...state.actions],
      };
    case CLEAR_VALUE:
      return {
        ...state,
        copiedValue: null,
      };
    default:
      return state;
  }
}

const NOT_ALLOWED_FIELDS = ['Options'];

const useActionsManager = () => {
  const { updateProductData } = useUpdateProduct();
  const { notify } = useNotifications();
  const [state, dispatch] = useReducer(actionsManager, INITIAL_STATE);

  /**
   *
   * @param {*} copiedValue {data: {}}
   */
  const copyValue = (copiedValue) => {
    dispatch({ type: COPY_VALUE, payload: { copiedValue } });
  };

  const undoAction = async () => {
    const [recentAction, ...actions] = state.actions;
    if (recentAction) {
      const { previous, id } = recentAction;
      try {
        await updateProductData({
          product: previous,
          id,
        });
        dispatch({ type: UNDO_ACTION, payload: { actions: actions ?? [] } });
        notify('Action successfully undone', 'success', { icon: null, position: 'bottom-left' });
      } catch (error) {
        notify('Paste failed', 'error', { icon: null, position: 'bottom-left' });
      }
    }
  };

  const changeValue = async ({ product, productId, newValues, previousData }) => {
    try {
      await updateProductData({ product: newValues, id: productId });
      product = { ...product, ...newValues };

      dispatch({
        type: EDIT_VALUE,
        payload: {
          action: { previous: previousData, current: newValues, id: productId },
        },
      });
    } catch (error) {
      notify('Paste failed', 'error', { icon: null, position: 'bottom-left' });
    }
  };

  const pasteValue = async ({ fieldName, productId, previousData, product }) => {
    const { copiedValue } = state;

    if (!copiedValue) {
      notify('Nothing to paste', 'error', { icon: null, position: 'bottom-left' });
      return;
    }

    const { data } = copiedValue;

    if (!Object.keys(data).includes(fieldName)) {
      notify(`Data in wrong format for ${fieldName} field`, 'error', {
        icon: null,
        position: 'bottom-left',
      });
      return;
    }

    if (NOT_ALLOWED_FIELDS.includes(fieldName)) {
      notify(`Cannot paste data to ${fieldName} field`, 'error', {
        icon: null,
        position: 'bottom-left',
      });
      return;
    }

    try {
      await updateProductData({ product: data, id: productId });
      product = { ...product, ...data };
      dispatch({
        type: PASTE_VALUE,
        payload: {
          action: { previous: previousData, current: data, id: productId },
        },
      });
      notify('Pasted successfully', 'success', { icon: null, position: 'bottom-left' });
    } catch (error) {
      notify('Paste failed', 'error', { icon: null, position: 'bottom-left' });
    }
  };

  const clearValue = () => {
    dispatch({ type: CLEAR_VALUE });
  };

  return {
    copyValue,
    undoAction,
    pasteValue,
    changeValue,
    copiedValue: state.copiedValue,
    clearValue,
  };
};

export function ActionsProviderWrapper(props) {
  const { notify } = useNotifications();
  const { copyValue, undoAction, pasteValue, changeValue, copiedValue, clearValue } =
    useActionsManager();

  const onDrag = ({ field, value, index }) => {
    copyValue({ data: { [field]: value }, index });
  };

  const onDrop = async ({ lastIndex, products }) => {
    if (copiedValue) {
      const startIndex = lastIndex > copiedValue.index ? copiedValue.index : lastIndex;
      const toUpdate =
        lastIndex > copiedValue.index
          ? products.slice(startIndex, lastIndex)
          : products.slice(startIndex, copiedValue.index);

      try {
        await Promise.allSettled(
          toUpdate.map(async (product, i) => {
            const newValues = {
              ...product,
              ...copiedValue.data,
            };

            await changeValue({
              product,
              productId: product._id,
              newValues,
              previousData: product,
            });
            products[startIndex + i] = newValues;
          })
        );
      } catch (error) {
        notify('Paste failed', 'error', { icon: null, position: 'bottom-left' });
      } finally {
        clearValue();
      }
    }
  };

  return (
    <ActionsContext.Provider
      value={{
        copyValue,
        undoAction,
        pasteValue,
        changeValue,
        onDrag,
        onDrop,
      }}>
      {props.children}
    </ActionsContext.Provider>
  );
}

export const useActionsContext = () => useContext(ActionsContext);
