import { memo } from 'react';
import { SortableImageCard } from './ImageCard';

export const ImagesList = memo(function ImagesList({ images, onRemoveImage }) {
  return images.map((image, index) => {
    const handleRemoveImage = async () => await onRemoveImage(image);
    const name = image.split('/').pop();

    return (
      <SortableImageCard
        image={image}
        name={name}
        index={index}
        key={image}
        id={image}
        onRemove={handleRemoveImage}
      />
    );
  });
});
