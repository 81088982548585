import { useReducer } from 'react';
import { AVAILABLE_COLUMNS } from './mocks';

const CHANGE_FIELD_TYPE = 'CHANGE_FIELD_TYPE';

const CHANGE_SORT_OPTION = 'CHANGE_SORT_OPTION';

const CREATE_CONDITION = 'CREATE_CONDITION';
const REMOVE_CONDITION = 'REMOVE_CONDITION';

const availableNames = Object.keys(AVAILABLE_COLUMNS);

const formatState = (hideSuppliers) => (initial) => {
  const usedNames = Object.keys(initial);
  const remaining = hideSuppliers
    ? availableNames.filter((name) => !usedNames.includes(name) && name !== 'Supplier Name')
    : availableNames.filter((name) => !usedNames.includes(name));
  const conditions = usedNames
    .filter((n) => n !== 'createdAt')
    .map((name) => ({ name, order: initial[name] }));

  const mockConditions = [{ name: 'Product Name', order: 1 }];

  const hasConditions = conditions.length > 0;

  return {
    remaining,
    canCreate: remaining.length > 0,
    conditions: hasConditions ? conditions : mockConditions,
  };
};

function sortManager(state, action) {
  const { remaining, conditions, canCreate } = state;
  const { type, payload } = action;
  const newConditions = [...conditions];
  const remainingCopy = [...remaining];

  switch (type) {
    case CREATE_CONDITION:
      newConditions.push({ name: remainingCopy[0], order: 1 });
      remainingCopy.shift();

      return {
        remaining: remainingCopy,
        canCreate: remainingCopy.length > 0,
        conditions: newConditions,
      };

    case CHANGE_SORT_OPTION:
      newConditions[payload.index] = {
        ...newConditions[payload.index],
        order: payload.value,
      };

      return {
        remaining,
        canCreate,
        conditions: newConditions,
      };

    case REMOVE_CONDITION:
      newConditions.splice(payload.index, 1);

      return {
        remaining: [...remaining, payload.name],
        canCreate: true,
        conditions: newConditions,
      };

    case CHANGE_FIELD_TYPE:
      newConditions[payload.index] = {
        ...newConditions[payload.index],
        name: payload.newName,
      };

      return {
        conditions: newConditions,
        remaining: [...remaining, payload.oldName].filter((val) => val !== payload.newName),
        canCreate: true,
      };
    default:
      return state;
  }
}

export const useSortManager = (initialState, hideSuppliers) => {
  const [state, dispatch] = useReducer(sortManager, initialState, formatState(hideSuppliers));

  const createCondition = () => {
    dispatch({
      type: CREATE_CONDITION,
    });
  };

  const changeSortOption = (index) => (value) => {
    dispatch({
      type: CHANGE_SORT_OPTION,
      payload: { index, value },
    });
  };

  const removeCondition = (index) => (name) => {
    dispatch({ type: REMOVE_CONDITION, payload: { index, name } });
  };

  const changeFieldType = (index) => (newName, oldName) => {
    dispatch({
      type: CHANGE_FIELD_TYPE,
      payload: { index, newName, oldName },
    });
  };

  return {
    ...state,
    createCondition,
    changeSortOption,
    removeCondition,
    changeFieldType,
  };
};
