import { useUser } from '../../api/auth';
import { useDesigner } from '../../queries/use_designer';

export default function ContactItem({ contact, setContact }) {
  const { data: contactData, isLoading } = useDesigner(contact);

  const FirstCharacter = () => {
    const firstChar = contactData?.name.charAt(0);
    return <div className="message-contact__first-char">{firstChar}</div>;
  };

  return (
    <>
      {!isLoading && (
        <div
          className={'message-contact__wrapper'}
          onClick={() => {
            setContact(contact);
          }}>
          <FirstCharacter />
          <div
            style={{ display: 'flex', justifyContent: 'center' }}
            className="message-contact__info">
            <div className="message-contact__name">{contactData?.name}</div>
            <div className="message-contact__activeness"></div>
          </div>
        </div>
      )}
    </>
  );
}
