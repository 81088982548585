import { useProductsContext } from '../../../context/product.context';
import IconLeft from '../imgs/left.svg';
import IconRight from '../imgs/right.svg';

export default function Pagination() {
  const {
    page,
    setPage,
    totalPages,
    hasNextPage,
    itemsPerPage,
    hasPreviousPage,
    calculateItemsPerPage,
  } = useProductsContext();

  return (
    <div className="footer-pagination">
      <button
        className={
          'pagination_button pagination_button--left' + (hasPreviousPage ? '' : ' disabled')
        }
        onClick={() => {
          setPage((prev) => prev - 1);
        }}
        disabled={!hasPreviousPage}>
        <img src={IconLeft} alt="left" />
      </button>
      <div style={{ fontSize: '12px' }} key={page}>
        <input
          defaultValue={page + 1}
          style={{
            border: '1px solid #CCCCCC',
            borderRadius: '2px',
            width: '28px',
            fontSize: '12px',
            height: '16px',
            marginRight: '3px',
            textAlign: 'center',
          }}
          onBlur={(e) => {
            setPage(e.target.value - 1);
          }}
        />
        of {totalPages + 1}
      </div>

      <button
        className="pagination_button pagination_button--right"
        onClick={() => {
          setPage((prev) => prev + 1);
        }}
        disabled={!hasNextPage}>
        <img src={IconRight} alt="right" />
      </button>

      <div
        style={{
          display: 'flex',
          marginLeft: '10px',
        }}>
        <select
          key={itemsPerPage}
          defaultValue={itemsPerPage}
          style={{
            border: '1px solid #CCCCCC',
            borderRadius: '2px',
            width: '28px',
            fontSize: '12px',
            height: '16px',
            cursor: 'pointer',
            marginRight: '3px',
            textAlign: 'center',
          }}
          onChange={(e) => {
            calculateItemsPerPage(e.target.value);
          }}>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>
        results
      </div>
    </div>
  );
}
