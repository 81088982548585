import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

export const DESIGNER_QUERY_KEY = 'DESIGNER_QUERY_KEY';

export const getDesigner = (designerId) =>
  authApiClient.get(`/designer/duser/${designerId}`).then((res) => res.data);

export const useDesigner = (designerId) => {
  const {
    data = {},
    isLoading,
    isRefetching,
  } = useQuery([`DESIGNER_QUERY_KEY ${designerId}`], () => getDesigner(designerId), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: Boolean(designerId),
  });

  return { data, isLoading, isRefetching };
};
