import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient, authApiClient } from '../api';
import { ME_QUERY_KEY } from '../api/auth';

export const USER_QUERY_KEY = 'USER_QUERY';

const getUser = (userId) => apiClient.get(`/designer/duser/${userId}`).then((res) => res.data);

export const useChangeUserPassword = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.post(`auth/pwd/${data.id}`, { newPassword: data.password }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ME_QUERY_KEY]);
      },
    }
  );

  return {
    changeUserPassword: mutateAsync,
    isLoading,
  };
};

export const useUser = ({ userId }) => {
  const { data, isLoading, isRefetching } = useQuery(
    [USER_QUERY_KEY, userId],
    () => getUser(userId),
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true,
    }
  );

  return { data, isLoading, isRefetching };
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.put(`user/${data.id}`, data.user),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ME_QUERY_KEY]);
      },
    }
  );

  return {
    updateUserData: mutateAsync,
    isLoading,
  };
};
