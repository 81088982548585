import { useState } from 'react';
import Cell from './Cell';
import { useActionsContext } from '../../../context/actions.context';
import { useCallbackRef } from '../../../hooks/useCallbackRef';

export default function NumberCell({
  value,
  defaultValue,
  onChange,
  onBlur,
  onClick,
  productId,
  fieldName,
  product,
  disabled,
  index,
  rowIndex,
  columnIndex,
  setActiveProduct,
  ...cellProps
}) {
  const [readOnly, setReadOnly] = useState(true);
  const { copyValue, pasteValue } = useActionsContext();
  const { ref: inputRef, handleRef: handleInputRef } = useCallbackRef(); // For setting/ unsetting editing

  return (
    <Cell
      {...cellProps}
      onClick={() => setActiveProduct(product)}
      fieldName={fieldName}
      id={index}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      defaultValue={defaultValue}
      onDoubleClick={() => setReadOnly(false)}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          setReadOnly(false);
          inputRef.focus();
        }

        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'c') {
            copyValue({ data: { [fieldName]: defaultValue } });
          }

          if (e.key === 'v') {
            await pasteValue({
              productId,
              previousData: { [fieldName]: defaultValue },
              product,
              fieldName,
            });
          }
        }
      }}>
      <input
        ref={handleInputRef}
        onWheel={(e) => e.currentTarget.blur()}
        key={defaultValue}
        type="number"
        readOnly={readOnly}
        className="number_cell_input"
        style={{ cursor: readOnly ? 'pointer' : 'text' }}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Cell>
  );
}
