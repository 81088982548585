import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authApiClient } from '../api';
import { ROOM_QUERY_KEY } from './use_room';

const sendMsgToDb = (msg) => authApiClient.post('https://portaireapi.herokuapp.com/message/', msg);

export const useSendMessageToDb = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(sendMsgToDb, {
    onSuccess: (data) => queryClient.invalidateQueries([ROOM_QUERY_KEY, data.room]),
  });

  return {
    sendMsgToDb: mutateAsync,
    isLoading,
  };
};
