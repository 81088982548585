import { useState } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Sorting } from '../organizing_data';
import { useProductsContext } from '../../context/product.context';

export default function SortButton({ icon, danger, hideSuppliers }) {
  const { sort, saveSort } = useProductsContext();

  const [clicked, setClicked] = useState(false);

  const { ref } = useClickOutside(() => {
    setClicked(false);
  });

  const active = Object.keys(sort).filter((key) => key !== 'createdAt');
  const activeItemsLength = active.length;

  return (
    <div className={`button-filter__wrapper ${activeItemsLength > 0 ? 'active' : ''}`}>
      <div
        className={`button-filter ${danger ? 'button-filter--danger' : ''} ${
          clicked ? 'active ' : ''
        }`}
        onClick={() => {
          setClicked((p) => !p);
        }}>
        <img src={icon} alt="Icon" />
        {activeItemsLength > 0 && (
          <p>{`${active[0]} ${activeItemsLength > 1 ? `+${activeItemsLength - 1}` : ''}`}</p>
        )}
      </div>

      {clicked && (
        <div ref={ref} className={`button-filter__dropdown`}>
          <Sorting
            initialConditions={sort}
            onApply={(data) => {
              saveSort(data);
              setClicked(false);
            }}
            hideSuppliers={hideSuppliers}
          />
        </div>
      )}
    </div>
  );
}
