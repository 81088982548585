import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

export const ROOM_QUERY_KEY = 'ROOM_QUERY';

const getRoom = (id) => {
  if (id) {
    return authApiClient.get(`message/room/${id}`).then((res) => res.data);
  } else {
    return [];
  }
};

export const useRoom = (id, onSuccess) => {
  const { data = [], isLoading } = useQuery([ROOM_QUERY_KEY, id], () => getRoom(id), {
    onSuccess,
    enabled: Boolean(id),
  });

  return { data, isLoading };
};
