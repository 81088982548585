import './BaseSelect.css';
import { useState } from 'react';
import Select, { components } from 'react-select';
import ChevronDown from './imgs/ChevronDown.svg';
import Search from './imgs/Search.svg';

export const BaseSelect = ({
  options,
  onChange,
  placeholder,
  id,
  defaultValue,
  searchable = false,
  disabled = false,
  multiselect = false,
  closeMenuOnSelect = true,
  value,
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const showSearchIcon = optionsOpen && searchable;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {showSearchIcon ? (
          <img alt="search" src={Search} style={{ marginLeft: '4px' }} />
        ) : (
          <img alt="open" src={ChevronDown} style={{ marginLeft: '4px' }} />
        )}
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      id={id || 'base_select'}
      isDisabled={disabled}
      classNamePrefix="base_select"
      backspaceRemovesValue
      escapeClearsValue
      defaultValue={defaultValue}
      maxMenuHeight={140}
      value={value}
      isMulti={multiselect}
      onMenuOpen={() => setOptionsOpen(true)}
      onMenuClose={() => setOptionsOpen(false)}
      onChange={(value) => {
        onChange(value);
      }}
      placeholder={placeholder}
      isSearchable={searchable}
      closeMenuOnSelect={closeMenuOnSelect}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
      options={options}
      unstyled
    />
  );
};
