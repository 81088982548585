import { createContext, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

const ProvideRoutesContext = createContext();

function ProvideRoutersWrapper({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [menuNewVisible, setMenuNewVisible] = useState(true);

  const [quickViewVisible, setQuickViewVisible] = useState(false);
  const [activeProduct, setActiveProduct] = useState();

  const dashboardVisible = useMatch('/');
  const profileVisible = useMatch('/account');
  const inventoryVisible = useMatch('/inventory');
  const settingsVisible = useMatch('/settings');
  const messagesVisible = useMatch('/messages');
  const supplierPageVisible = useMatch('/preview');
  const profileImageGalleryVisible = useMatch('/account/gallery');
  const activeRoute = location.pathname;
  const pathname = activeRoute.split('/')[1];
  const activeTab = pathname === '' ? 'dashboard' : pathname;

  const onNavigate = (tabName) => {
    navigate(`/${tabName}`);
  };

  useEffect(() => {
    setQuickViewVisible(false);
  }, [location]);

  return (
    <ProvideRoutesContext.Provider
      value={{
        menuNewVisible,
        setMenuNewVisible,
        quickViewVisible,
        setQuickViewVisible,
        activeProduct,
        setActiveProduct,
        supplierPageVisible,
        profileVisible,
        dashboardVisible,
        inventoryVisible,
        settingsVisible,
        messagesVisible,
        profileImageGalleryVisible,
        onNavigate,
        activeTab,
        activeRoute,
      }}>
      {children}
    </ProvideRoutesContext.Provider>
  );
}

export { ProvideRoutersWrapper, ProvideRoutesContext };
