import { useState, useEffect } from 'react';
import { useProductGroupData, useUpdateProductGroups } from '../../queries/use_product_groups';

export const useGroupModal = (groupName, groupId) => {
  const { updateGroupsData } = useUpdateProductGroups();
  const [submitting, setSubmitting] = useState(false);
  const { data: groupData, isLoading, isRefetching } = useProductGroupData(groupId);

  const [optionsDiff, setOptionsDiff] = useState(0);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [name, setName] = useState(groupName);

  const [productOptions, setProductOptions] = useState([]);

  const oldOptionNames = groupData?.options?.map((option) => option?.option_name);
  const optionNames = productOptions?.map((option) => option.option_name);

  useEffect(() => {
    const diff = oldOptionNames?.filter((x) => !optionNames?.includes(x)) ?? [];
    setOptionsDiff(diff.length);
  }, [oldOptionNames, optionNames, setOptionsDiff]);

  useEffect(() => {
    setProductOptions(groupData?.options);
  }, [groupData?.options]);

  const handleProductsUpdates = async () => {
    setSubmitting(true);
    const newProductOptions = productOptions
      ?.filter(
        (newOptions) => Boolean(newOptions.option_name) && newOptions.available_options.length > 0
      )
      .map((newOptions) => {
        return {
          option_name: newOptions.option_name,
          available_options: [...new Set(newOptions.available_options)],
        };
      });

    try {
      await updateGroupsData({
        id: groupId,
        data: {
          name,
          options: newProductOptions,
        },
      });
    } catch (e) {
      console.log('ERROR WHILE UPDATING GROUP DATA', e);
    } finally {
      setSubmitting(false);
    }
  };

  return {
    optionsDiff,
    disableSubmit: optionsDiff > 0 && saveDisabled,
    name,
    productOptions,
    setProductOptions,
    setName,
    setSaveDisabled,
    optionNames,
    handleProductsUpdates,
    submitting,
    dataReady: !isLoading && !isRefetching,
  };
};
