import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

export const CONVERSATION_QUERY_KEY = 'CONVERSATION_QUERY_KEY';

const getConversations = (supplierId) =>
  authApiClient.get(`/message/conversations/${supplierId}`).then((res) => res.data);

export const useConversations = (supplierId) => {
  const {
    data = [],
    isLoading,
    isRefetching,
  } = useQuery([CONVERSATION_QUERY_KEY], () => getConversations(supplierId), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: Boolean(supplierId),
  });

  return { data, isLoading, isRefetching };
};
