import { useMutation, useQuery } from '@tanstack/react-query';
import { apiClient, authApiClient } from './index';
import { queryClient } from './query-client';
import { cookies } from './cookies';

export const signIn = async (email, password) => {
  const response = await apiClient.post('/auth/login', { email, password });
  return response?.data?.authToken;
};

export const useSignIn = () =>
  useMutation(['signIn'], ({ email, password }) => signIn(email, password), {
    onSuccess: (authToken) => {
      if (authToken) {
        cookies.setAccessToken(authToken);
        window.location.reload();
      }
    },
  });

export const signOut = async () => {
  cookies.removeAccessToken();
  queryClient.removeQueries();
};

const getUserData = (id) => apiClient.get(`/user/${id}`).then((res) => res.data);
export const geMe = () =>
  authApiClient.get('/auth/verify').then((res) => getUserData(res.data._id));

export const ME_QUERY_KEY = 'ME_QUERY';

export const useUser = () => {
  const {
    data: user,
    isLoading,
    isRefetching,
  } = useQuery([ME_QUERY_KEY], geMe, {
    enabled: Boolean(cookies.accessToken),
    refetchOnWindowFocus: false,
  });

  return {
    userId: user?._id,
    isRefetching,
    isLoading,
    userData: user,
    hasUser: Boolean(user),
    isAdmin: Boolean(user?.admin),
  };
};
