import './GenericButton.scss';
import Spin from './imgs/spinner-white.svg';

export default function GenericButton({
  label,
  color,
  backgroundColor,
  disabled,
  onClick,
  topSeparator,
  styling,
  loading,
}) {
  return (
    <div
      className={`button-generic__wrapper ${
        topSeparator ? 'button-generic__wrapper--top-separator' : 'm0'
      } `}>
      <button
        className={`button-generic ${disabled ? 'disabled' : styling ? 'button--modal' : ''}`}
        style={{
          backgroundColor: backgroundColor,
          color: color,
        }}
        onClick={onClick}>
        {loading ? (
          <img className="spinner spinner--smaller" src={Spin} alt="Spinner icon" />
        ) : (
          label
        )}
      </button>
    </div>
  );
}
