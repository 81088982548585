import { useInfiniteQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const USER_PRODUCTS_QUERY_KEY = 'PRODUCTS_QUERY';

const getProducts = ({ page }) =>
  authApiClient
    .get('/products/filter', {
      params: {
        filter: {},
        sort: { 'Product Name': 1 },
        perPage: 20,
        page,
      },
    })
    .then((res) => res.data);

export const useUserProducts = ({ enabled = true }) => {
  const {
    data = [],
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [USER_PRODUCTS_QUERY_KEY],
    ({ pageParam }) => getProducts({ page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.meta.nextPage > lastPage.meta.currentPage
          ? lastPage.meta.nextPage
          : undefined;
      },
      getPreviousPageParam: (firstPage) => firstPage?.meta?.previousPage ?? undefined,
      enabled,
    }
  );
  return {
    data: data?.pages?.map((page) => page.data).flat() || [],
    meta: data?.pages?.length > 0 ? data?.pages[0]?.meta : {},
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  };
};
