import PdfIcon from './imgs/pdf_icon.svg';
import ImgIcon from './imgs/img_icon.svg';

export default function PillFile({ backgroundColor, label, icon, color }) {
  return (
    <div
      style={{
        fontSize: '12px',
        backgroundColor: backgroundColor,
        color: color,
        padding: '6px',
        marginRight: '3px',

        borderRadius: '3px',
        cursor: 'default',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}>
      <img width="16px" src={label.slice(-4).trim() == 'pdf' ? PdfIcon : ImgIcon} />
      ...{label.slice(label.length - 10, label.length)}
    </div>
  );
}
