import axios from 'axios';
import { cookies } from './cookies';
import { BASE_URL } from '../consts/env-variables';

export const apiClient = axios.create({
  baseURL: `${BASE_URL}`,
});

export const authApiClient = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    Authorization: `Bearer ${cookies.accessToken}`,
  },
});
