import '../../../assets/style/context-menu.scss';
import OpenSideIcon from '../imgs/open-side.svg';
import DeleteIcon from '../imgs/delete-icon.svg';
import { useClickOutside } from '../../../hooks/useClickOutside';

export default function ContextMenu({ x, y, showMenu, setShowMenu, setQuickViewVisible, onClick }) {
  const { ref } = useClickOutside(() => {
    setShowMenu(false);
  });

  return showMenu ? (
    <div
      ref={ref}
      className="context-menu"
      style={{
        top: y,
        left: x,
      }}
      onContextMenu={(e) => e.preventDefault()}>
      <div
        onClick={() => {
          setQuickViewVisible(true);
          setShowMenu(false);
        }}>
        <img src={OpenSideIcon} alt="Open" />
        <p>Open in side bar</p>
      </div>
      <div
        onClick={() => {
          setShowMenu(false);
          onClick();
        }}>
        <img src={DeleteIcon} alt="Delete" />
        <p className="text-danger">Remove product</p>
      </div>
    </div>
  ) : null;
}
