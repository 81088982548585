import Contacts from './Contacts';
import Thread from './Thread';
import Details from './Details';

import { useState } from 'react';
import { useUser } from '../../queries/use_user';

export default function Messaging() {
  const [contact, setContact] = useState('');
  const { data: userData } = useUser({ userId: contact });

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Contacts setContact={setContact} />
        <Thread contact={contact} />
        {contact && <Details user={userData} />}
      </div>
    </>
  );
}
