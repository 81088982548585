import { AVAILABLE_COLUMNS } from './mocks';
import CloseHandle from '../imgs/Close.svg';
import { BaseSelect } from '../../base_select/BaseSelect';

export const SortingRow = ({
  index,
  field,
  changeFieldType,
  changeSortOption,
  remainingOptions,
  removeCondition,
  fieldChangeDisabled = false,
}) => {
  const prefix = index === 0 ? 'Sort by' : 'Then by';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: '13px',
        lineHeight: '8px',
        width: 'max-content',
        alignItems: 'center',
      }}>
      <div
        style={{
          width: '80px',
        }}>
        {prefix}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '124px 62px 188px 24px',
          alignItems: 'center',
        }}>
        <BaseSelect
          name="fieldType"
          searchable
          onChange={(value) => {
            changeFieldType(value.id, field?.name);
          }}
          disabled={fieldChangeDisabled}
          defaultValue={field?.name}
          value={{ name: field?.name, label: field?.name }}
          options={remainingOptions.map((column) => ({ id: column, label: column }))}
        />
        <div
          style={{
            width: '63px',
            height: '27px',
            backgroundColor: '#F9F9F9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          from
        </div>

        <BaseSelect
          defaultValue={field?.order}
          value={AVAILABLE_COLUMNS[field?.name]?.conditions?.find(
            (condition) => condition.id === field?.order
          )}
          id="sortType"
          placeholder={'Select sort type'}
          onChange={(value) => changeSortOption(value.id)}
          options={AVAILABLE_COLUMNS[field?.name]?.conditions}
        />
        <img
          onClick={() => removeCondition(field?.name)}
          src={CloseHandle}
          role="button"
          alt="remove filter"
          className="close-handle"
        />
      </div>
    </div>
  );
};
