import ReactDOM from 'react-dom';
import { useRef, useState } from 'react';
import './TextModal.css';
import CloseIcon from './imgs/close.svg';
import { useClickOutside } from '../../hooks/useClickOutside';

export default function TextModal({ onClose, defaultValue, open }) {
  const [modalValue, setModalValue] = useState(defaultValue);

  const handleModalClose = async (value) => onClose(value);

  const { ref } = useRef(null);

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <div onClick={handleModalClose} className="text_modal_background">
      <div className="text_modal" onClick={(e) => e.stopPropagation()}>
        <img
          src={CloseIcon}
          className="close_handle"
          alt="close modal"
          role="button"
          onClick={() => handleModalClose(ref.current.value)}
        />

        <textarea
          ref={ref}
          className="text_modal_content"
          onBlur={(e) => handleModalClose(e.target.value)}
          onChange={(e) => {
            setModalValue(e.target.value);
          }}
          defaultValue={defaultValue}
        />
      </div>
    </div>,
    document.body
  );
}
