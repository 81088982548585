import { useState, useRef } from 'react';
import ButtonTopMenu from '../button_top_menu/ButtonTopMenu';
import PlusIcon from './imgs/plus.svg';
import SearchIcon from './imgs/search.svg';
import FilterIcon from './imgs/filter.svg';
import DeleteIcon from './imgs/delete.svg';
import Grouping from './imgs/group.svg';
import ProductShowDetails from './imgs/products-icon-active.svg';
import MediumRows from './imgs/medium-rows.svg';
import LargeRows from './imgs/large-rows.svg';
import Rows from './imgs/rows.svg';
import Sort from './imgs/sort.svg';
import CloseIcon from './imgs/close-icon.svg';
import FilterButton from '../button_top_menu/FilterButton';
import SortButton from '../button_top_menu/SortButton';
import { useClickOutside } from '../../hooks/useClickOutside';

import { useProductsContext } from '../../context/product.context';
import { useRemoveProducts } from '../../queries/use_products';
import { useUser } from '../../api/auth';

export default function TopBarMenu({ filtersVisible, setFiltersVisible, menuNewVisible }) {
  const { removeProducts } = useRemoveProducts();
  const { isAdmin } = useUser();
  const { rowsSelected, setRowsSelected, setSearchText, setPage, searchTextInput } =
    useProductsContext();

  const [switchSearchIcon, setSwitchSearchIcon] = useState(true);
  // const [showDetails, setShowDetails] = useState(true);

  // const toggleDetails = () => {
  //   setShowDetails((p) => !p);
  // };
  const { ref } = useClickOutside(() => {
    setSwitchSearchIcon(true);
  });

  const group = [{ name: 'Status' }, { name: 'Product Group' }, { name: 'Clear', danger: true }];

  const rows = [
    { name: 'Compact rows', icon: ProductShowDetails },
    { name: 'Medium rows', icon: MediumRows },
    { name: 'Large rows', icon: LargeRows },
  ];

  const searchRef = useRef();
  return (
    <div
      style={{
        width: `${menuNewVisible ? 'calc(100% - 245px)' : 'calc(100% - 72px)'}`,
        zIndex: '520',
        padding: '12px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '6px',
        alignItems: 'center',
        borderBottom: '1px solid #f2f2f2',
        position: 'fixed',
        top: '0px',
        backgroundColor: 'white',
        transition: 'all 0.4s',
      }}>
      <div style={{ display: 'flex', gap: '6px' }}>
        <FilterButton icon={FilterIcon} hideSuppliers={!isAdmin} />
        <SortButton icon={Sort} hideSuppliers={!isAdmin} />

        {rowsSelected?.length > 0 && (
          <ButtonTopMenu
            color="black"
            danger
            backgroundColor="#F6F6F6"
            icon={DeleteIcon}
            onClick={async () => {
              await removeProducts(rowsSelected);
              setRowsSelected([]);
            }}
          />
        )}
      </div>
      <div className={`top-bar-search__wrapper ${!switchSearchIcon ? 'active' : ''}`}>
        <input
          ref={searchRef}
          spellCheck={false}
          value={searchTextInput}
          placeholder="Type to search"
          type="text"
          onChange={(e) => {
            setPage(0);
            setSearchText(e.target.value);
          }}
        />

        <img
          style={{ cursor: 'pointer' }}
          onClick={() => {
            searchRef.current.focus();
            setSwitchSearchIcon((p) => !p);
            if (!switchSearchIcon) {
              setSearchText('');
              searchRef.current.value = '';
            }
          }}
          src={switchSearchIcon ? SearchIcon : CloseIcon}
          alt="search"
        />
      </div>
    </div>
  );
}
