import { useUser } from '../api/auth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { authApiClient } from '../api';
import { ACTIVE_SUPPLIER_QUERY_KEY, getSupplier } from './use_supplier';

export const useUserSupplier = ({ onSuccess }) => {
  const { userData } = useUser();
  const supplierId = userData?.['SupplierId'];

  const {
    data = {},
    isLoading,
    isRefetching,
  } = useQuery([ACTIVE_SUPPLIER_QUERY_KEY], () => getSupplier({ supplierId }), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onSuccess,
    enabled: !!supplierId,
  });

  return { data, isLoading, isRefetching };
};

export const useUpdateUserSupplier = () => {
  const queryClient = useQueryClient();
  const { userData } = useUser();
  const supplierId = userData?.['SupplierId'];

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.put(`suppliers/${supplierId}`, data),
    {
      onSuccess: () => queryClient.invalidateQueries([ACTIVE_SUPPLIER_QUERY_KEY]),
    }
  );

  return {
    updateSupplierData: mutateAsync,
    isLoading,
  };
};
