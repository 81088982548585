import React, { useState } from 'react';
import { useUpdateProduct } from '../queries/use_products';

// TODO: this will be removed after new select component is fully implemented in the portable

const AuthContext = React.createContext();

function AuthProviderWrapper(props) {
  const { updateProductData } = useUpdateProduct();
  const [projects, setProjects] = useState();

  const [activeProduct, setActiveProduct] = useState();

  const saveOnBlur = async () => {
    await updateProductData({ product: activeProduct, id: activeProduct['_id'] });
  };

  const saveProduct = async (product) => {
    await updateProductData({ product, id: product['_id'] });
  };

  return (
    <AuthContext.Provider
      value={{
        projects,
        setProjects,
        activeProduct,
        setActiveProduct,
        saveOnBlur,
        saveProduct,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProviderWrapper, AuthContext };
