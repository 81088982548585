import { useState, useEffect } from 'react';
import { textAreaHandlers } from '../utils';

export const useTextAreaCell = () => {
  const [focused, setFocused] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (focused && !expanded) {
      return textAreaHandlers(setExpanded);
    }
  }, [expanded, focused]);

  const showModal = () => {
    setModalOpen(true);
    setExpanded(false);
  };

  const hideModal = () => setModalOpen(false);

  const removeFocus = () => {
    setFocused(false);
    setExpanded(false);
  };

  return {
    focused,
    setFocused,
    expanded,
    setExpanded,
    modalOpen,
    showModal,
    hideModal,
    removeFocus,
  };
};
