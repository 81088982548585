import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const MATERIAL_QUERY_KEY = 'MATERIAL_QUERY';

const getMaterial = () => authApiClient.get('/tag/material').then((res) => res.data);

export const useMaterial = () => {
  const { data = [], isLoading } = useQuery([MATERIAL_QUERY_KEY], getMaterial, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
