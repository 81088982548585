export default function FieldControl({ children, error }) {
  const hasError = Boolean(error);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      {children}
      {hasError && <div style={{ fontSize: '12px', color: 'red' }}>{error}</div>}
    </div>
  );
}
