import BackIcon from './imgs/back.svg';

export default function TopBarSuppliers({ onClick }) {
  return (
    <div
      style={{
        width: '100%',
        padding: '16px',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '6px',
        borderBottom: '1px solid #f2f2f2',
        borderRadius: '2px',
      }}>
      <div
        style={{
          backgroundColor: 'white',
          color: 'black',
          padding: '8px',
          fontSize: '12px',
          display: 'flex',
          gap: '6px',
          borderRadius: '2px',
          cursor: 'pointer',
        }}
        onClick={onClick}>
        <img src={BackIcon} /> Back
      </div>
    </div>
  );
}
