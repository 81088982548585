import { forwardRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import TrashIcon from '../imgs/trash.svg';
import DownloadIcon from '../imgs/download.svg';

export const FileCard = forwardRef(({ file, fileName, onRemove, onDownload, ...props }, ref) => {
  return (
    <div ref={ref} {...props}>
      <div className="file_card_nav">
        <div className="file_card" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}>
          <span>{fileName}</span>
          <div className="file_card_actions">
            <a className="file_card_action_button" href={file} download>
              <img onClick={onDownload} alt="download" role="button" src={DownloadIcon} />
            </a>
            <button className="file_card_action_button" onClick={onRemove}>
              <img alt="remove" src={TrashIcon} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export const SortableFileCard = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return <FileCard ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />;
};
