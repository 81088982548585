import { useState } from 'react';

import Cell from './Cell';
import PlusIcon from '../imgs/Plus.svg';
import TagGroup from '../../tag_group/TagGroup';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useProductGroups } from '../../../queries/use_product_groups';
import SettingsIcon from '../imgs/settings.svg';
import FuseSearch from './FuseSearch';
import { useActionsContext } from '../../../context/actions.context';

export default function ProductGroupCell({
  value,
  defaultValue,
  onChange,
  onBlur,
  groupId,
  type = 'text',
  supplierId,
  onCreateGroup,
  clearValue,
  onSettingsClick,
  productId,
  setActiveProduct,
  product,
  index,
  rowIndex,
  columnIndex,
  ...cellProps
}) {
  const { copyValue, pasteValue } = useActionsContext();
  const values =
    value && Boolean(value?.length) ? [value].flat().filter((el) => Boolean(el)) : null;
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const { data: productGroups = [] } = useProductGroups(supplierId, focused);

  const { ref } = useClickOutside(() => {
    setExpanded(false);
    setFocused(false);
  });
  const hasTags = values?.length > 0;
  const baseStyle = expanded ? 'expandable_cell--focused' : '';
  const styleOverrides = expanded ? { position: 'relative', outline: 'none' } : {};

  const handleOptionChange = (option) => {
    onChange(option);
    setExpanded(false);
  };

  return (
    <Cell
      {...cellProps}
      id={index}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      defaultValue={product['GroupId']}
      fieldName={'GroupId'}
      onClick={(e) => {
        setFocused(true);
      }}
      onDoubleClick={(e) => {
        setExpanded(true);
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Escape' && expanded) {
          return setExpanded(false);
        }

        if (e.key === 'Enter') {
          return setExpanded(!expanded);
        }

        if (e.key === 'Backspace' && !expanded && hasTags) {
          return clearValue();
        }

        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'c') {
            return copyValue({ data: { GroupId: product['GroupId'] } });
          }
          if (e.key === 'v') {
            await pasteValue({
              productId,
              previousData: { GroupId: product['GroupId'] },
              product,
              fieldName: 'GroupId',
            });
            return;
          }

          setExpanded(false);
        }
      }}
      styleOverrides={{ ...styleOverrides, cursor: 'pointer' }}>
      <div ref={ref} className={baseStyle}>
        {expanded ? (
          <FuseSearch
            open={expanded}
            selected={values}
            options={productGroups ?? []}
            onOptionSelect={handleOptionChange}
            creatable
            onCreateOption={onCreateGroup}
          />
        ) : hasTags ? (
          <div style={{ display: 'flex', gap: '4px' }}>
            <div
              style={{ maxWidth: '200px', overflow: 'auto', scrollbarWidth: 'none' }}
              className="no-scrollbar"
              onClick={() => {
                setActiveProduct(product);
              }}>
              <TagGroup
                key={product['GroupId']._id}
                tags={values}
                onTagRemove={clearValue}
                showCloseHandles={focused}
                variant={'secondary'}
              />
            </div>
            {focused && (
              <img
                src={SettingsIcon}
                style={{
                  cursor: 'pointer',
                }}
                alt="Add"
                role="button"
                onClick={() => {
                  setActiveProduct(product);
                  onSettingsClick(true);
                }}
              />
            )}
          </div>
        ) : (
          <img
            src={PlusIcon}
            style={{
              cursor: 'pointer',
            }}
            alt="Add"
            role="button"
            onClick={() => setExpanded(true)}
          />
        )}
      </div>
    </Cell>
  );
}
