import { Wrapper } from '../shared/Wrapper';
import { SortingRow } from './SortingRow';
import { useSortManager } from './useSorting';

export const Sorting = ({ onApply, initialConditions, hideSuppliers }) => {
  const {
    canCreate,
    remaining,
    conditions,
    createCondition,
    changeSortOption,
    removeCondition,
    changeFieldType,
  } = useSortManager(initialConditions, hideSuppliers);

  const handleApplyConditions = () => {
    const newSort = conditions.reduce(
      (o, condition) => ({ ...o, [condition.name]: condition.order }),
      {}
    );

    onApply(newSort);
  };

  return (
    <Wrapper
      title={'Sort records'}
      onApply={handleApplyConditions}
      onCreate={createCondition}
      createDisabled={!canCreate}>
      <>
        {conditions.map((condition, i) => (
          <SortingRow
            key={condition.name}
            index={i}
            remainingOptions={remaining}
            field={condition}
            fieldChangeDisabled={!canCreate}
            changeFieldType={changeFieldType(i)}
            removeCondition={removeCondition(i)}
            changeSortOption={changeSortOption(i)}
          />
        ))}
      </>
    </Wrapper>
  );
};
