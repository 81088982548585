import { useActionsContext } from '../../context/actions.context';

export const useProductUpdate = (product, products) => {
  const { changeValue } = useActionsContext();

  const clearProductValue = async (fieldName) => {
    await changeValue({
      product,
      productId: product['_id'],
      newValues: { [fieldName]: null },
      previousData: { [fieldName]: product[fieldName] },
    });
  };

  const updateProductValue = async (fieldName, value) => {
    await changeValue({
      product,
      productId: product['_id'],
      newValues: { [fieldName]: value },
      previousData: { [fieldName]: product[fieldName] },
    });
  };

  const updateProductData = async ({ id, data, previousData }) => {
    await changeValue({
      product,
      productId: id,
      newValues: data,
      previousData,
    });
  };

  return { clearProductValue, updateProductValue, updateProductData };
};
