import OptionsManager from './parts/OptionsManager';
import GenericButton from '../generic_button/GenericButton';
import Confirmation from './parts/Confirmation';
import GroupName from './parts/GroupName';
import { useGroupModal } from './GroupModal.hooks';

export default function GroupModal({ groupName, groupId, closeModal }) {
  const {
    optionsDiff,
    disableSubmit,
    name,
    productOptions,
    setProductOptions,
    setName,
    setSaveDisabled,
    optionNames,
    handleProductsUpdates,
    submitting,
    dataReady,
  } = useGroupModal(groupName, groupId);

  return (
    dataReady && (
      <div
        style={{
          width: '420px',
          backgroundColor: 'white',
          position: 'absolute',
          left: '33%',
          top: '25%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '20px',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          zIndex: '190',
        }}>
        <div style={{ fontSize: '24px' }}> Manage Product Group</div>

        <GroupName groupName={name} onNameChange={setName} />
        <hr className="divider" />
        <OptionsManager
          key={dataReady}
          optionNames={optionNames}
          productOptions={productOptions}
          setProductOptions={setProductOptions}
        />
        {optionsDiff > 0 && (
          <Confirmation
            label={`I confirm I want to delete ${optionsDiff} options and all associated variants.`}
            onChange={(e) => setSaveDisabled(!e.target.checked)}
          />
        )}
        <div style={{ display: 'flex', gap: '10px' }}>
          <GenericButton label="Cancel" backgroundColor="white" onClick={closeModal} />
          <GenericButton
            key={optionsDiff}
            label={submitting ? 'Saving...' : 'Save'}
            color="white"
            backgroundColor="black"
            onClick={async () => {
              await handleProductsUpdates();
              closeModal();
            }}
            disabled={disableSubmit || submitting}
          />
        </div>
      </div>
    )
  );
}
