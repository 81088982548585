import { useEffect, useRef } from 'react';

export const useClickOutside = (onClickOutsideCallback) => {
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      onClickOutsideCallback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return { ref };
};
