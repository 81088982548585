import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import InfoIcon from './imgs/info.svg';

export default function Tooltip({ content, id }) {
  return (
    <>
      <img
        src={InfoIcon}
        role="button"
        style={{ width: '12px', height: '12px' }}
        alt="info tooltip"
        data-tooltip-id={id}
      />
      <ReactTooltip id={id} place="bottom">
        <div style={{ fontSize: '10px', maxWidth: '100px', lineBreak: 'auto' }}>{content}</div>
      </ReactTooltip>
    </>
  );
}
