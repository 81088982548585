export default function InputAccountProfile({
  placeholder,
  label,
  type,
  error,
  errorMessage,
  errors,
  paddingLeft,
  onChange,
  onClick,
  search,
  fontSize,
  inputHeight,
  optional,
  value,
  textarea,
  marginBottom,
  disabled,
  errorPosition,
  updateButton,
  defaultValue,
}) {
  return (
    <div
      style={{
        width: '100%',
        marginBottom: marginBottom || '18px',
      }}>
      <label
        style={{
          fontSize: '12px',
          marginBottom: '5px',
          paddingLeft: paddingLeft || '4px',
          display: !label && 'flex',
          fontFamily: 'Object Sans Medium',
          position: 'relative',
        }}>
        {label}
        {optional && <span className="optional"> (optional)</span>}
        <br />
        {!textarea ? (
          <input
            onChange={onChange || undefined}
            className="generic-input"
            value={value}
            disabled={disabled}
            style={{
              width: '100%',
              height: inputHeight || '50px',
              fontSize: fontSize || '16px',
              border: errors && error ? '1px solid #E17B7B' : '1px solid #D5D4D4',
              borderRadius: !search ? '3px' : '3px 0px 0px 3px',
              borderRight: search
                ? 'none'
                : errors && error
                ? '1px solid #E17B7B'
                : '1px solid #D5D4D4',
              paddingLeft: '10px',
              outline: 'none',
            }}
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
        ) : (
          <textarea
            onChange={onChange || undefined}
            className="generic-input generic-input--textarea"
            value={value}
            placeholder={placeholder}
          />
        )}
        {updateButton && (
          <span
            onClick={onClick}
            style={{
              position: 'absolute',
              top: '31px',
              right: '15px',
              color: '#0066FF',
              cursor: 'pointer',
              fontFamily: 'Object Sans ',
            }}>
            Update
          </span>
        )}

        {search && (
          <img
            src={''}
            alt=""
            style={{
              border: '1px solid #D5D4D4',
              borderRadius: '0px 3px 3px 0px',
              borderLeft: 'none',
              paddingRight: '10px',
            }}
          />
        )}
      </label>
      {errors && error && (
        <div className="input-text input-text--error" style={{ right: errorPosition || '0px' }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}
