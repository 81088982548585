import './Shared.css';
import { DropdownFooter } from './DropdownFooter';

export const Wrapper = ({ title, onApply, onCreate, children, createDisabled = false }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '520px',
        gap: '8px',
      }}>
      <span className="data_title">{title}</span>
      <div style={{ padding: '0 13px', display: 'flex', flexDirection: 'column', gap: '6px' }}>
        {children}
      </div>
      <hr className="data_divider" />
      <DropdownFooter onAdd={onCreate} onApply={onApply} createDisabled={createDisabled} />
    </div>
  );
};
