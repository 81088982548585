import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const COLOUR_QUERY_KEY = 'COLOUR_QUERY';

const getColour = () => authApiClient.get('/tag/colour').then((res) => res.data);

export const useColour = () => {
  const { data = [], isLoading } = useQuery([COLOUR_QUERY_KEY], getColour, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
