import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const STYLE_QUERY_KEY = 'STYLE_QUERY';

const getStyle = () => authApiClient.get('/tag/style').then((res) => res.data);

export const useStyle = () => {
  const { data = [], isLoading } = useQuery([STYLE_QUERY_KEY], getStyle, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
