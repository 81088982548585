import OptionRow from '../parts/option/OptionRow';

export default function OptionsManager({ productOptions = [], setProductOptions, optionNames }) {
  return (
    <div
      key={productOptions.length}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '100px 260px 8px',
          gap: '5px',
          marginBottom: '5px',
        }}>
        <span
          style={{
            paddingLeft: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '8px',
          }}>
          Name
        </span>
        <span
          style={{
            paddingLeft: '4px',
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '8px',
          }}>
          Options
        </span>
      </div>
      {productOptions?.map((option, i) => (
        <OptionRow
          optionNames={optionNames}
          name={option?.option_name}
          values={option?.['available_options'] ?? []}
          key={`option-${i}`}
          onOptionRemove={() => {
            setProductOptions((prev) => prev.filter((_, index) => index !== i));
          }}
          onNameChange={(e) => {
            const newName = e.target.value;
            setProductOptions((prev) => {
              const newOptions = [...prev];
              newOptions[i].option_name = newName;
              return newOptions;
            });
          }}
          onOptionsChange={(_name, value) => {
            setProductOptions((prev) => {
              const newOptions = [...prev];
              newOptions[i]['available_options'] = [
                ...new Set([...newOptions[i]['available_options'], value]),
              ];
              return newOptions;
            });
          }}
          onTagRemove={(name) => {
            setProductOptions((prev) => {
              const newOptions = [...prev];
              newOptions[i]['available_options'] = newOptions[i]['available_options'].filter(
                (o) => o !== name
              );
              return newOptions;
            });
          }}
        />
      ))}
      <div
        role="button"
        style={{
          marginTop: '20px',
          fontSize: '14px',
          lineHeight: '18px',
          color: '#7D8693',
          cursor: 'pointer',
        }}
        onClick={() => {
          setProductOptions((prev) => {
            const prevOptions = [...prev];
            prevOptions.push({ name: '', available_options: [] });
            return prevOptions;
          });
        }}>
        Add row +
      </div>
    </div>
  );
}
