const STRING_CONDITIONS = [
  { label: 'Alphabetical (A-Z)', id: 1 },
  { label: 'Reverse Alphabetical (Z-A)', id: -1 },
];

const NUMBER_CONDITIONS = [
  { label: 'Ascending Order (1-9)', id: 1 },
  { label: 'Descending Order (9-1)', id: -1 },
];

const BOOLEAN_CONDITIONS = [
  { label: 'True first', id: 1 },
  { label: 'False first', id: -1 },
];

export const AVAILABLE_COLUMNS = {
  'Supplier Name': { conditions: STRING_CONDITIONS },
  'Product Name': { conditions: STRING_CONDITIONS },
  'Product Group': { conditions: STRING_CONDITIONS },
  Location: { conditions: STRING_CONDITIONS },
  Status: { conditions: STRING_CONDITIONS },
  Categories: { conditions: STRING_CONDITIONS },
  Customisable: { conditions: BOOLEAN_CONDITIONS },
  Weight: { conditions: NUMBER_CONDITIONS },
};
