import { Wrapper } from '../shared/Wrapper';
import { FilterRow } from './FilterRow';
import { useFiltersManager } from './useFilters';

export const Filters = ({ onApply, initialConditions, hideSuppliers }) => {
  const {
    filters,
    changeFieldType,
    setFilterType,
    setFieldValue,
    changePrefix,
    createFilter,
    removeFilter,
  } = useFiltersManager(initialConditions);

  const getQuery = (condition, value, name) => {
    if (condition === '$exists') {
      return {
        [name]: { [condition]: true, [value]: [[], '', null] },
      };
    }

    return {
      [name]: {
        [condition]: value,
      },
    };
  };

  const handleApplyConditions = () => {
    const toApply = filters
      .filter(
        (f) => Boolean(f?.value?.length) || f.fieldType === 'bool' || f.byCondition === '$exists'
      )
      .reverse();
    const [startCondition, ...remaining] = toApply;
    let filterQuery = {};

    if (toApply.length === 0) {
      return onApply({});
    }

    if (toApply.length > 2) {
      for (let i = 0; i < remaining.length - 1; i++) {
        const [current, ...remainingConditions] = toApply.slice(i);
        const currentQuery = getQuery(current.byCondition, current.value, current.name);

        let query = {};
        let remainingQuery = {};

        if (remainingConditions.length === 1) {
          remainingQuery = getQuery(
            remainingConditions[0].byCondition,
            remainingConditions[0].value,
            remainingConditions[0].name
          );
          query = { [current.prefix]: [currentQuery, remainingQuery] };
        } else {
          remainingQuery = {
            [remainingConditions[0].prefix]: remainingConditions.map((c) =>
              getQuery(c.byCondition, c.value, c.name)
            ),
          };
          query = { [current.prefix]: [currentQuery, remainingQuery] };
        }

        filterQuery = query;
      }
    } else {
      filterQuery = {
        [startCondition.prefix]: toApply.map((c) => getQuery(c.byCondition, c.value, c.name)),
      };
    }

    if (!Object.keys(filterQuery).length) {
      return onApply({});
    }

    onApply(filterQuery);
  };

  return (
    <Wrapper title={'Filter records'} onApply={handleApplyConditions} onCreate={createFilter}>
      {filters.map((filter, index) => {
        return (
          <FilterRow
            hideSuppliers={hideSuppliers}
            key={index}
            field={filter}
            index={index}
            changeFieldType={changeFieldType(index)}
            setFilterType={setFilterType(index)}
            setFieldValue={setFieldValue(index)}
            changePrefix={changePrefix(index)}
            removeFilter={removeFilter(index)}
          />
        );
      })}
    </Wrapper>
  );
};
