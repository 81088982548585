export default function GroupName({ groupName, onNameChange }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div htmlFor="group_name" style={{ fontSize: '12px', fontWeight: 'bold', lineHeight: '8px' }}>
        Group name
      </div>
      <input
        style={{
          width: '100%',
          padding: '10px',
          marginTop: '5px',
          borderRadius: '3px',
          border: '1px solid #D5D4D4',
        }}
        defaultValue={groupName}
        onChange={(e) => {
          onNameChange(e.target.value);
        }}
        id="group_name"
      />
    </div>
  );
}
