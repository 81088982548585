import { useQueryClient, useMutation } from '@tanstack/react-query';
import { authApiClient } from '../api';

const IMAGES_QUERY_KEY = 'IMAGES_QUERY';

export const useUploadImage = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => {
      return authApiClient.post(`image`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([IMAGES_QUERY_KEY]);
      },
    }
  );

  return {
    uploadImage: mutateAsync,
    isLoading,
  };
};
