import './QuickView.scss';
import '../../assets/style/messaging.scss'; // get rid of this when messaging is here

import CloseIcon from './imgs/close.svg';
import LeftIcon from './imgs/left-arrow.svg';
import RightIcon from './imgs/right-arrow.svg';
import VerifiedIcon from './imgs/verified.svg';
import CarbonNegative from './imgs/sustainability/carbonNegative.svg';
import CarbonNeutral from './imgs/sustainability/carbonNeutral.svg';
import EthicallyMade from './imgs/sustainability/ethicallyMade.svg';
import GreenEnergy from './imgs/sustainability/greenEnergy.svg';
import LowVoc from './imgs/sustainability/lowVoc.svg';
import MadeLocally from './imgs/sustainability/madeLocally.svg';
import Recyclable from './imgs/sustainability/recyclable.svg';
import Recycled from './imgs/sustainability/recycled.svg';
import Regenerative from './imgs/sustainability/regenerative.svg';
import Sustainable from './imgs/sustainability/sustainable.svg';
import SustainablySourced from './imgs/sustainability/sustainablySourced.svg';
import PlaceholderImage from '../suppliers/imgs/placeholder.png';
import { useEffect, useState } from 'react';
import { useSupplier } from '../../queries/use_supplier';
import { useUser } from '../../api/auth';
import GenericButton from '../generic_button/GenericButton';
import { currencySymbols } from '../../consts/currencies';
import { useProductGroupData } from '../../queries/use_product_groups';
import { deepCompareArrayOfObjects } from '../../helpers/arrays';
import { useProductGroupProductsData } from '../../queries/use_product_group_products';

const sustainabilityDict = {
  'Carbon Negative': CarbonNegative,
  'Carbon Neutral': CarbonNeutral,
  'Ethically Made': EthicallyMade,
  'Green Energy': GreenEnergy,
  'Low VOC': LowVoc,
  'Made Locally': MadeLocally,
  Recyclable: Recyclable,
  recycled: Recycled,
  Regenerative: Regenerative,
  Sustainable: Sustainable,
  'Sustainability Sourced': SustainablySourced,
};
export default function QuickView({ activeProduct, setActiveProduct, setQuickViewVisible }) {
  const [tab, setTab] = useState('details');
  const [isHovered, setIsHovered] = useState(false);
  const groupId = activeProduct?.GroupId?._id ?? activeProduct?.GroupId;

  const { data: productGroup } = useProductGroupData(groupId, Boolean(groupId));
  const { data: productGroupProducts } = useProductGroupProductsData(groupId, Boolean(groupId));
  const { userData } = useUser();
  const { data: supplierData } = useSupplier({
    supplierId: activeProduct?.SupplierId?._id ?? userData?.SupplierId,
  });

  const hasPrice =
    activeProduct['Price - Retail'] &&
    activeProduct['Price - Retail'] != '0' &&
    activeProduct?.Currency;

  // TO DO THIS STATE WOS for Sustainability
  const [sustainabilityArr, setSustainabilityArr] = useState(
    activeProduct?.Sustainability &&
      typeof activeProduct?.Sustainability == Object &&
      activeProduct?.Sustainability?.split(',').filter((el) => {
        return el != '';
      })
  );
  const images =
    activeProduct?.['Product Images']?.length > 0
      ? activeProduct?.['Product Images']
      : [PlaceholderImage];

  const [activeImage, setActiveImage] = useState(images[0]);

  const numImages = images.length;

  const handleLeftArrowClick = () => {
    const index = images.indexOf(activeImage);
    let newIndex = index - 1;

    if (newIndex < 0) {
      newIndex = numImages - 1;
    }

    setActiveImage(images[newIndex]);
  };

  const handleRightArrowClick = () => {
    const index = images.indexOf(activeImage);
    let newIndex = index + 1;

    if (newIndex >= numImages) {
      newIndex = 0;
    }

    setActiveImage(images[newIndex]);
  };

  const onVariantClick = async (optionName, optionVariant) => {
    const restOptions = activeProduct?.Options?.filter((el) => {
      return el.name !== optionName;
    });
    const variant = [{ name: optionName, option_values: [optionVariant] }, ...restOptions];

    const selectedProduct = productGroupProducts?.find((product) =>
      deepCompareArrayOfObjects(product?.Options, variant, 'name')
    );

    if (selectedProduct) {
      setActiveProduct(selectedProduct);
    }
  };

  useEffect(() => {
    setActiveImage(images[0]);
  }, [activeProduct]);

  return (
    <>
      <div className="quick-view__container">
        <div className="quick-view__header">
          <div
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <div
              className="quickview-close-button"
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid white',
                padding: '8px',
                borderRadius: '3px',
              }}
              onClick={() => {
                setQuickViewVisible(false);
              }}>
              <img src={CloseIcon} alt="Close" />
            </div>
            <div>Quick View</div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {/* <img src={ContextIcon} /> */}
          </div>
        </div>

        <div className="quick-view__hero">
          <div style={{ marginTop: '40px' }}>
            <div
              style={{ position: 'relative', height: '440px' }}
              onMouseOver={() => {
                setIsHovered(true);
              }}
              onMouseOut={() => {
                setIsHovered(false);
              }}>
              <img
                width="100%"
                alt="product"
                src={activeImage}
                style={{ backgroundColor: '#F5f5f5', height: '100%', objectFit: 'cover' }}
              />
              {images.length > 1 && (
                <div
                  className="arrow-carrousel__wrapper"
                  style={{ visibility: isHovered ? 'visible' : 'hidden' }}>
                  <div className="arrow-carrousel arrow-left" onClick={handleLeftArrowClick}>
                    <img alt="left" src={LeftIcon} />
                  </div>
                  <div className="arrow-carrousel arrow-right" onClick={handleRightArrowClick}>
                    <img alt="left" src={RightIcon} />
                  </div>
                </div>
              )}
            </div>

            {images.length > 1 && (
              <div
                style={{
                  marginTop: '13px',
                  display: 'flex',
                  gap: '13.3px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}>
                {images.map((element, i) => {
                  return (
                    <img
                      key={i}
                      style={{
                        backgroundColor: '#F5f5f5',
                        cursor: 'pointer',
                        outline: activeImage == element ? '1px solid black' : '1px solid white',
                        outlineOffset: '3px',
                        width: '63px',
                        height: '63px',
                        objectFit: 'cover',
                      }}
                      alt="product "
                      src={element}
                      onClick={(e) => {
                        setActiveImage(element);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="quick-view__name">
            {supplierData['Company Name']}
            {activeProduct['Premium?'] == 'checked' && (
              <img style={{ paddingLeft: '10px' }} alt="verified" src={VerifiedIcon} />
            )}{' '}
          </div>
          <div
            style={{
              textTransform: 'uppercase',
              fontSize: '24px',
              marginTop: '21px',
              fontFamily: 'Object Sans Medium',
            }}>
            {activeProduct['Product Name']}
          </div>
          <div style={{ lineHeight: '24px', fontSize: '16px', marginTop: '4px' }}>
            {activeProduct['Description']?.replaceAll(/<\/?[^>]+(>|$)/gi, '')}
          </div>
          <div style={{ marginTop: '51px', fontSize: '18px', display: 'flex' }}>
            <span
              style={{
                fontFamily: 'Object Sans Medium',
                marginRight: '6px',
                alignContent: 'center',
              }}>
              {hasPrice ? 'Retail' : 'Price Guide'}:
            </span>
            {hasPrice ? (
              <div>
                {activeProduct?.Currency === '£'
                  ? `£${activeProduct['Price - Retail']} ${
                      currencySymbols?.[activeProduct?.Currency]
                    }`
                  : `${currencySymbols?.[activeProduct?.Currency]}${
                      activeProduct['Price - Retail']
                    } ${activeProduct?.Currency}`}
              </div>
            ) : (
              [...Array(4)].map((_, i) => (
                <span
                  key={i}
                  className={`message-details__price-guide ${
                    i < activeProduct['Price Guide']?.length ? '' : 'shadow'
                  }`}>
                  £
                </span>
              ))
            )}
          </div>

          {
            <div className="quick-view__variants">
              <div className="quick-view__variant-options">
                {productGroup?.options?.map((option) => {
                  return (
                    <>
                      <div className="quick-view__variant-options">
                        <p className="quick-view__variant-name">{option.option_name}</p>
                        <div className="quick-view__variant-wrapper">
                          {option?.available_options?.map((optionValue) => {
                            const active = activeProduct?.Options?.some(
                              (o) =>
                                o?.name === option?.option_name &&
                                o?.option_values?.includes(optionValue)
                            );

                            const restOptions = activeProduct?.Options?.filter((el) => {
                              return el?.name !== option?.option_name;
                            });

                            const available = productGroup?.selected_variants?.some(
                              (selectedVariant) => {
                                const optionVariant = [
                                  { name: option?.option_name, option_values: [optionValue] },
                                  ...restOptions,
                                ];
                                return deepCompareArrayOfObjects(
                                  selectedVariant,
                                  optionVariant,
                                  'name'
                                );
                              }
                            );
                            return (
                              <div
                                className={`quick-view__variant-option ${active && 'active'} ${
                                  !available && 'unavailable'
                                }`}
                                onClick={() => onVariantClick(option.option_name, optionValue)}>
                                {optionValue}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          }
          <div
            style={{
              display: 'flex',
              gap: '10px',
              paddingTop: '15px',
              paddingBottom: '40px',
              borderBottom: '1px solid #F2F2F2',
            }}>
            {activeProduct?.Sample && (
              <GenericButton onClick={() => null} label="Request sample" disabled />
            )}
            <GenericButton onClick={() => null} label="Message supplier" disabled />
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '40px',
              fontSize: '16px',
              borderBottom: '1px solid white',
            }}>
            <div
              style={{
                marginRight: '20px',
                cursor: 'pointer',
                borderBottom: tab === 'details' ? '1px solid black' : '',
              }}
              onClick={() => {
                setTab('details');
              }}>
              Details
            </div>
            <div
              onClick={() => {
                setTab('supplier');
              }}
              style={{
                marginRight: '20px',
                cursor: 'pointer',
                borderBottom: tab === 'supplier' ? '1px solid black' : '',
              }}>
              Supplier
            </div>
          </div>
          {tab === 'details' ? (
            <div className="specs-table" style={{ marginTop: '16px' }}>
              {[
                { Width: activeProduct['Width'] },
                { Height: activeProduct['Height'] },
                { Weight: activeProduct['Weight'] },
                { Unit: activeProduct['Unit'] },
                { Material: activeProduct['Material'] },
                { Finish: activeProduct['Finish'] },
                { Style: activeProduct['Styles']?.slice(0, 50) },
                { SKU: activeProduct['SKU'] },
              ].map((element, i) => {
                //if element value is empty, don't render it
                if (
                  !Object.values(element)?.[0] ||
                  Object.values(element)?.[0] === '' ||
                  Object.values(element)?.[0] == '0' ||
                  (Array.isArray(Object.values(element)?.[0]) &&
                    Object.values(element)?.[0]?.length === 0) ||
                  (Array.isArray(Object.values(element)?.[0]) &&
                    Object.values(element)?.[0]?.[0] === '')
                ) {
                  return null;
                }
                return (
                  <div key={i + element} style={{ display: 'flex', fontSize: '12px' }}>
                    <div
                      style={{
                        width: '100px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        borderBottom: '1px solid #e5e5e5',
                        borderRight: '1px solid #e5e5e5',
                      }}>
                      {Object.keys(element)}
                    </div>
                    <div
                      style={{
                        paddingLeft: '30px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        borderBottom: '1px solid #e5e5e5',
                        width: '100%',
                        overflow: 'hidden',
                      }}>
                      {Object.keys(element)?.[0] === 'Style'
                        ? element.Style.join(', ')
                        : Object.keys(element)?.[0] === 'Finish'
                        ? element.Finish.join(', ')
                        : Object.keys(element)?.[0] === 'Material'
                        ? element.Material.join(', ')
                        : Object.values(element)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <h3 style={{ fontSize: '24px' }}>{activeProduct['Supplier Name']}</h3>
              <p style={{ fontSize: '16px' }}>{supplierData.Blurb}</p>
            </>
          )}
          <div style={{ marginTop: '40px' }}>
            <div style={{ display: 'flex' }}>
              {sustainabilityArr?.length > 0 && (
                <div style={{ marginTop: '40px' }}>
                  <div>Sustainability</div>
                  <div style={{ display: 'flex' }}>
                    {sustainabilityArr &&
                      sustainabilityArr.map((el) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                            <div>
                              <img src={sustainabilityDict[el]} alt="Sustainability" />
                            </div>{' '}
                            <div style={{ fontSize: '10px' }}>{el}</div>{' '}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
          {activeProduct['Downloads'].length > 0 && (
            <div style={{ marginTop: '40px' }}>
              <div>Downloads</div>
              <div>
                {activeProduct['Downloads'].map((element, i) => {
                  return (
                    <div
                      style={{
                        marginRight: '40px',
                        marginTop: '20px',
                        fontSize: '12px',
                      }}
                      key={i}>
                      <a target="_blank" rel="noreferrer" style={{ color: 'black' }} href={element}>
                        {element}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: '40px' }}></div>
      </div>
    </>
  );
}
