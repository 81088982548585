import { useState } from 'react';
import './ImagePicker.css';

import { useUploadImage } from '../../queries/use_images';
import { useUpdateProduct } from '../../queries/use_products';
import ImagesUploader from '../images-uploader/ImagesUploader';
import FileUploader from '../file_uploader/FileUploader';

const ACCEPT_FILE_TYPES = {
  'image/*': [],
};

export default function ImagePicker({ activeProduct, modalMode, toggleModalMode, closeModal }) {
  const { updateProductData } = useUpdateProduct();
  const { uploadImage } = useUploadImage();
  const [fileList, setFileList] = useState([]);

  const handleFileSend = async (acceptedFiles) => {
    let ticker = 0;
    setFileList([]);

    for (let i = 0; i < acceptedFiles.length; i++) {
      const formData = new FormData();
      formData.append('image', acceptedFiles[i]);

      const imageResponse = await uploadImage(formData);
      setFileList((p) => p.concat(imageResponse.data));
      ticker = ticker + 1;
      ticker === acceptedFiles.length ? toggleModalMode('files') : toggleModalMode('upload');
    }
  };

  const handleUploadImages = async (images) => {
    try {
      activeProduct['Product Images'] = images;
      await updateProductData({
        product: {
          'Product Images': images,
        },
        id: activeProduct._id,
      });
    } catch (e) {
      console.log('ERROR WHILE UPDATING IMAGES', e);
    }
  };

  const handleCloseModal = async (images) => {
    await handleUploadImages(images);
    closeModal();
  };

  return (
    <div
      style={{
        position: 'fixed',
        left: '33%',
        top: '200px',
        width: '430px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding: '20px',
        zIndex: 400,
      }}>
      {modalMode === 'files' && (
        <ImagesUploader
          files={[...activeProduct['Product Images'], ...fileList]}
          onClose={handleCloseModal}
          onRemoveImage={handleUploadImages}
          toggleModalMode={toggleModalMode}
          onUploadMore={async (images) => {
            await handleUploadImages(images);
            toggleModalMode('upload');
          }}
        />
      )}

      {modalMode === 'upload' && (
        <FileUploader
          onDrop={handleFileSend}
          multiple
          title="Upload Images"
          onClose={closeModal}
          accept={ACCEPT_FILE_TYPES}
        />
      )}
    </div>
  );
}
