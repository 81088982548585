export const getCheckboxLabel = (checked) => (checked ? 'Yes' : 'No');

export const textAreaHandlers = (setExpanded) => {
  const onKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
      case ' ':
      default:
        setExpanded(true);
        return;
      case 'Escape':
        e.preventDefault();
        setExpanded(false);
        return;
    }
  };

  document.addEventListener('keydown', onKeyDown);
  return () => document.removeEventListener('keydown', onKeyDown);
};

export const setSelectionRange = (e) => {
  const current = e.target;
  const selectionRange = current.value.length;

  return setTimeout(() => {
    current.setSelectionRange(selectionRange, selectionRange);
  }, 0);
};

export const getProductCurrency = (currency = 'GBP') => {
  const CURRENCIES = {
    GBP: '£ GBP',
    EUR: '€ EUR',
    USD: '$ USD',
    AUD: 'A$ AUD',
    CHF: 'Fr. CHF',
  };

  return CURRENCIES[currency] ?? '£ GBP';
};

export const selectHandlers = (optionsLength, activeIndex, setActiveIndex, onSelect) => {
  const handleActiveIndexChange = (e, index) => {
    e.preventDefault();
    setActiveIndex(index);
  };

  const onKeyDown = (e) => {
    // eslint-disable-next-line default-case
    switch (e.key) {
      case 'Up':
      case 'ArrowUp':
        const newIndex = activeIndex <= 0 ? optionsLength - 1 : activeIndex - 1;
        handleActiveIndexChange(e, newIndex);
        return;
      case 'Down':
      case 'ArrowDown':
        const downIndex = activeIndex + 1 === optionsLength ? 0 : activeIndex + 1;
        handleActiveIndexChange(e, downIndex);
        return;
      case 'Enter':
      case ' ':
        e.preventDefault();
        onSelect();
        return;
      case 'PageUp':
      case 'Home':
        handleActiveIndexChange(e, 0);
        return;
      case 'PageDown':
      case 'End':
        handleActiveIndexChange(e, optionsLength - 1);
        return;
    }
  };

  document.addEventListener('keydown', onKeyDown);
  return () => {
    document.removeEventListener('keydown', onKeyDown);
  };
};

export function throttle(callback, duration) {
  let lastArgs;

  const run = () => {
    if (lastArgs) {
      callback(lastArgs);
      lastArgs = undefined;
    }
  };

  const throttled = (args) => {
    const processing = !!lastArgs;

    lastArgs = args;

    if (processing) {
      return;
    }

    window.setTimeout(run, duration);
  };

  return throttled;
}
