import { useEffect, useState } from 'react';
import ClassicDropdown from './ClassicDropdown';
import { useClickOutside } from '../../hooks/useClickOutside';

export default function ButtonTopMenu({
  label,
  icon,
  danger,
  setOpenDropdown,
  tooltip,
  items,
  classic,
  backgroundColor,
  color,
  onMouseOver,
  itemsToAdd,
  setItemsToAdd,
  filtersVisible,
  setSaveProjectItemsVisible,
  onClick,
}) {
  const [clicked, setClicked] = useState(false);

  const { ref } = useClickOutside(() => {
    setClicked(false);
  });

  const [addName, setAddName] = useState('');
  return (
    <div className={`button-filter__wrapper ${addName ? 'active' : ''}`} onClick={onClick}>
      <div
        className={`button-filter ${danger ? 'button-filter--danger' : ''} ${
          clicked ? 'active ' : ''
        }`}
        onClick={() => {
          setClicked((p) => !p);
          // setOpenDropdown(p => !p)
        }}>
        <img src={icon} alt="Icon" />
        {label && <p>{label}</p>}
        {addName && <p>{addName}</p>}
      </div>
      {tooltip && !clicked && <div className="button-filter__tooltip">{tooltip}</div>}
    </div>
  );
}
