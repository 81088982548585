import { AVAILABLE_COLUMNS, getParsedCondition, PREFIX, PARSED_PREFIX } from './mocks';
import { BaseSelect } from '../../base_select/BaseSelect';
import CloseHandle from '../imgs/Close.svg';
import { useStyle } from '../../../queries/use_style';
import { useCategories } from '../../../queries/use_categories';
import { useStatus } from '../../../queries/use_status';
import { useColour } from '../../../queries/use_colour';
import { usePriceGuide } from '../../../queries/use_price_guide';
import { useLead } from '../../../queries/use_lead';
import { useSustainability } from '../../../queries/use_sustainability';
import { useUnits } from '../../../queries/use_units';
import { useLocation } from '../../../queries/use_location';
import { useFinish } from '../../../queries/use_finish';
import { useMaterial } from '../../../queries/use_material';

export const FilterRow = ({
  field,
  index,
  changeFieldType,
  setFilterType,
  setFieldValue,
  changePrefix,
  removeFilter,
  hideSuppliers,
}) => {
  const isFirst = index === 0;
  const { data: Categories = [] } = useCategories();
  const { data: Styles = [] } = useStyle();
  const { data: Status = [] } = useStatus();
  const { data: Colour = [] } = useColour();
  const { data: Finish = [] } = useFinish();
  const { data: priceGuide = [] } = usePriceGuide();
  const { data: leadOptions = [] } = useLead();
  const { data: Location = [] } = useLocation();
  const { data: Unit = [] } = useUnits();
  const { data: Material = [] } = useMaterial();
  const { data: Sustainability = [] } = useSustainability();

  const availableOptions = {
    Categories,
    Styles,
    Status,
    Colour,
    'Price Guide': priceGuide,
    Finish,
    'Lead Time': leadOptions,
    Location,
    Unit,
    Material,
    Sustainability,
    Suitability: [
      { label: 'Indoor', id: 'Indoor' },
      { name: 'Outdoor', id: 'Outdoor' },
    ],
    Currency: [
      {
        name: 'GBP',
        id: 'GBP',
      },
      {
        name: 'USD',
        id: 'USD',
      },
      {
        name: 'EUR',
        id: 'EUR',
      },
      {
        name: 'AUD',
        id: 'AUD',
      },
    ],
  };

  const fieldType = AVAILABLE_COLUMNS[field?.name]?.fieldType;
  const fieldOptions = AVAILABLE_COLUMNS[field?.name]?.conditions;

  const multiselect =
    ['$nin', '$in'].includes(field?.byCondition) && !['Categories', 'Status'].includes(field?.name);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        fontSize: '13px',
        lineHeight: '8px',
        alignItems: 'center',
      }}>
      <div
        style={{
          width: '80px',
        }}>
        {isFirst ? (
          <span>Where</span>
        ) : (
          <BaseSelect
            id="prefix"
            onChange={(val) => {
              changePrefix(val.id);
            }}
            defaultValue={{ label: PARSED_PREFIX[field.prefix], id: field.prefix }}
            options={PREFIX}
          />
        )}
      </div>

      <div
        style={{
          border: '1px solid #f9f9f9',
          borderRadius: '3px',
          display: 'flex',
        }}>
        <div style={{ width: '120px' }}>
          <BaseSelect
            id="fieldType"
            defaultValue={{ label: field.name, id: field.name }}
            onChange={(value) => changeFieldType(value.id)}
            searchable
            options={Object.keys(AVAILABLE_COLUMNS).map((column) => ({
              label: column,
              id: column,
            }))}
          />
        </div>
        <div style={{ width: '140px' }}>
          <BaseSelect
            id="filterType"
            value={{
              label: getParsedCondition(field?.name, field?.byCondition, field?.value),
              id: field?.byCondition,
            }}
            onChange={(value) => {
              if ((value.id === '$nin' || value.id === '$in') && field.byCondition !== value.id) {
                setFieldValue([]);
              }
              if (value.id !== '$nin' && value.id !== '$in') {
                setFieldValue('');
              }
              if (fieldType === 'bool') {
                setFieldValue(true);
              }
              if (value.id === '$exists') {
                setFieldValue(value.value);
              }
              if (fieldType) {
                setFilterType(value.id, AVAILABLE_COLUMNS[field.name].fieldType);
              }
            }}
            options={fieldOptions?.map((condition) => ({
              label: condition.name,
              id: condition.id,
              value: condition?.value,
            }))}
          />
        </div>
        <div style={{ width: '140px' }}>
          {fieldType === 'select' && field?.byCondition !== '$exists' && (
            <BaseSelect
              searchable
              disabled={!Boolean(field?.byCondition)}
              closeMenuOnSelect={!multiselect}
              id="condition_select"
              multiselect={multiselect}
              options={availableOptions[field?.name]?.map((option) => ({
                label: option.name,
                id: option._id,
                value: option.name,
              }))}
              value={
                multiselect
                  ? field?.value?.map((v) => ({ label: v, id: v, value: v }))
                  : { label: field?.value, id: field?.value, value: field?.value }
              }
              onChange={(value) => {
                if (multiselect) {
                  const withoutDuplicates = [...new Set(value.map((v) => v.label))];
                  return setFieldValue(withoutDuplicates);
                }
                setFieldValue(value.label);
              }}
            />
          )}

          {(fieldType === 'text' || fieldType === 'number') && (
            <input
              type="text"
              name="fieldValue"
              style={{
                width: '100%',
                height: '100%',
                outline: 'none',
                border: 'none',
                fontSize: '12px',
                padding: '5px',
              }}
              defaultValue={field?.byCondition === '$exists' ? '' : field?.value}
              disabled={!Boolean(field?.byCondition) || field?.byCondition === '$exists'}
              onChange={(e) => setFieldValue(e.target.value)}
            />
          )}
        </div>
      </div>

      <img
        onClick={removeFilter}
        src={CloseHandle}
        role="button"
        alt="remove filter"
        className="close-handle"
      />
    </div>
  );
};
