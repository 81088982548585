import { useState } from 'react';

import Cell from './Cell';
import PlusIcon from '../imgs/Plus.svg';
import TagGroup from '../../tag_group/TagGroup';
import Tooltip from '../../tooltip/Tooltip';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useVariants } from '../../../queries/use_variants';
import { useActionsContext } from '../../../context/actions.context';

import FuseSearch from './FuseSearch';
import { type } from '@testing-library/user-event/dist/type';

export const VariantCell = ({
  value,
  groupId,
  minOptionLength,
  onVariantChange,
  clearValue,
  product,
  productId,
  rowIndex,
  cellIndex,
  ...cellProps
}) => {
  const { copyValue, pasteValue } = useActionsContext();
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);

  const { ref } = useClickOutside(() => {
    setExpanded(false);
    setFocused(false);
  });

  const {
    data: variants = {
      combinedOptions: [],
      selected: [],
      availableOptions: [],
    },
  } = useVariants(groupId, Boolean(minOptionLength));

  const baseStyle = expanded ? 'expandable_cell--focused' : '';
  const styleOverrides = expanded ? { position: 'relative', outline: 'none' } : {};

  const currentValue = Array.isArray(value)
    ? value?.map((el) => {
        if (!el?.option_values) return null;
        if (typeof el?.option_values === 'string') return el?.option_values;
        return el?.option_values[0];
      })
    : null;
  const hasTags =
    Boolean(currentValue) &&
    currentValue?.length > 0 &&
    currentValue.every((item) => Boolean(item));

  const showWarning = hasTags && currentValue?.length !== minOptionLength;

  return (
    <Cell
      rowIndex={rowIndex}
      cellIndex={cellIndex}
      {...cellProps}
      draggable={false}
      fieldName={'Options'}
      id={productId}
      onClick={(e) => {
        setFocused(true);
      }}
      onDoubleClick={(e) => {
        setExpanded(true);
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Escape' && expanded) {
          return setExpanded(false);
        }

        if (e.key === 'Enter') {
          return setExpanded(!expanded);
        }

        if (e.key === 'Backspace' && !expanded && hasTags) {
          return clearValue();
        }

        if (e.key === 'Tab' && expanded) {
          setExpanded(false);
          setFocused(false);
        }

        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'c') {
            return copyValue({ data: { Options: value } });
          }
          if (e.key === 'v') {
            await pasteValue({
              productId,
              previousData: { Options: value },
              product,
              fieldName: 'Options',
            });
            return;
          }

          setExpanded(false);
        }
      }}
      styleOverrides={{ ...styleOverrides, cursor: 'pointer' }}>
      <div ref={ref} className={baseStyle}>
        {expanded ? (
          <FuseSearch
            selected={[]}
            options={variants.availableOptions}
            useCombined
            fuseConfig={{
              threshold: 0.8,
              matchAllOnEmptyQuery: true,
              keys: [{ name: 'name' }, { name: 'tags' }],
            }}
            onOptionSelect={(option) => {
              onVariantChange(option.data);
              setExpanded(false);
            }}
            creatable={false}
          />
        ) : hasTags ? (
          <div
            style={{
              maxWidth: '200px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
            className="no-scrollbar">
            <TagGroup
              tags={currentValue}
              onTagRemove={() => {}}
              showCloseHandles={focused}
              backspaceRemoves={false}
              variant="secondary"
            />
            {showWarning && (
              <Tooltip content="Your current variant option has incorrect properties" id="test" />
            )}
          </div>
        ) : (
          <img
            src={PlusIcon}
            style={{
              cursor: 'pointer',
            }}
            alt="Add"
            role="button"
            onClick={() => setExpanded(true)}
          />
        )}
      </div>
    </Cell>
  );
};
