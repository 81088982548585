import PdfIcon from './imgs/pdf-icon.svg';
import DownloadIcon from './imgs/download-icon.svg';
import { useUser } from '../../api/auth';

export default function Message({ msg, idx, margin }) {
  const { userData } = useUser();
  const convertToToday = (date) => {
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toLocaleDateString() === today.toLocaleDateString()) {
      return 'Today';
    } else if (date.toLocaleDateString() === yesterday.toLocaleDateString()) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString([], {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
      });
    }
  };

  return (
    <div className={`message__wrapper ${msg.from === userData.SupplierId ? 'sender' : ''}`}>
      {margin && (
        <div className={`message__time ${margin == 'narrow' ? 'narrow' : ''} `}>
          {convertToToday(new Date(msg.createdAt))}
        </div>
      )}

      <div className="message__bubble">
        {msg.content}
        <div className="message__time">
          {new Date(msg.createdAt).toLocaleTimeString([], {
            hour: 'numeric',
            minute: '2-digit',
            hour12: 'false',
          })}
        </div>
      </div>
    </div>
  );
}
