import { useState, useEffect } from 'react';
import Row from './Row';
import TableHeaderRow from './TableHeaderRow';
import ImagePicker from '../image_picker/ImagePicker';
import DocumentPicker from '../document_picker/DocumentPicker';
import Spinner from '../spinner/Spinner';
import { useCategories } from '../../queries/use_categories';
import { usePriceGuide } from '../../queries/use_price_guide';
import { useLead } from '../../queries/use_lead';
import { useSustainability } from '../../queries/use_sustainability';
import { useUnits } from '../../queries/use_units';
import { useLocation } from '../../queries/use_location';
import { useFinish } from '../../queries/use_finish';
import { useColour } from '../../queries/use_colour';
import { useStyle } from '../../queries/use_style';
import { useStatus } from '../../queries/use_status';
import { useSuppliers } from '../../queries/use_suppliers';
import { useMaterial } from '../../queries/use_material';
import { useUser } from '../../api/auth';
import BottomBar from '../bottom_bar/BottomBar';
import { useProductsContext } from '../../context/product.context';
import { useRemoveProducts } from '../../queries/use_products';
import { useActionsContext } from '../../context/actions.context';
import ContextMenu from './parts/ContextMenu';

import TopBarMenu from '../top_bar_menu/TopBarMenu';

import GroupModal from '../group_modal/GroupModal';
import EmptyState from './EmptyState';
import { getMockRowData } from './mocks';
import { useCreateProduct } from '../../queries/use_products';
import { useNotifications } from '../notifications/useNotifications';
import { TableNavigationWrapper } from '../../context/table_navigation.context';
import { useUserSupplier } from '../../queries/use_user_supplier';

export default function Portable({
  products,
  setProducts,
  menuNewVisible,
  activeProduct,
  setQuickViewVisible,
  setActiveProduct,
  setStatusSaved,
  statusSaved,
}) {
  const { notify } = useNotifications();
  const [showNewRowId, setNewRowId] = useState(null);
  const { userData, isAdmin, hasUser } = useUser();
  const { undoAction } = useActionsContext();
  const {
    rowsSelected,
    setRowsSelected,
    page,
    itemsPerPage,
    allProducts,
    isReady,
    clearFilters,
    sortByNewest,
  } = useProductsContext();
  const { data: activeSupplier } = useUserSupplier({});

  const { removeProducts } = useRemoveProducts();
  const { createProduct } = useCreateProduct();
  const { data: categories = [], isLoading: categoriesLoading } = useCategories();
  const { data: priceGuide = [], isLoading: priceGuideLoading } = usePriceGuide();
  const { data: leadOptions = [], isLoading: leadOptionLoading } = useLead();
  const { data: location = [], isLoading: locationsLoading } = useLocation();
  const { data: units = [], isLoading: unitsLoading } = useUnits();
  const { data: colour = [], isLoading: colourLoading } = useColour();
  const { data: material = [], isLoading: materialLoading } = useMaterial();
  const { data: finish = [], isLoading: finishLoading } = useFinish();
  const { data: style = [], isLoading: styleLoading } = useStyle();
  const { data: suppliers = [], isLoading: suppliersLoading } = useSuppliers(isAdmin);
  const { data: sustainability = [], isLoading: sustainabilityLoading } = useSustainability();
  const { data: status = [], isLoading: statusLoading } = useStatus();

  const isTableReady =
    isReady &&
    !categoriesLoading &&
    !priceGuideLoading &&
    !leadOptionLoading &&
    !locationsLoading &&
    !unitsLoading &&
    !colourLoading &&
    !materialLoading &&
    !finishLoading &&
    !styleLoading &&
    !(suppliersLoading && isAdmin) &&
    !sustainabilityLoading &&
    !statusLoading;

  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const [rowToDelete, setRowToDelete] = useState(null);
  const [modalOpen, setModalOpen] = useState(null);
  const [modalMode, setModalMode] = useState('upload');

  const handleSelectAll = () => setRowsSelected(allProducts.map((p) => p._id));
  const closeModal = () => {
    setModalOpen(null);
    setModalMode('upload');
  };

  const productsAvailable = isTableReady && allProducts && Boolean(allProducts.length);

  useEffect(() => {
    if (productsAvailable) {
      const undoActionHandlers = (e) => {
        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'z') {
            undoAction();
          }
        }
      };

      document.addEventListener('keydown', undoActionHandlers);
      return () => document.removeEventListener('keydown', undoActionHandlers);
    }
  }, [productsAvailable, undoAction]);

  const toggleNewRow = async () => {
    try {
      const res = await createProduct(
        getMockRowData(userData?.SupplierId, activeSupplier?.['Company Name'])
      );
      setNewRowId(res.data._id);
      sortByNewest();
    } catch (e) {
      console.error('error creating product', e);
      notify('Could not create product. Try again later', 'error');
    }
  };

  const showSuppliers = isAdmin && hasUser;

  return (
    <TableNavigationWrapper maxRows={allProducts.length} maxCols={showSuppliers ? 37 : 36}>
      <div
        style={{
          width: '100%',
          height: '100%',
          overflow: productsAvailable ? 'scroll' : 'hidden',
          backgroundColor: '#FCFCFF',
          paddingBottom: '300px',
          // marginTop: '61px',
        }}>
        <TopBarMenu
          products={allProducts ?? []}
          rowsSelected={rowsSelected}
          menuNewVisible={menuNewVisible}
          setRowsSelected={setRowsSelected}
        />

        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '61px',
          }}>
          <TableHeaderRow
            selectedRows={rowsSelected}
            selectAllRows={handleSelectAll}
            deselectAllRows={() => setRowsSelected([])}
            itemsPerPage={itemsPerPage}
          />
          <tbody>
            {!isTableReady && (
              <tr className="empty_state" style={{ top: '15%' }}>
                <td>
                  <Spinner label="Loading your products..." />
                </td>
              </tr>
            )}
            {isReady && allProducts?.length === 0 && <EmptyState onClearFilters={clearFilters} />}

            {productsAvailable && (
              <>
                {allProducts?.map((product, i) => (
                  <Row
                    setRowToDelete={setRowToDelete}
                    productId={product._id}
                    key={product._id}
                    index={i}
                    product={product}
                    itemIndex={i + 1 + Number(itemsPerPage) * Number(page)}
                    showSuppliers={isAdmin && hasUser}
                    categories={categories}
                    products={products}
                    setProducts={setProducts}
                    activeProduct={activeProduct}
                    setActiveProduct={setActiveProduct}
                    setStatusSaved={setStatusSaved}
                    setQuickViewVisible={setQuickViewVisible}
                    rowsSelected={rowsSelected}
                    setRowsSelected={setRowsSelected}
                    openModal={setModalOpen}
                    toggleModalMode={setModalMode}
                    priceGuide={priceGuide}
                    sustainability={sustainability}
                    leadOptions={leadOptions}
                    modalOpen={modalOpen}
                    units={units}
                    status={status}
                    colour={colour}
                    material={material}
                    finish={finish}
                    style={style}
                    suppliers={suppliers}
                    setMenuPosition={setMenuPosition}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    location={location}
                    autoFocusFirstField={showNewRowId === product._id}
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
        <BottomBar
          toggleNewRow={toggleNewRow}
          statusSaved={statusSaved}
          setStatusSaved={setStatusSaved}
        />
        {/* document download */}
        {modalOpen === 'document-upload' && (
          <DocumentPicker
            activeProduct={activeProduct}
            fieldName={'Downloads'}
            products={products}
            setProducts={setProducts}
            closeModal={closeModal}
            modalMode={modalMode}
            toggleModalMode={setModalMode}
          />
        )}
        {/* document download */}
        {modalOpen === 'drawings-upload' && (
          <DocumentPicker
            activeProduct={activeProduct}
            fieldName={'Technical Drawings or PDFs'}
            products={products}
            setProducts={setProducts}
            closeModal={closeModal}
            modalMode={modalMode}
            toggleModalMode={setModalMode}
          />
        )}
        {/* image download */}
        {modalOpen === 'image-upload' && (
          <ImagePicker
            activeProduct={activeProduct}
            products={products}
            setProducts={setProducts}
            closeModal={closeModal}
            modalMode={modalMode}
            toggleModalMode={setModalMode}
          />
        )}
        {modalOpen === 'manage-options' && (
          <GroupModal
            key={`group-modal-${activeProduct['GroupId']._id}-${modalOpen}`}
            groupName={activeProduct['GroupId']?.name}
            groupId={activeProduct['GroupId']._id}
            closeModal={closeModal}
          />
        )}
        <ContextMenu
          setQuickViewVisible={setQuickViewVisible}
          {...menuPosition}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          onClick={() => {
            removeProducts(rowToDelete);
          }}
        />
      </div>
    </TableNavigationWrapper>
  );
}
