import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const SUSTAINABILITY_QUERY_KEY = 'SUSTAINABILITY_QUERY';

const getSustainability = () => authApiClient.get('/tag/sustainability').then((res) => res.data);

export const useSustainability = () => {
  const { data = [], isLoading } = useQuery([SUSTAINABILITY_QUERY_KEY], getSustainability, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
