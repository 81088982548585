import './App.css';
import { useState, useEffect, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from './api/auth';

import './assets/style/helper.scss';
import './assets/style/profile.scss';

import { useProductsContext } from './context/product.context';
import Login from './components/login/Login';
import Portable from './components/portable/Portable';
import QuickView from './components/quick_view/QuickViev.js';
import ProfileDescription from './components/account/ProfileDescription';
import ProfileImageGallery from './components/account/ProfileImageGallery';
import { getProductsData, ALL_PRODUCTS_QUERY } from './queries/use_products';
import Layout from './components/layout/Layout';
import Settings from './components/settings/Settings';
import Messaging from './components/messaging/Messaging';
import { Notification } from './components/notifications/Notification';
import { ProvideRoutesContext } from './context/routes.context';
import SupplierPage from './components/suppliers/SupplierPage';
import Dashboard from './components/dashboard/Dashboard';

function App() {
  const queryClient = useQueryClient();
  const { allProducts, page, isPreviousData, meta, itemsPerPage, searchText, sort, rawFilters } =
    useProductsContext();
  const { hasUser, isLoading, userData } = useUser();
  const [statusSaved, setStatusSaved] = useState('Saved');
  const {
    menuNewVisible,
    setMenuNewVisible,
    quickViewVisible,
    setQuickViewVisible,
    activeProduct,
    setActiveProduct,
    supplierPageVisible,
    profileVisible,
    dashboardVisible,
    inventoryVisible,
    settingsVisible,
    messagesVisible,
    profileImageGalleryVisible,
    onNavigate,
    activeTab,
    activeRoute,
  } = useContext(ProvideRoutesContext);

  useEffect(() => {
    // PREFETCH NEXT PRODUCTS PAGE
    if (!isPreviousData && meta?.nextPage !== meta?.currentPage) {
      queryClient.prefetchQuery({
        queryKey: [ALL_PRODUCTS_QUERY, searchText, itemsPerPage, page + 1, rawFilters, sort],
        queryFn: () =>
          getProductsData({
            itemsPerPage,
            page: page + 1,
            searchText,
            filters: rawFilters,
            sort,
          }),
      });
    }
    // PREFETCH PREV PRODUCTS PAGE IF AVAILABLE

    if (!isPreviousData && meta?.previousPage !== meta?.currentPage) {
      queryClient.prefetchQuery({
        queryKey: [ALL_PRODUCTS_QUERY, searchText, itemsPerPage, page - 1, rawFilters, sort],
        queryFn: () =>
          getProductsData({
            itemsPerPage,
            page: page - 1,
            searchText,
            filters: rawFilters,
            sort,
          }),
      });
    }
  }, [meta, isPreviousData, page, queryClient, itemsPerPage, searchText, rawFilters, sort]);

  return (
    <>
      {!hasUser && !isLoading && <Login />}

      {hasUser && allProducts?.length > 0 && quickViewVisible && (
        <QuickView
          activeProduct={activeProduct}
          setActiveProduct={setActiveProduct}
          setQuickViewVisible={setQuickViewVisible}
        />
      )}
      {hasUser && (
        <Layout
          settingsVisible={settingsVisible}
          accountSubVisible={profileVisible || profileImageGalleryVisible}
          handleActiveTabChange={onNavigate}
          activeTab={activeTab}
          setMenuNewVisible={setMenuNewVisible}
          menuNewVisible={menuNewVisible}
          activeRoute={activeRoute}>
          {/* Everything between Layout component has the potential to be inside Sidebar, Topbar and Footer */}

          {(inventoryVisible || dashboardVisible) && (
            <Portable
              products={allProducts}
              setQuickViewVisible={setQuickViewVisible}
              menuNewVisible={menuNewVisible}
              activeProduct={activeProduct}
              setActiveProduct={setActiveProduct}
              setStatusSaved={setStatusSaved}
              page={page}
              statusSaved={statusSaved}
            />
          )}

          {(profileImageGalleryVisible || profileVisible) && (
            <div className="profile__wrapper">
              {profileImageGalleryVisible && userData && <ProfileImageGallery />}
              {profileVisible && userData && <ProfileDescription />}
            </div>
          )}

          {settingsVisible && <Settings />}
          {messagesVisible && <Messaging />}
          {supplierPageVisible && (
            <SupplierPage
              products={allProducts}
              setQuickViewVisible={setQuickViewVisible}
              setActiveProduct={setActiveProduct}
            />
          )}
        </Layout>
      )}
      <Notification />
    </>
  );
}

export default App;
