import { useState } from 'react';

import Cell from './Cell';
import PlusIcon from '../imgs/Plus.svg';
import TagGroup from '../../tag_group/TagGroup';
import { useClickOutside } from '../../../hooks/useClickOutside';
import FuseSearch from './FuseSearch';
import { useActionsContext } from '../../../context/actions.context';
import { useTableNavigationContext } from '../../../context/table_navigation.context';

export default function SelectCell({
  value,
  defaultValue,
  onChange,
  onBlur,
  clearValue,
  onSingleTagRemove,
  type = 'text',
  multiselect = false,
  options,
  creatable = false,
  backspaceRemoves = true,
  showCloseHandles = true,
  showCloseOnHover = true,
  useCombined = false,
  onCreateOption,
  fieldName,
  productId,
  product,
  index,
  rowIndex,
  columnIndex,
  ...cellProps
}) {
  const values =
    value && Boolean(value?.length) ? [value].flat().filter((el) => Boolean(el)) : [null];
  const [localValues, setLocalValues] = useState(values);
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const { copyValue, pasteValue } = useActionsContext();
  const { ref } = useClickOutside(() => {
    setExpanded(false);
    setFocused(false);
  });
  const { setEditingLocked } = useTableNavigationContext();
  const hasTags = values?.filter((el) => Boolean(el)).length > 0;
  const baseStyle = expanded ? 'expandable_cell--focused' : '';
  const styleOverrides = expanded ? { position: 'relative', outline: 'none' } : {};

  const handleMultiOptionChange = async (option) => {
    if (localValues.includes(option.name)) {
      const newValues = localValues.filter((v) => v !== option.name);
      setLocalValues(newValues);
      await onChange(newValues);
    } else {
      const newValues = [...localValues, option.name];
      setLocalValues(newValues);
      await onChange(newValues);
    }
    setExpanded(multiselect);
  };

  const handleSingleOptionChange = (option) => {
    onChange(option);
    setExpanded(false);
  };

  return (
    <Cell
      fieldName={fieldName}
      defaultValue={multiselect ? values : values[0]}
      id={index}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      {...cellProps}
      onClick={(e) => {
        setFocused(true);
      }}
      onDoubleClick={(e) => {
        setExpanded(true);
      }}
      onCopy={(e) => {
        e.stopPropagation();
      }}
      onPaste={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Escape' && expanded) {
          return setExpanded(false);
        }

        if (e.key === 'Backspace' && !expanded && hasTags) {
          return clearValue();
        }

        if (e.key === 'Enter' && !multiselect) {
          return setExpanded(!expanded);
        }

        if (e.key === 'Enter' && multiselect) {
          setEditingLocked(true);
          return setExpanded(true);
        }

        if (e.key === 'Tab' && expanded) {
          setExpanded(false);
        }

        if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
          if (e.key === 'c') {
            return copyValue({ data: { [fieldName]: multiselect ? values : values[0] } });
          }
          if (e.key === 'v') {
            await pasteValue({
              productId,
              previousData: { [fieldName]: multiselect ? values : values[0] },
              product,
              fieldName,
            });
            return;
          }

          setExpanded(false);
        }
      }}
      styleOverrides={{ ...styleOverrides, cursor: 'pointer', maxWidth: '150px' }}>
      <div ref={ref} className={baseStyle}>
        {expanded && (
          <FuseSearch
            open={expanded}
            selected={multiselect ? localValues : values}
            options={options}
            useCombined={useCombined}
            onOptionSelect={multiselect ? handleMultiOptionChange : handleSingleOptionChange}
            creatable={creatable}
            onCreateOption={onCreateOption}
          />
        )}
        {!expanded && hasTags && (
          <div style={{ overflow: 'auto', scrollbarWidth: 'none' }} className="no-scrollbar">
            <TagGroup
              tags={values}
              onTagRemove={onSingleTagRemove}
              showCloseHandles={focused && showCloseHandles}
              backspaceRemoves={backspaceRemoves}
            />
          </div>
        )}
        {!expanded && !hasTags && (
          <img
            src={PlusIcon}
            style={{
              cursor: 'pointer',
            }}
            alt="Add"
            role="button"
            onClick={() => setExpanded(true)}
          />
        )}
      </div>
    </Cell>
  );
}
