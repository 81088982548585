import Checkbox from '../checkbox/Checkbox';
import { useUser } from '../../api/auth';

export default function TableHeaderRow({
  selectAllRows,
  deselectAllRows,
  selectedRows,
  itemsPerPage,
}) {
  const { isAdmin } = useUser();

  const hasSomeSelected = selectedRows && selectedRows?.length > 0;
  const hasEverySelected = selectedRows?.length === itemsPerPage;

  const handleSelectAll = () => (hasSomeSelected ? deselectAllRows() : selectAllRows());

  const column_names = [
    'Product',
    isAdmin && 'Supplier',
    'Status',
    'Exclusive?',
    'Product Group',
    'Variant',
    'Description',
    'Gallery',
    'Technical  Drawings',
    'Downloads',
    'Category',
    'Style',
    'Colour',
    'Finish',
    'Material',
    'Customisable?',
    'Sample?',
    'Width',
    'Height',
    'Length',
    'Depth',
    'Diameter',
    'Weight',
    'Sustainability',
    'Suitability',
    'Lead time',
    'Location',
    'Unit',
    'Currency',
    'Price Guide',
    'Retail Price (£)',
    'Trade Price (£)',
    'Trade Discount %',
    'ex VAT',
    'Product URL',
    'Meta description',
    'Image alt text',
  ].filter(Boolean);
  return (
    <thead style={{ position: 'sticky', top: '61px', zIndex: 300 }}>
      <tr>
        <th
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1px solid #f2f2f2',
            textAlign: 'left',
            fontSize: '12px',
            padding: '8px 5px 8px 5px',
            position: 'sticky',
            zIndex: 300,
            left: 0,
          }}
          className="table-header-row">
          <Checkbox
            variant="secondary"
            type={hasEverySelected ? 'checkbox' : 'intermediate'}
            checked={hasSomeSelected}
            onChange={handleSelectAll}
          />
        </th>

        {column_names.map((name) => {
          return (
            <th
              key={name}
              style={{
                backgroundColor: '#f9f9f9',
                borderBottom: '1px solid #f2f2f2',
                // borderRight: "1px solid #f2f2f2",
                textAlign: 'left',
                fontSize: '12px',
                padding: '8px 5px 8px 5px',
                position: name === 'Product' && 'sticky',
                left: '31.5px',
                zIndex: 300,
              }}
              className={`${name === 'Product' ? 'table-header-row' : ''}`}>
              <div
                style={{
                  resize: 'horizontal',
                  overflow: 'auto',
                  minWidth: '150px',
                }}>
                {name}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
