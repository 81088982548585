import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const SUPPLIERS_QUERY_KEY = 'SUPPLIERS_QUERY';

const getSuppliers = () => authApiClient.get('/suppliers').then((res) => res.data.data);

export const useSuppliers = (enabled) => {
  const { data, isLoading } = useQuery([SUPPLIERS_QUERY_KEY], getSuppliers, {
    refetchOnWindowFocus: false,
    enabled,
    select: (data) =>
      data.map((supplier) => ({ _id: supplier._id, name: supplier['Company Name'] })),
  });
  return { data, isLoading };
};
