import NoResults from './imgs/no_results.svg';

export default function EmptyState({ onClearFilters }) {
  return (
    <div
      className="empty_state"
      style={{
        gap: '18px',
        color: '#8997ba',
        fontSize: '12px',
        top: '30%',
      }}>
      <img
        src={NoResults}
        alt="No results"
        style={{
          height: '60px',
        }}
      />
      <p>No products found</p>
      <button onClick={onClearFilters} className="clear_filters_btn">
        Clear filters
      </button>
    </div>
  );
}
