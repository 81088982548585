import { useState } from 'react';
import FileUploader from '../file_uploader/FileUploader';
import FilesUploader from '../files_uploader/FilesUploader';
import { useUploadImage } from '../../queries/use_images';
import { useUpdateProduct } from '../../queries/use_products';

const ACCEPT_FILE_TYPES = {
  Downloads: {},
  'Technical Drawings or PDFs': {
    'image/*': [],
  },
};

export default function DocumentPicker({
  activeProduct,
  closeModal,
  modalMode,
  toggleModalMode,
  fieldName,
}) {
  const { updateProductData } = useUpdateProduct();
  const { uploadImage: uploadFile } = useUploadImage();
  const [fileList, setFileList] = useState([]);

  const handleFileSend = async (acceptedFiles) => {
    let ticker = 0;
    setFileList([]);

    for (let i = 0; i < acceptedFiles.length; i++) {
      const formData = new FormData();
      formData.append('image', acceptedFiles[i]);

      const imageResponse = await uploadFile(formData);
      setFileList((p) => p.concat(imageResponse.data));
      ticker = ticker + 1;
      ticker === acceptedFiles.length ? toggleModalMode('files') : toggleModalMode('upload');
    }
  };

  const handleUploadFiles = async (files) => {
    try {
      await updateProductData({
        product: {
          [fieldName]: files,
        },
        id: activeProduct._id,
      });
      activeProduct[fieldName] = files;
    } catch (e) {
      console.log('ERROR WHILE UPDATING FILES', e);
    }
  };

  const handleCloseModal = async (files) => {
    await handleUploadFiles(files);
    closeModal();
  };

  return (
    <div
      style={{
        position: 'fixed',
        left: '300px',
        top: '200px',
        width: '430px',
        overflowY: 'scroll',
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding: '20px',
        zIndex: 400,
      }}>
      {modalMode === 'files' && (
        <FilesUploader
          files={[...activeProduct[fieldName], ...fileList]}
          onClose={handleCloseModal}
          onRemoveFile={handleUploadFiles}
          toggleModalMode={toggleModalMode}
          onUploadMore={async (images) => {
            await handleUploadFiles(images);
            toggleModalMode('upload');
          }}
        />
      )}

      {modalMode === 'upload' && (
        <FileUploader
          onDrop={handleFileSend}
          multiple
          title="Upload Files"
          onClose={closeModal}
          accept={ACCEPT_FILE_TYPES[fieldName]}
        />
      )}
    </div>
  );
}
