import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import { AUTH_COOKIE_KEY, FILTERING_KEY } from '../consts/local-storage';

// wrappers for sfcookies so they can be easily replaced with some other libraries in the future

const get = (key) => read_cookie(key);
const set = (key, value) => bake_cookie(key, value);
const remove = (key) => delete_cookie(key);

const accessToken = get(AUTH_COOKIE_KEY);
const setAccessToken = (token) => set(AUTH_COOKIE_KEY, token);
const removeAccessToken = () => remove(AUTH_COOKIE_KEY);

const filteringToken = get(FILTERING_KEY);
const setFilteringToken = (token) => set(FILTERING_KEY, token);
const removeFilteringToken = () => remove(FILTERING_KEY);

export const cookies = {
  accessToken,
  setAccessToken,
  removeAccessToken,
  filteringToken,
  setFilteringToken,
  removeFilteringToken,
};
