import './BottomBar.scss';
import BigPlus from './imgs/bigplus.svg';
import SaveStatus from '../save_status/SaveStatus';
import Pagination from './parts/Pagination';
import { useProductsContext } from '../../context/product.context';

export default function BottomBar({ statusSaved, setStatusSaved, toggleNewRow }) {
  const { total } = useProductsContext();

  return (
    <div className="table__footer" key={total}>
      <div>
        <div
          style={{ position: 'fixed', bottom: '14px', cursor: 'pointer' }}
          role="button"
          onClick={toggleNewRow}>
          <img src={BigPlus} alt="Add icon" />
        </div>

        <div
          style={{
            marginLeft: '42px',
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div>{(total ?? 0).toLocaleString()} products</div>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <SaveStatus statusSaved={statusSaved} setStatusSaved={setStatusSaved} />
        <Pagination />
      </div>
    </div>
  );
}
