import './Checkbox.css';
import { forwardRef } from 'react';

/**
 *
 * @param {*} variant - "primary" | "secondary" | "tertiary"
 * @param {*} type - "checkbox" | "intermediate" |
 */
const Checkbox = forwardRef(
  (
    {
      onChange,
      disabled,
      label,
      checked,
      readonly,
      defaultChecked,
      name,
      id,
      variant = 'primary',
      type = 'checkbox',
      value,
    },
    ref
  ) => {
    const hasLabel = Boolean(label);
    const checkboxClass = `checkbox_input checkbox_input--${variant} checkbox_input--${type}`;

    return (
      <div className="checkbox_container">
        <input
          ref={ref}
          readOnly={readonly}
          defaultChecked={defaultChecked}
          className={checkboxClass}
          type="checkbox"
          name={name}
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          value={value}
        />
        {hasLabel && (
          <label className="checkbox_label" htmlFor={id}>
            {label}
          </label>
        )}
      </div>
    );
  }
);

export default Checkbox;
