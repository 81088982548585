import PlusIcon from './imgs/add-gray.svg';
import HeroImage from '../image_gallery/HeroImage';
import { useUploadImage } from '../../queries/use_images';
import GenericButton from '../generic_button/GenericButton';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserSupplier, useUserSupplier } from '../../queries/use_user_supplier';
import GalleryImages from '../image_gallery/GalleryImages';
import Spinner from '../spinner/Spinner';
import { useState } from 'react';

export default function AccountProfile() {
  const { uploadImage } = useUploadImage();
  const navigate = useNavigate();

  const { updateSupplierData } = useUpdateUserSupplier();
  const { data: userSupplierData, isLoading: isSupplierDataLoading } = useUserSupplier({});
  const [logoUploading, setLogoUploading] = useState(false);

  const onUpload = async (e) => {
    const files = e.target.files;
    const formData = new FormData();
    formData.append('image', files[0]);

    if (files.length) {
      try {
        setLogoUploading(true);
        const imageResponse = await uploadImage(formData);
        await updateSupplierData({ companyLogo: imageResponse.data });
      } catch (e) {
        console.error('[UPDATE USER DATA ERROR]', e);
      } finally {
        setLogoUploading(false);
      }
    }
  };

  if (isSupplierDataLoading) {
    return null;
  }

  return (
    <div>
      <div className="account-topbar">
        <div style={{ maxWidth: '120px' }}>
          <GenericButton
            styles={{ padding: '11px 39px', borderRadius: '0' }}
            label="Preview"
            color="white"
            backgroundColor="black"
            onClick={() => navigate('/preview')}
          />
        </div>
      </div>
      <div style={{ width: '391px', paddingTop: '20px', paddingBottom: '20px' }}>
        <p
          style={{
            lineHeight: '20px',
            color: '#C4C4C4',
            fontSize: '14px',
            marginTop: '0',
            fontWeight: '700',
            fontFamily: 'Object Sans Thin',
          }}>
          Showcase beautiful project and lifestyle imagery to help people visualise your product
        </p>

        <div className="account-profile__wrapper">
          <div style={{ fontSize: '14px', margin: '15px 0 15px 0' }}>Logo</div>

          <label htmlFor="profileImage" style={{ display: 'inline-block' }}>
            <div
              className={`account-profile__avatar ${
                userSupplierData?.companyLogo ? 'has-value' : ''
              }`}>
              {logoUploading ? (
                <Spinner styles={{ marginTop: '0px' }} />
              ) : userSupplierData?.companyLogo ? (
                <img
                  src={userSupplierData?.companyLogo}
                  className="image__full-width"
                  alt="Profile Images"
                />
              ) : (
                <img src={PlusIcon} alt="Avatar blank" />
              )}
              {!logoUploading && (
                <div className="account-profile__avatar--overlay">
                  <img src={PlusIcon} alt="Avatar blank" className="account-profile__plus-icon" />
                </div>
              )}
              <input
                type="file"
                id="profileImage"
                accept="image/png, image/jpeg"
                hidden
                onChange={onUpload}
              />
            </div>
          </label>
        </div>
        <div />
        <GalleryImages
          userData={userSupplierData}
          onUpdateSupplierData={updateSupplierData}
          onUploadImage={uploadImage}
        />
      </div>
    </div>
  );
}
