import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { authApiClient } from '../api';

export const SUPPLIER_QUERY_KEY = 'SUPPLIER_QUERY_KEY';
export const ACTIVE_SUPPLIER_QUERY_KEY = 'ACTIVE_SUPPLIER_QUERY_KEY';

export const getSupplier = ({ supplierId }) =>
  authApiClient.get(`/suppliers/${supplierId}`).then((res) => res.data);

export const useSupplier = ({ supplierId }) => {
  const {
    data = {},
    isLoading,
    isRefetching,
  } = useQuery([SUPPLIER_QUERY_KEY], () => getSupplier({ supplierId }), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: Boolean(supplierId),
  });

  return { data, isLoading, isRefetching };
};
