import InputAccountProfile from './InputAccountProfile';
import GenericButton from '../generic_button/GenericButton';
import { useState } from 'react';
import { useNotifications } from '../notifications/useNotifications';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserSupplier, useUserSupplier } from '../../queries/use_user_supplier';
import Select from 'react-select';
import { priceGuide } from '../../consts/price-guide';
import '../../assets/style/select-styled.scss';

export default function AccountProfile() {
  const { notify } = useNotifications();
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [description, setDescription] = useState('');
  const [instagram, setInstagram] = useState('');
  const [priceGuideValue, setPriceGuideValue] = useState('');

  const onSupplierLoad = (data) => {
    setCompanyName(data?.['Company Name'] ?? '');
    setDescription(data?.description ?? '');
    setInstagram(data?.instagram ?? '');
    setPriceGuideValue(data?.['Price Guide'] ?? '');
  };
  const { isLoading } = useUserSupplier({ onSuccess: onSupplierLoad });

  const { updateSupplierData, isLoading: isSupplierUpdating } = useUpdateUserSupplier();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateSupplierData({
        'Company Name': companyName,
        description,
        instagram,
        'Price Guide': priceGuideValue,
      });
      notify('Supplier data changed successfully.', 'success');
    } catch (e) {
      console.error('[UPDATE USER DATA ERROR]', e);
      notify('Something went wrong. Please try again later.', 'error');
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <div className="account-topbar">
        <div style={{ maxWidth: '120px' }}>
          <GenericButton
            styles={{ padding: '11px 39px', borderRadius: '0' }}
            label="Preview"
            color="white"
            backgroundColor="black"
            onClick={() => navigate('/preview')}
          />
        </div>
      </div>
      <div style={{ width: '391px', paddingTop: '20px', paddingBottom: '20px' }}>
        <p
          style={{
            lineHeight: '20px',
            color: '#C4C4C4',
            fontSize: '14px',
            marginTop: '0',
            fontWeight: '700',
            fontFamily: 'Object Sans Thin',
          }}>
          This information is publicly available to everyone who visits your profile on Portaire.
        </p>

        <InputAccountProfile
          label="Company name"
          marginBottom="13px"
          placeholder="Portaire"
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          value={companyName}
        />

        <InputAccountProfile
          label="Description"
          placeholder="Tell designer about your business"
          marginBottom="13px"
          textarea
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
        />
        <div style={{ marginBottom: '12px' }}>
          <label
            style={{
              fontSize: '12px',
              marginBottom: '2px',
              paddingLeft: '4px',
              display: 'flex',
              fontFamily: 'Object Sans Medium',
              position: 'relative',
            }}>
            Price Guide
          </label>
          <Select
            className={'react-select-container'}
            classNamePrefix={'react-select'}
            options={priceGuide}
            value={{ label: priceGuideValue, value: priceGuideValue }}
            onChange={(selectedOption) => setPriceGuideValue(selectedOption.value)}
          />
        </div>
        <InputAccountProfile
          label="Instagram"
          marginBottom="13px"
          placeholder="https://www.instagram.com/portaire"
          optional
          onChange={(e) => {
            setInstagram(e.target.value);
          }}
          value={instagram}
        />
        <div>
          <GenericButton
            onClick={handleSubmit}
            topSeparator
            label={'Save'}
            color="white"
            backgroundColor="black"
            loading={isSupplierUpdating}
          />
        </div>
      </div>
    </div>
  );
}
