import { useEffect, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { selectHandlers } from '../utils';

export const useNavigation = (
  optionsLength,
  onCreateOption,
  onOptionSelect,
  refs,
  searchOutput,
  open
) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleSetActiveIndex = useCallback(
    (index) => {
      setActiveIndex(index);
      refs[searchOutput[index]._id]?.current?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
      refs[searchOutput[index]._id]?.current?.focus();
    },
    [refs, searchOutput]
  );

  useEffect(() => {
    const handleOptionsSelect = () => {
      if (searchOutput[activeIndex].id === 'creatable') {
        onCreateOption(searchOutput[activeIndex].name);
        onOptionSelect({ name: searchOutput[activeIndex].name, id: uuid() });

        return;
      }
      onOptionSelect(searchOutput[activeIndex]);
    };
    if (open) {
      return selectHandlers(optionsLength, activeIndex, handleSetActiveIndex, handleOptionsSelect);
    }
  }, [
    open,
    activeIndex,
    searchOutput,
    onOptionSelect,
    onCreateOption,
    optionsLength,
    handleSetActiveIndex,
  ]);

  return { activeIndex, setActiveIndex };
};
