import { useState } from 'react';
import './Portable.scss';
import PlusIcon from './imgs/Plus.svg';
import PillFile from '../pill/PillFile';
import SelectStyled from '../select/Select';
import { getProductCurrency } from './utils';
import { CURRENCIES, SUITABILITY } from './mocks';
import { Cell, TextAreaCell, TextCell, NumberCell } from './parts';
import SelectCell from './parts/SelectCell';
import { useUpdateCategory } from '../../queries/use_categories';
import { useUpdateLead } from '../../queries/use_lead';
import { useUpdateFinish } from '../../queries/use_finish';
import { useAddProductGroup } from '../../queries/use_product_groups';
import { useUser } from '../../api/auth';
import { useProductUpdate } from './Row.hooks';
import ProductGroupCell from './parts/ProductGroupCell';
import { VariantCell } from './parts/VariantCell';
import { useActionsContext } from '../../context/actions.context';
import { CheckboxCell } from './parts/CheckboxCell';
import { ProductCheckboxCell } from './parts/ProductCheckboxCell';

export default function Row({
  product,
  showSuppliers,
  categories,
  products,
  setActiveProduct,
  setStatusSaved,
  setRowToDelete,
  setQuickViewVisible,
  rowsSelected,
  setRowsSelected,
  openModal,
  modalOpen,
  sustainability,
  itemIndex,
  setShowMenu,
  showMenu,
  setMenuPosition,
  leadOptions,
  units,
  colour,
  material,
  finish,
  style,
  suppliers,
  index,
  status,
  priceGuide,
  toggleModalMode,
  location,
  className = 'row_base',
  autoFocusFirstField = false,
}) {
  const { clearProductValue, updateProductValue, updateProductData } = useProductUpdate(
    product,
    products
  );
  const { copyValue, pasteValue } = useActionsContext();
  const { addProductGroup } = useAddProductGroup();
  const { updateCategoryData } = useUpdateCategory();
  const { updateLeadData } = useUpdateLead();
  const { updateFinishData } = useUpdateFinish();

  const [openOnEnter, setOpenOnEnter] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const isRowSelected = rowsSelected?.includes(product['_id']);
  const { isAdmin } = useUser();

  return (
    <tr
      className={className}
      onClick={() => {
        if (showMenu) {
          setActiveProduct(product);
        }
      }}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseOut={() => {
        setIsHover(false);
      }}>
      <ProductCheckboxCell
        index={index}
        fieldName={'_id'}
        product={product}
        columnIndex={0}
        selected={isRowSelected}
        showCheckbox={isHover || isRowSelected}
        setRowsSelected={setRowsSelected}
        rowsSelected={rowsSelected}
        itemIndex={itemIndex}
      />
      <TextCell
        sticky
        rowIndex={index}
        columnIndex={1}
        draggable={false}
        setShowMenu={setShowMenu}
        fieldName="Product Name"
        productId={product['_id']}
        setRowToDelete={setRowToDelete}
        rowsSelected={rowsSelected}
        setRowsSelected={setRowsSelected}
        setMenuPosition={setMenuPosition}
        defaultValue={product['Product Name']}
        autoFocus={autoFocusFirstField}
        onClick={(e) => {
          setActiveProduct(product);
        }}
        onChange={(e) => {
          setStatusSaved('Saving');
          product['Product Name'] = e.target.value;
        }}
        onBlur={async () => {
          try {
            await updateProductData({
              data: {
                'Product Name': product['Product Name'],
              },
              id: product['_id'],
              previousData: product['Product Name'],
            });
          } catch (e) {
            console.error('[UPDATE PRODUCT ERROR]', e);
          } finally {
            setStatusSaved('Saved');
          }
        }}
      />
      {showSuppliers && (
        <SelectCell
          fieldName="Supplier Name"
          index={index}
          rowIndex={index}
          columnIndex={2}
          productId={product['_id']}
          value={product['Supplier Name']}
          options={suppliers}
          onChange={async (option) => {
            await updateProductData({
              data: {
                'Supplier Name': option.name,
                SupplierId: {
                  _id: option._id,
                  name: option.name,
                },
              },
              id: product['_id'],
              previousData: {
                'Supplier Name': product['Supplier Name'],
                SupplierId: product['SupplierId'],
              },
            });
          }}
          backspaceRemoves={false}
          showCloseHandles={false}
          showCloseOnHover={false}
        />
      )}
      <Cell
        id={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 3 : 2}
        fieldName={'Status'}
        onDoubleClick={() => setOpenOnEnter(true)}
        defaultValue={product['Status'] || 'Draft'}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            setOpenOnEnter(!openOnEnter);
          }
          if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
            if (e.key === 'c') {
              copyValue({ data: { Status: product['Status'] } });
            }
            if (e.key === 'v') {
              await pasteValue({
                productId: product['_id'],
                previousData: { Status: product['Status'] },
                product,
                fieldName: 'Status',
              });
            }
          }
        }}>
        <SelectStyled
          status={status}
          isAdmin={isAdmin}
          enableDropdownPreview={isAdmin && showSuppliers}
          label={product['Status'] || 'Draft'}
          product={product}
          products={products}
          updateProductValue={isAdmin && updateProductValue}
          openOnEnter={isAdmin && openOnEnter}
          setOpenOnEnter={isAdmin ? setOpenOnEnter : () => null}
        />
      </Cell>
      <CheckboxCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 4 : 3}
        fieldName={'Exclusive'}
        product={product}
        id={'exclusive'}
        onUpdateProduct={updateProductValue}
      />
      <ProductGroupCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 5 : 4}
        value={product['GroupId']?.name}
        defaultValue={product['GroupId']?.name}
        product={product}
        productId={product['_id']}
        groupId={product['GroupId']?._id}
        supplierId={product['SupplierId']?._id}
        setQuickViewVisible={setQuickViewVisible}
        onSettingsClick={() => {
          setActiveProduct(product);
          openModal('manage-options');
        }}
        setActiveProduct={setActiveProduct}
        onChange={async (option) => {
          if (option?._id) {
            await updateProductValue('GroupId', {
              name: option.name,
              _id: option._id,
            });
          }
        }}
        onCreateGroup={async (option) => {
          const newProductGroup = await addProductGroup({
            supplierId: product['SupplierId']?._id,
            name: option,
          });
          const newProductGroupData = newProductGroup.data?.item;
          await updateProductValue('GroupId', {
            name: newProductGroupData.name,
            _id: newProductGroupData._id,
          });
        }}
        clearValue={async () => {
          await updateProductData({
            data: {
              GroupId: null,
              Options: null,
            },
            id: product['_id'],
            previousData: {
              GroupId: product['GroupId'],
              Options: product['Options'],
            },
          });
        }}
      />
      <VariantCell
        value={product['Options']}
        rowIndex={index}
        columnIndex={showSuppliers ? 6 : 5}
        product={product}
        productId={product['_id']}
        groupId={product['GroupId']?._id}
        minOptionLength={product['GroupId']?.options?.length}
        onVariantChange={async (option) => {
          await updateProductValue('Options', option);
        }}
        clearValue={async () => {
          await clearProductValue('Options');
        }}
      />
      <TextAreaCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 7 : 6}
        key={product['_id']}
        fieldName="Description"
        productId={product['_id']}
        defaultValue={product['Description']?.trim()}
        onBlur={async (value) => {
          await updateProductValue('Description', value);
        }}
      />
      <Cell
        id={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 8 : 7}
        onClick={() => {
          setActiveProduct(product);
        }}
        fieldName={'Product Images'}
        onDoubleClick={() => {
          toggleModalMode(product['Product Images']?.length > 0 ? 'files' : 'upload');

          openModal('image-upload');
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && modalOpen !== 'image-upload') {
            return openModal('image-upload');
          }

          if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
            if (e.key === 'c') {
              return copyValue({ data: { 'Product Images': product['Product Images'] } });
            }
            if (e.key === 'v') {
              await pasteValue({
                fieldName: 'Product Images',
                productId: product['_id'],
                previousData: { 'Product Images': product['Product Images'] },
                product,
              });
              return;
            }
          }
        }}>
        <div
          style={{
            display: 'flex',
            gap: '4px',
          }}>
          {product['Product Images']
            ? product['Product Images'].map((image) => {
                return <img width="20px" height="20px" src={image} key={image} alt="product" />;
              })
            : ''}
          <div
            style={{
              width: '40px',
            }}>
            {isHover && (
              <img
                src={PlusIcon}
                role="button"
                onClick={() => {
                  toggleModalMode('upload');
                  openModal('image-upload');
                }}
                alt="open gallery"
              />
            )}
          </div>
        </div>
      </Cell>
      <Cell
        id={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 9 : 8}
        fieldName={'Technical Drawings or PDFs'}
        draggable={false}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && modalOpen !== 'drawings-upload') {
            toggleModalMode(product['Technical Drawings or PDFs']?.length > 0 ? 'files' : 'upload');

            return openModal('drawings-upload');
          }

          if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
            if (e.key === 'c') {
              return copyValue({
                data: { 'Technical Drawings or PDFs': product['Technical Drawings or PDFs'] },
              });
            }
            if (e.key === 'v') {
              await pasteValue({
                fieldName: 'Technical Drawings or PDFs',
                productId: product['_id'],
                previousData: {
                  'Technical Drawings or PDFs': product['Technical Drawings or PDFs'],
                },
                product,
              });
              return;
            }
          }
        }}
        onClick={() => {
          setActiveProduct(product);
        }}
        onDoubleClick={() => {
          toggleModalMode(product['Technical Drawings or PDFs']?.length > 0 ? 'files' : 'upload');

          openModal('drawings-upload');
        }}>
        <div
          style={{
            height: '30px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
          }}>
          {product['Technical Drawings or PDFs'] &&
            product['Technical Drawings or PDFs']?.length > 0 &&
            !Array.isArray(product['Technical Drawings or PDFs']) &&
            product['Technical Drawings or PDFs']?.split(',')?.map((fileItem) => {
              return (
                <PillFile
                  key={fileItem}
                  backgroundColor="#38776B"
                  color="white"
                  label={fileItem?.split('(')[0]}
                />
              );
            })}
          <div
            style={{
              width: '40px',
            }}>
            {isHover && (
              <img
                src={PlusIcon}
                role="button"
                onClick={() => {
                  toggleModalMode('upload');
                  openModal('drawings-upload');
                }}
                alt="open gallery"
              />
            )}
          </div>
        </div>
      </Cell>
      <Cell
        rowIndex={index}
        columnIndex={showSuppliers ? 10 : 9}
        onClick={() => {
          setActiveProduct(product);
        }}
        onDoubleClick={() => {
          toggleModalMode(product['Downloads']?.length > 0 ? 'files' : 'upload');

          openModal('document-upload');
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && modalOpen !== 'document-upload') {
            toggleModalMode(product['Downloads']?.length > 0 ? 'files' : 'upload');

            return openModal('document-upload');
          }

          if (Boolean(e.ctrlKey) || Boolean(e.metaKey)) {
            if (e.key === 'c') {
              return copyValue({
                data: { Downloads: product['Downloads'] },
              });
            }
            if (e.key === 'v') {
              await pasteValue({
                fieldName: 'Downloads',
                productId: product['_id'],
                previousData: {
                  Downloads: product['Downloads'],
                },
                product,
              });
              return;
            }
          }
        }}
        id={index}
        draggable={false}
        fieldName={'Downloads'}>
        <div
          style={{
            height: '30px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
          }}>
          {product['Downloads'] &&
            product['Downloads'].map((fileItem) => {
              return (
                <PillFile key={fileItem} backgroundColor="#38776B" color="white" label={fileItem} />
              );
            })}
          <div
            style={{
              width: '40px',
            }}>
            {isHover && (
              <img
                src={PlusIcon}
                role="button"
                onClick={() => {
                  toggleModalMode('upload');
                  openModal('document-upload');
                }}
                alt="open gallery"
              />
            )}
          </div>
        </div>
      </Cell>
      <SelectCell
        productId={product['_id']}
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 11 : 10}
        fieldName="Categories"
        value={product['Categories']}
        creatable
        options={categories}
        onCreateOption={async (option) => {
          await updateCategoryData({
            category: {
              name: option,
            },
          });
        }}
        onChange={async (option) => {
          await updateProductValue('Categories', option.name);
        }}
        onSingleTagRemove={async () => {
          await clearProductValue('Categories');
        }}
        clearValue={async () => {
          await clearProductValue('Categories');
        }}
      />

      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 12 : 11}
        productId={product['_id']}
        fieldName="Styles"
        multiselect
        value={product['Styles']?.sort()}
        options={style}
        onChange={async (values) => {
          await updateProductValue('Styles', values);
        }}
        onSingleTagRemove={async (option) => {
          await updateProductValue(
            'Styles',
            product['Styles'].filter((val) => {
              return val != option;
            })
          );
        }}
        clearValue={async () => {
          await clearProductValue('Styles');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 13 : 12}
        productId={product['_id']}
        multiselect
        fieldName="Colour"
        value={product['Colour']?.sort()}
        options={colour}
        onChange={async (values) => {
          await updateProductValue('Colour', values);
        }}
        onSingleTagRemove={async (option) => {
          await updateProductValue(
            'Colour',
            product['Colour'].filter((val) => {
              return val !== option;
            })
          );
        }}
        clearValue={async () => {
          await clearProductValue('Colour');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 14 : 13}
        productId={product['_id']}
        fieldName="Finish"
        value={product['Finish']}
        options={finish}
        multiselect
        creatable
        onCreateOption={async (option) => {
          await updateFinishData({
            finish: {
              name: option,
            },
          });
        }}
        onChange={async (values) => {
          await updateProductValue('Finish', values);
        }}
        onSingleTagRemove={async (option) => {
          // await clearProductValue("Finish");
          await updateProductValue(
            'Finish',
            product.Finish.filter((style) => {
              return style != option;
            })
          );
        }}
        clearValue={async () => {
          await clearProductValue('Finish');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 15 : 14}
        productId={product['_id']}
        multiselect
        fieldName="Material"
        value={product['Material']?.sort()}
        options={material}
        onChange={async (values) => {
          await updateProductValue('Material', values);
        }}
        onSingleTagRemove={async (option) => {
          await updateProductValue(
            'Material',
            product['Material'].filter((val) => {
              return val != option;
            })
          );
        }}
        clearValue={async () => {
          await clearProductValue('Material');
        }}
      />
      <CheckboxCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 16 : 15}
        fieldName={'Customisable'}
        product={product}
        id={'customisable'}
        onUpdateProduct={updateProductValue}
      />
      <CheckboxCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 17 : 16}
        fieldName={'Sample'}
        product={product}
        id={'sample'}
        onUpdateProduct={updateProductValue}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 18 : 17}
        fieldName={'Width'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Width']}
        onChange={(e) => {
          setStatusSaved('Saving');
        }}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Width: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Width: product['Width'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 19 : 18}
        fieldName={'Length'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Length']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Length: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Length: product['Length'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 20 : 19}
        fieldName={'Height'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Height']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Height: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Height: product['Height'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 21 : 20}
        fieldName={'Thickness'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Thickness']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Thickness: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Thickness: product['Thickness'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 22 : 21}
        fieldName={'Diameter'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Diameter']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Diameter: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Diameter: product['Diameter'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 23 : 22}
        fieldName={'Weight'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Weight']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Weight: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Weight: product['Weight'],
            },
          });
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 24 : 23}
        productId={product['_id']}
        fieldName="Sustainability"
        multiselect
        value={product['Sustainability']?.sort()}
        options={sustainability}
        onChange={async (values) => {
          await updateProductValue('Sustainability', values);
        }}
        onSingleTagRemove={async (option) => {
          await updateProductValue(
            'Sustainability',
            product['Sustainability'].filter((val) => {
              return val !== option;
            })
          );
        }}
        clearValue={async () => {
          await clearProductValue('Sustainability');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 25 : 24}
        productId={product['_id']}
        fieldName="Suitability"
        multiselect
        value={product['Suitability']?.sort()}
        options={SUITABILITY}
        onChange={async (values) => {
          await updateProductValue('Suitability', values);
        }}
        onSingleTagRemove={async (option) => {
          await updateProductValue(
            'Suitability',
            product['Suitability'].filter((val) => {
              return val != option;
            })
          );
        }}
        clearValue={async () => {
          await clearProductValue('Suitability');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 26 : 25}
        productId={product['_id']}
        fieldName="Lead Time"
        value={product['Lead Time']}
        options={leadOptions}
        creatable
        onChange={async (option) => {
          await updateProductValue('Lead Time', option.name);
        }}
        onSingleTagRemove={async () => {
          await clearProductValue('Lead Time');
        }}
        clearValue={async () => {
          await clearProductValue('Lead Time');
        }}
        onCreateOption={async (option) => {
          await updateLeadData({
            lead: {
              name: option,
            },
          });
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 27 : 26}
        productId={product['_id']}
        fieldName="Location"
        value={product['Location']}
        options={location}
        onChange={async (option) => {
          await updateProductValue('Location', option.name);
        }}
        onSingleTagRemove={async () => {
          await clearProductValue('Location');
        }}
        clearValue={async () => {
          await clearProductValue('Location');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 28 : 27}
        productId={product['_id']}
        fieldName="Unit"
        value={product['Unit'] || 'U - Unit'}
        options={units}
        onChange={async (option) => {
          await updateProductValue('Unit', option.name);
        }}
        onSingleTagRemove={async () => {
          await clearProductValue('Unit');
        }}
        clearValue={async () => {
          await clearProductValue('Unit');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 29 : 28}
        productId={product['_id']}
        fieldName="Currency"
        value={getProductCurrency(product['Currency'])}
        options={CURRENCIES}
        onChange={async (option) => {
          await updateProductValue('Currency', option.id);
        }}
        onSingleTagRemove={async () => {
          await clearProductValue('Currency');
        }}
        clearValue={async () => {
          await clearProductValue('Currency');
        }}
      />
      <SelectCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 30 : 29}
        productId={product['_id']}
        fieldName="Price Guide"
        value={product['Price Guide'] || '££'}
        options={priceGuide}
        onChange={async (option) => {
          await updateProductValue('Price Guide', option.name);
        }}
        onSingleTagRemove={async () => {
          await clearProductValue('Price Guide');
        }}
        clearValue={async () => {
          await clearProductValue('Price Guide');
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 31 : 30}
        productId={product['_id']}
        fieldName={'Price - Retail'}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Price - Retail']}
        onBlur={async (e) => {
          product['Price - Retail'] = e.target.value;
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              'Price - Retail': e.target.value,
            },
            id: product['_id'],
            previousData: {
              'Price - Retail': product['Price - Retail'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 32 : 31}
        fieldName={'Price - Trade'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Price - Trade']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          product['Price - Trade'] = e.target.value;
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              'Price - Trade': e.target.value,
            },
            id: product['_id'],
            previousData: {
              'Price - Trade': product['Price - Trade'],
            },
          });
        }}
      />
      <NumberCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 33 : 32}
        fieldName={'Discount'}
        productId={product['_id']}
        setActiveProduct={setActiveProduct}
        defaultValue={product['Discount']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              Discount: e.target.value,
            },
            id: product['_id'],
            previousData: {
              Discount: product['Discount'],
            },
          });
        }}
      />
      <CheckboxCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 34 : 33}
        fieldName={'VAT'}
        product={product}
        id={product['_id']}
        onUpdateProduct={updateProductValue}
      />
      <TextCell
        fieldName={'Product URL'}
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 35 : 34}
        defaultValue={product['Product URL'] || product['Original URL']}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          product['Product URL'] = e.target.value;
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              'Product URL': e.target.value,
            },
            id: product['_id'],
            previousData: {
              'Product URL': product['Product URL'],
            },
          });
        }}
      />
      <TextAreaCell
        fieldName={'Meta Description'}
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 36 : 35}
        defaultValue={product['Meta Description']?.trim()}
        onBlur={async (value) => {
          await updateProductValue('Description', value);
        }}
      />
      <TextCell
        index={index}
        rowIndex={index}
        columnIndex={showSuppliers ? 37 : 36}
        defaultValue={product['Image Alt Text']}
        fieldName={'Image Alt Text'}
        onChange={() => setStatusSaved('Saving')}
        onBlur={async (e) => {
          setStatusSaved('Saved');
          await updateProductData({
            data: {
              'Image Alt Text': e.target.value,
            },
            id: product['_id'],
            previousData: {
              'Image Alt Text': product['Image Alt Text'],
            },
          });
        }}
      />
    </tr>
  );
}
