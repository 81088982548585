import { forwardRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import TrashIcon from '../imgs/trash.svg';
import DownloadIcon from '../imgs/download.svg';

export const ImageCard = forwardRef(({ image, name, onRemove, onDownload, ...props }, ref) => {
  return (
    <div className="img_card" ref={ref} {...props}>
      <img alt={name} src={image} className="img_card_file" />
      <div className="img_card_nav">
        <span style={{ width: '120px', whiteSpace: 'pre-wrap' }}>{name}</span>
        <div className="img_card_actions">
          <a className="img_card_action_button" href={image} download>
            <img onClick={onDownload} alt="download" role="button" src={DownloadIcon} />
          </a>
          <button className="img_card_action_button" onClick={onRemove}>
            <img alt="remove" src={TrashIcon} />
          </button>
        </div>
      </div>
    </div>
  );
});

export const SortableImageCard = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return <ImageCard ref={setNodeRef} style={style} {...attributes} {...listeners} {...props} />;
};
