import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './api/query-client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { AuthProviderWrapper } from './context/auth.context';
import { ActionsProviderWrapper } from './context/actions.context';
import { ProductProvider } from './context/product.context';
import { ProvideRoutersWrapper } from './context/routes.context';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ActionsProviderWrapper>
        <ProductProvider>
          <Router>
            <AuthProviderWrapper>
              <ProvideRoutersWrapper>
                <App />
              </ProvideRoutersWrapper>
            </AuthProviderWrapper>
          </Router>
        </ProductProvider>
      </ActionsProviderWrapper>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
