import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { authApiClient } from '../api';

const FINISH_QUERY_KEY = 'FINISH_QUERY';

const getFinish = () => authApiClient.get('/tag/finish').then((res) => res.data);

export const useFinish = () => {
  const { data = [], isLoading } = useQuery([FINISH_QUERY_KEY], getFinish, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export const useUpdateFinish = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.post(`tag/finish`, data?.finish),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([FINISH_QUERY_KEY]);
      },
    }
  );

  return {
    updateFinishData: mutateAsync,
    isLoading,
  };
};
