import { Cell } from './index';
import Checkbox from '../../checkbox/Checkbox';
import { getCheckboxLabel } from '../utils';
import { useCallbackRef } from '../../../hooks/useCallbackRef';

export const CheckboxCell = ({
  index,
  rowIndex,
  columnIndex,
  product,
  fieldName,
  styleOverrides,
  id,
  onUpdateProduct,
}) => {
  const { ref: checkboxRef, handleRef: handleCheckboxRef } = useCallbackRef(); // For setting/ unsetting editing

  return (
    <Cell
      id={index}
      rowIndex={rowIndex}
      columnIndex={columnIndex}
      fieldName={fieldName}
      defaultValue={product[fieldName]}
      styleOverrides={styleOverrides}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          checkboxRef.checked = !checkboxRef.checked;
          await onUpdateProduct(fieldName, checkboxRef.checked);
        }
      }}>
      <Checkbox
        ref={handleCheckboxRef}
        defaultChecked={product[fieldName]}
        label={getCheckboxLabel(product[fieldName])}
        id={id}
        onChange={async (e) => {
          await onUpdateProduct(fieldName, e.target.checked);
        }}
      />
    </Cell>
  );
};
