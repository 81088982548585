import BadgeIcon from './imgs/badge.svg';

export default function Details({ user }) {
  const userCompanyName = user?.companyName;

  const Avatar = () => {
    const firstChar = userCompanyName?.charAt(0);
    return user?.companyLogo ? (
      <img className="message-contact__first-char" src={user?.companyLogo} alt="logo" />
    ) : (
      <div className="message-contact__first-char">{firstChar}</div>
    );
  };

  return (
    <div className="message-details__wrapper">
      <div className="message-details__name">
        {user ? user['Premium?'] === 'checked' && <img src={BadgeIcon} alt="Badge icon" /> : ''}
        {user ? user?.name : ''}
      </div>
      <div className="message-details__active">Last active 12 hours ago</div>
      {userCompanyName && (
        <div className="message-details__info">
          <Avatar />
          <p>{userCompanyName}</p>
        </div>
      )}
      {/*<div className="message-details__bio">*/}
      {/*  <Accordion title="Bio" description={user && user['Blurb']} />*/}
      {/*</div>*/}
    </div>
  );
}
