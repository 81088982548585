import { useContext, useState } from 'react';

import LeafIcon from './imgs/leaf-icon.svg';
import TickRound from './imgs/tick_round.svg';
import { ProvideRoutesContext } from '../../context/routes.context';
import PlaceholderImage from './imgs/placeholder.png';

export default function SupplierCard({ product, itemsToAdd, setItemsToAdd }) {
  const { setActiveProduct, setQuickViewVisible } = useContext(ProvideRoutesContext);

  const [isHovered, setIsHovered] = useState(false);
  const imageSrc = product['Thumbnail Image']
    ? product['Thumbnail Image'].split('(')[1].split(')')[0]
    : product['Product Images'].length > 0
    ? product['Product Images'][0]
    : PlaceholderImage;

  return (
    product && (
      <>
        <div
          className="product-grid-element"
          style={{
            position: 'relative',
            marginTop: '20px',
            cursor: 'pointer',
            backgroundColor: 'white',
          }}
          onClick={() => {
            setActiveProduct(product);
            setQuickViewVisible(true);
          }}
          onMouseOver={() => {
            setIsHovered(true);
          }}
          onMouseOut={() => {
            setIsHovered(false);
          }}>
          <div
            className="product-grid-img-wrapper"
            style={{ position: 'relative', backgroundColor: '#F5F5F5' }}>
            <img
              style={{ objectFit: 'contain' }}
              height="280px"
              width="100%"
              alt="thumbnail img"
              src={imageSrc}
            />
            {itemsToAdd && itemsToAdd?.includes(product) && (
              <div
                style={{
                  position: 'absolute',
                  top: '12px',
                  left: '12px',
                  zIndex: '40',
                }}>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (itemsToAdd.includes(product)) {
                      let newItemsToAdd = itemsToAdd.filter((el) => {
                        return el != product;
                      });
                      setItemsToAdd(newItemsToAdd);
                    } else {
                      let newItemsToAdd = itemsToAdd.concat([product]);
                      setItemsToAdd(newItemsToAdd);
                    }
                  }}>
                  <img src={TickRound} alt="tick" />
                </div>
              </div>
            )}
            {isHovered && itemsToAdd && !itemsToAdd.includes(product) && (
              <div
                style={{
                  position: 'absolute',
                  top: '12px',
                  left: '12px',
                  zIndex: '40',
                }}>
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid black',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    if (itemsToAdd.includes(product)) {
                      let newItemsToAdd = itemsToAdd.filter((el) => {
                        return el !== product;
                      });
                      setItemsToAdd(newItemsToAdd);
                    } else {
                      let newItemsToAdd = itemsToAdd.concat([product]);
                      setItemsToAdd(newItemsToAdd);
                    }
                  }}></div>
              </div>
            )}
            {isHovered && (
              <div
                style={{
                  zIndex: '20',
                  position: 'absolute',
                  opacity: '20%',
                  top: '0px',
                  left: '0px',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'black',
                }}></div>
            )}
          </div>
          <div className="product-grid-description-wrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '5px',
              }}>
              <div style={{ fontSize: '13px', fontFamily: 'Object Sans Medium' }}>
                {product['Supplier Name'] ? product['Supplier Name'] : '----'}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '5px',
              }}>
              <div style={{ fontSize: '14px' }}>{product['Product Name']}</div>
              <div></div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '5px',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '20px',
                  gap: '4px',
                }}
                onClick={() => {}}>
                {product['Sustainability']?.filter((el) => {
                  return el != '';
                }).length > 0 && <img alt="leaf icon" src={LeafIcon} />}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
