import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const PRICE_GUIDE_QUERY_KEY = 'PRICE_GUIDE_QUERY';

const getPriceGuide = () => authApiClient.get('/tag/priceguide').then((res) => res.data);

export const usePriceGuide = () => {
  const { data = [], isLoading } = useQuery([PRICE_GUIDE_QUERY_KEY], getPriceGuide, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
