import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useUser } from '../../api/auth';

import Message from './Message';
import SendMessage from './imgs/sendarrow.svg';
import '../../assets/style/messaging.scss';
import { useRoom } from '../../queries/use_room';
import { useSendMessageToDb } from '../../queries/use_messages';
import { CHAT_URL } from '../../consts/env-variables';

const socket = io.connect(CHAT_URL);

export default function Thread({ contact }) {
  const { userData } = useUser();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const roomId = `${contact}x${userData.SupplierId}`;

  const { isLoading } = useRoom(roomId, setMessages);
  const { sendMsgToDb, isLoading: isSendingMessage } = useSendMessageToDb();

  const sendMessage = () => {
    setMessages((previous) => {
      return [
        {
          content: message,
          from: userData.SupplierId,
          to: contact,
          room: roomId,
          createdAt: new Date(),
        },
        ...previous,
      ];
    });

    sendMsgToDb({
      content: message,
      from: userData.SupplierId,
      to: contact,
      room: roomId,
    });

    socket.emit('message', {
      content: message,
      from: userData.SupplierId,
      to: contact,
      room: roomId,
      createdAt: new Date(),
    });
    setMessage('');
  };

  const joinRoom = (room) => {
    if (room) {
      socket.emit('join_room', room);
    }
  };

  useEffect(() => {
    joinRoom(roomId);
  }, [roomId]);

  const receiveMessage = (data) =>
    setMessages((previous) => {
      return [data, ...previous];
    });

  useEffect(() => {
    socket.on('receive_message', receiveMessage);

    return () => {
      socket.off('receive_message', receiveMessage);
    };
  }, []);

  const handleSend = (e) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div className="thread__wrapper">
      <div className="thread__chat">
        {!isLoading &&
          messages.length > 0 &&
          messages
            .sort((a, b) => {
              if (a.createdAt > b.createdAt) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((element, i) => {
              return <Message key={i} msg={element} />;
            })}
      </div>
      <div className="thread__input-wrapper">
        <input
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Write your message"
          disabled={!contact}
        />
        <div className="thread__input-buttons">
          {/* <label htmlFor="attachment" className="center">
              <img src={AttachmentIcon} alt="Attachment icon" className="m0" />
            </label>
            <input type="file" id="attachment" accept="image/png, image/jpeg" hidden /> */}
          <img
            src={SendMessage}
            alt="Send Icon"
            onClick={contact ? handleSend : () => null}
            style={{ cursor: contact ? 'pointer' : 'not-allowed' }}
          />
        </div>
      </div>
    </div>
  );
}
