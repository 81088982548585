import { Cell } from './index';
import { useTableNavigationContext } from '../../../context/table_navigation.context';
import Checkbox from '../../checkbox/Checkbox';
import { useCallbackRef } from '../../../hooks/useCallbackRef';

export const ProductCheckboxCell = ({
  index,
  fieldName,
  product,
  columnIndex,
  selected,
  showCheckbox,
  setRowsSelected,
  rowsSelected,
  itemIndex,
}) => {
  const { activeCol, activeRow } = useTableNavigationContext();
  const { ref: checkboxRef, handleRef: handleCheckboxRef } = useCallbackRef(); // For setting/ unsetting editing

  const isActive = activeCol === columnIndex && activeRow === index;

  const handleCheckboxChange = () => {
    if (rowsSelected.includes(product[fieldName])) {
      let newRowsSelected = rowsSelected.filter((id) => id !== product[fieldName]);
      return setRowsSelected(newRowsSelected);
    }
    return setRowsSelected((prev) => [...prev, product[fieldName]]);
  };

  return (
    <Cell
      draggable={false}
      fieldName={fieldName}
      id={index}
      rowIndex={index}
      columnIndex={columnIndex}
      sticky
      style={{
        textAlign: 'center',
        width: '30px',
        position: 'sticky',
        left: 0,
        backgroundColor: '#ffffff',
        zIndex: 201,
        borderRight: 'none',
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleCheckboxChange();
        }
      }}>
      {Boolean(itemIndex) && (showCheckbox || isActive) ? (
        <Checkbox
          ref={handleCheckboxRef}
          checked={selected}
          onChange={handleCheckboxChange}
          variant="secondary"
        />
      ) : (
        <div>{itemIndex}</div>
      )}
    </Cell>
  );
};
