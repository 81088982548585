import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const UNITS_QUERY_KEY = 'UNITS_QUERY';

const getUnits = () => authApiClient.get('/tag/units').then((res) => res.data);

export const useUnits = () => {
  const { data, isLoading } = useQuery([UNITS_QUERY_KEY], getUnits, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
