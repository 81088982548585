import Splash from './imgs/splash.png';

import PortaireLogo from './imgs/Portaire.svg';
import GenericButton from '../generic_button/GenericButton';
import InputText from '../input_text/InputText';

import { useState } from 'react';

import { useSignIn } from '../../api/auth';

export default function Login({}) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [isSignup, setIsSignup] = useState(false);

  const { mutateAsync: signIn, error, isError, isLoading } = useSignIn();
  const errorMessage = error?.response?.data?.message;
  const errorCode = error?.response?.status;

  const isUserError = isError && (errorCode === 404 || errorCode === 403);
  const isPasswordError = isError && errorCode === 401;

  const handleSubmit = async (e) => {
    e.preventDefault();
    return signIn({ email, password });
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
      <div
        style={{
          width: '50%',
          backgroundImage: `url(${Splash})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          paddingTop: '80px',
        }}>
        <img width="233px" src={PortaireLogo} />
      </div>

      {/* Right size start */}
      {!isSignup && (
        <div
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div
            style={{
              width: '500px',
              padding: '20px',
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '35px',
                marginBottom: '30px',
              }}>
              Login
            </div>
            <div>
              <InputText
                label="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={isUserError}
                errorMessage={errorMessage}
              />
              <InputText
                label="Password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                error={isPasswordError}
                errorMessage={errorMessage}
              />
              <div style={{ display: 'flex', gap: '20px' }}>
                <GenericButton
                  label="Login"
                  backgroundColor="black"
                  color="white"
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                fontSize: '12px',
                cursor: 'pointer',
              }}
              onClick={() => {
                // setIsSignup(true);
              }}>
              {/* Don't have an account? Register. */}
            </div>
          </div>
        </div>
      )}

      {/* {isSignup && (
        <Signup
          isSignup={isSignup}
          setIsSignup={setIsSignup}
          email={email}
          setEmail={setEmail}
          name={name}
          setName={setName}
          userName={userName}
          setUserName={setUserName}
          companyName={companyName}
          setCompanyName={setCompanyName}
          password={password}
          setPassword={setPassword}
          error={error}
        />
      )} */}
    </div>
  );
}
