import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

const STATUS_QUERY_KEY = 'STATUS_QUERY';

const getStatus = () => authApiClient.get('/tag/status').then((res) => res.data);

export const useStatus = () => {
  const { data = [], isLoading } = useQuery([STATUS_QUERY_KEY], getStatus, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};
