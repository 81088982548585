import './LeftMenu.scss';
import { useEffect, useState } from 'react';
import LogoDummy from './imgs/prettyspaces.svg';
import ProjectsIcon from './imgs/projects-icon.svg';
import ProjectsIconActive from './imgs/projects-icon-active.svg';
import MessagesIcon from './imgs/messages-icon.svg';
import MessagesIconActive from './imgs/messages-icon-active.svg';
import LogoutIcon from './imgs/logout-icon.svg';
import ArrowLeft from './imgs/arrow-left.svg';
import ArrowRight from './imgs/arrow-right.svg';

import ProductsIcon from './imgs/products-icon.svg';
import ProductsIconActive from './imgs/products-icon-active.svg';
import AccountIcon from './imgs/account-icon.svg';
import AccountIconActive from './imgs/account-icon-active.svg';
import SettingsIcon from './imgs/settings-icon.svg';
import SettingsIconActive from './imgs/settings-icon-active.svg';
import { charToImage } from './utils';

import { useUser } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import { useUserSupplier } from '../../queries/use_user_supplier';

export default function LeftMenu({
  handleActiveTabChange,
  activeTab,
  accountSubVisible,
  menuNewVisible,
  setMenuNewVisible,
}) {
  const navigate = useNavigate();
  const { userData } = useUser();
  const { data: userSupplierData } = useUserSupplier({});
  

  return (
    <div
      className={`left-menu-new ${menuNewVisible ? '' : 'collapsed'}`}
      style={{
        width: menuNewVisible ? '245px' : '71px',
        padding: menuNewVisible ? '13px' : '13px 10px',
        cursor: 'default',
      }}>
      <div className="left-menu-new-top">
        <div style={{ display: 'flex', cursor: 'pointer' }}>
          <div
            className="left-menu-new-top-left"
            onClick={() => {
              setMenuNewVisible((p) => {
                return !p;
              });
            }}>
            <div className="left-menu-logo-overlay">
              <div>
                <img src={menuNewVisible ? ArrowLeft : ArrowRight} alt="Open / Close" />
              </div>
            </div>
            {userSupplierData?.companyLogo && userSupplierData?.companyLogo !== 'any' ? (
              <img
                alt="logo"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={userSupplierData?.companyLogo}
              />
            ) : (
              charToImage(userData)
            )}
          </div>

          <div className="left-menu-new-top-mid" style={{ opacity: menuNewVisible ? 1 : 0 }}>
            <div>
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#7D8693',
                }}></div>
              <div
                key={userData?.companyName}
                style={{ fontWeight: 500, textTransform: 'Capitalize' }}>
                {userData?.companyName}
              </div>
            </div>
          </div>
        </div>

        <div className="left-menu-new-top-right">
          {/* <img alt="up down" src={UpDown} className="menu-item-new-left-icon" />
          <img alt="collapse" src={Collapse} /> */}
        </div>
      </div>
      <div className="left-menu-new-bottom">
        <div
          style={{
            paddingBottom: '17px',
            borderBottom: '1px solid #f2f2f2',
            cursor: 'pointer',
          }}
          className="left-menu-new-bottom-top">
          {/* <div
            className={`menu-item-new ${activeTab === 'dashboard' ? 'active' : ''}`}
            onClick={() => handleActiveTabChange('')}>
            <div className={`menu-item-new-left`}>
              <div className="menu-item-new-left-icon">
                <img
                  alt="projects icon"
                  src={activeTab === 'dashboard' ? ProjectsIconActive : ProjectsIcon}
                />
              </div>
              <div
                className="menu-item-new-title"
                style={{
                  opacity: menuNewVisible ? 1 : 0,
                  transition: 'opacity 0.4s',

                  color: '#7D8693',
                }}>
                Dashboard
              </div>
            </div>

            <div className="menu-item-new-right"> <div className="menu-token">1</div> </div>
          </div> */}
          <div
            style={{ marginTop: '17px' }}
            className={`menu-item-new ${activeTab === 'inventory' ? 'active' : ''}`}
            onClick={() => handleActiveTabChange('inventory')}>
            <div className="menu-item-new-left">
              <div className="menu-item-new-left-icon">
                <img
                  alt="projects icon"
                  src={activeTab === 'inventory' ? ProductsIconActive : ProductsIcon}
                />
              </div>
              <div
                className="menu-item-new-title"
                style={{
                  opacity: menuNewVisible ? 1 : 0,
                  transition: 'opacity 0.4s',

                  color: '#7D8693',
                }}>
                Inventory
              </div>
            </div>

            <div className="menu-item-new-right">{/* <div className="menu-token">1</div> */}</div>
          </div>
          {/* Messages */}
          <div
            style={{ marginTop: '17px' }}
            className={`menu-item-new ${activeTab === 'messages' ? 'active' : ''}`}
            onClick={() => handleActiveTabChange('messages')}>
            <div className="menu-item-new-left">
              <div className="menu-item-new-left-icon">
                <img
                  alt="projects icon"
                  src={activeTab == 'messages' ? MessagesIconActive : MessagesIcon}
                />
              </div>{' '}
              <div
                className="menu-item-new-title"
                style={{
                  opacity: menuNewVisible ? 1 : 0,
                  transition: 'opacity 0.4s',
                }}>
                Messages
              </div>
              {/* Should replace the 3 with dynamic value */}
              {/* <div className="menu-item-notifications">
                <span>3</span> 
              </div> */}
            </div>

            <div className="menu-item-new-right">{/* <div className="menu-token">3</div> */}</div>
          </div>
        </div>

        <div className="left-menu-new-bottom-bottom">
          <div
            style={{ marginTop: '17px', cursor: 'pointer' }}
            className={`menu-item-new ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => {
              navigate('/profile');
              handleActiveTabChange('account');
            }}>
            <div className="menu-item-new-left">
              <div className="menu-item-new-left-icon">
                <img
                  alt="projects icon"
                  src={accountSubVisible ? AccountIconActive : AccountIcon}
                />
              </div>
              <div
                className="menu-item-new-title"
                style={{
                  opacity: menuNewVisible ? 1 : 0,
                  transition: 'opacity 0.4s',
                  color: accountSubVisible ? 'black' : '#7D8693',
                }}>
                Profile
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: '17px', cursor: 'pointer' }}
            className={`menu-item-new ${activeTab === 'settings' ? 'active' : ''}`}
            onClick={() => {
              handleActiveTabChange('settings');
            }}>
            <div className="menu-item-new-left">
              <div className="menu-item-new-left-icon">
                <img
                  alt="projects icon"
                  src={activeTab === 'settings' ? SettingsIconActive : SettingsIcon}
                />
              </div>
              <div
                className="menu-item-new-title"
                style={{
                  opacity: menuNewVisible ? 1 : 0,
                  transition: 'opacity 0.4s',
                  color: activeTab === 'settings' ? 'black' : '#7D8693',
                }}>
                Account
              </div>
            </div>
          </div>

          {/* <div
            style={{ marginTop: "17px", cursor: "pointer" }}
            className={`menu-item-new`}
            onClick={() => {
              handleActiveTabChange(signOut);
            }}
          >
            <div className="menu-item-new-left">
              <div className="menu-item-new-left-icon">
                <img style={{ width: '20px' }} alt="logout" src={LogoutIcon} />
              </div>
              <div
                className="menu-item-new-title"

                style={{
                  opacity: menuNewVisible ? 1 : 0,
                  transition: "opacity 0.4s",
                  color: "#f97878"
                }}
              >
                Logouts
              </div>
            </div>
          </div> */}

          {/* <div>
            <div
              onClick={signOut}
              style={{ color: "#F97878", cursor: "pointer", padding: "13px", display: "flex", alignItems: 'center' }}
            >
              <img style={{ width: '20px' }} alt="logout" src={LogoutIcon} />
              <div className="menu-item-new-title">
                Logout
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
