import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import showPassword from './imgs/icon-show.svg';
import hidePassword from './imgs/icon-hide.svg';
import '../../assets/style/rf-form.scss';

export default function RfInput({ name, placeholder, disabled, type, validate, id, value, label }) {
  const {
    register,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods
  const [switchType, setSwitchType] = useState(type);

  return (
    <>
      <label htmlFor={id} className="rf-label">
        <span>{name}</span>
        {name === 'password' && <p>Minimum 6 character, one upper case and one number</p>}
        <div className="rf-input__wrapper">
          <input
            {...register(name, {
              pattern: validate === 'password' ? /^(?=.*[A-Z])(?=.*\d).{6,}$/ : '',
              required: true,
              disabled,
            })}
            className={`rf-input ${errors[name] ? 'rf-input--error' : ''}`}
            type={switchType}
            placeholder={placeholder}
            value={value}
            id={id}
          />
          {name === 'password' && (
            <img
              className="rf-password-toggle"
              src={switchType === 'password' ? hidePassword : showPassword}
              onClick={(e) => {
                e.stopPropagation();

                setSwitchType((p) => (p === 'password' ? 'text' : 'password'));
              }}
              alt="Toggle password visibility"
            />
          )}
        </div>
        {errors[name] && (
          <div className="rf-error">
            <p className="rf-error--message">Minimum 6 character, one upper case and one number</p>
          </div>
        )}
      </label>
    </>
  );
}
