import GenericButton from './../generic_button/GenericButton';
import { useForm, FormProvider } from 'react-hook-form';
import { useState } from 'react';
import { useUser } from '../../api/auth';
import { useChangeUserPassword } from '../../queries/use_user';
import { useNotifications } from '../notifications/useNotifications';

import RfInput from './RfInput';

export default function AccountProfile() {
  const { notify } = useNotifications();
  const { changeUserPassword } = useChangeUserPassword();
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);

  const methods = useForm();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      setLoading(true);
      await changeUserPassword({
        id: userData._id,
        password: data.password,
      });
      notify('Password changed successfully.', 'success');
    } catch (error) {
      notify('Something went wrong. Please try again later.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="account-topbar"></div>
      <div
        style={{ width: '411px', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '20px' }}>
        <p
          style={{
            lineHeight: '20px',
            color: '#C4C4C4',
            fontSize: '14px',
            marginTop: '0',
            fontWeight: '700',
            fontFamily: 'Object Sans Thin',
          }}>
          This is your account profile. You can change your password here.
        </p>

        <FormProvider {...methods}>
          <form className="rf-form" onSubmit={methods.handleSubmit(onSubmit)}>
            <RfInput
              label="Email address"
              name="email"
              type="email"
              value={userData.email}
              disabled={true}
              id={'email'}
            />

            <RfInput
              label="Password"
              name="password"
              placeholder="Enter your new password"
              type="password"
              id={'password'}
              validate="password"
            />

            <div>
              <GenericButton
                topSeperator="true"
                loading={loading}
                label="Save"
                color="white"
                backgroundColor="black"
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
