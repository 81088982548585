import { useReducer } from 'react';
import { AVAILABLE_COLUMNS } from './mocks';

const CHANGE_FIELD_TYPE = 'CHANGE_FIELD_TYPE';
const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
const CHANGE_PREFIX = 'CHANGE_PREFIX';
const CREATE_FILTER = 'CREATE_FILTER';
const REMOVE_FILTER = 'REMOVE_FILTER';

const formatState = (initial) => {
  const hasConditions = initial.length > 0;

  return hasConditions
    ? initial
    : [
        {
          name: 'Product Name',
          conditions: AVAILABLE_COLUMNS['Product Name'].conditions,
          byCondition: '$eq',
          fieldType: 'text',
          value: '',
          prefix: '$and',
        },
      ];
};

function filtersManager(state, action) {
  const { type, payload } = action;
  const newFilters = [...state];

  switch (type) {
    case CHANGE_FIELD_TYPE:
      const newConditions = AVAILABLE_COLUMNS[payload.name].conditions;
      newFilters[payload.index] = {
        name: payload.name,
        conditions: newConditions,
        byCondition: newConditions[0].id,
        fieldType: AVAILABLE_COLUMNS[payload.name].fieldType,
        value: '',
        prefix: newFilters[payload.index].prefix,
      };
      return newFilters;
    case SET_FILTER_TYPE:
      newFilters[payload.index] = {
        ...newFilters[payload.index],
        byCondition: payload.byCondition,
        fieldType: payload.fieldType,
      };

      return newFilters;

    case SET_FIELD_VALUE:
      newFilters[payload.index] = {
        ...newFilters[payload.index],
        value: payload.value,
      };

      return newFilters;

    case CHANGE_PREFIX:
      newFilters[payload.index] = {
        ...newFilters[payload.index],
        prefix: payload.prefix,
      };

      return newFilters;

    case CREATE_FILTER:
      newFilters.push({
        name: 'Product Name',
        conditions: AVAILABLE_COLUMNS['Product Name'].conditions,
        byCondition: '$eq',
        fieldType: 'text',
        value: '',
        prefix: '$and',
      });
      return newFilters;

    case REMOVE_FILTER:
      newFilters.splice(payload.index, 1);
      return newFilters;

    default:
      return state;
  }
}

export const useFiltersManager = (initialState) => {
  const [state, dispatch] = useReducer(filtersManager, initialState, formatState);

  const changeFieldType = (index) => (name) => {
    dispatch({
      type: CHANGE_FIELD_TYPE,
      payload: { name, index },
    });
  };

  const setFilterType = (index) => (byCondition, fieldType) => {
    dispatch({
      type: SET_FILTER_TYPE,
      payload: { index, byCondition, fieldType },
    });
  };

  const setFieldValue = (index) => (value) => {
    dispatch({ type: SET_FIELD_VALUE, payload: { index, value } });
  };

  const changePrefix = (index) => (prefix) => {
    dispatch({ type: CHANGE_PREFIX, payload: { index, prefix } });
  };

  const createFilter = () => {
    dispatch({ type: CREATE_FILTER });
  };

  const removeFilter = (index) => () => {
    dispatch({ type: REMOVE_FILTER, payload: { index } });
  };

  return {
    filters: state,
    changeFieldType,
    setFilterType,
    setFieldValue,
    changePrefix,
    createFilter,
    removeFilter,
  };
};
