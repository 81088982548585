import { useState, useEffect } from 'react';
import { useActionsContext } from '../../../context/actions.context';
import { useProductsContext } from '../../../context/product.context';
import { throttle } from '../utils';
import { useTableNavigationContext } from '../../../context/table_navigation.context';

const CELL_HEIGHT = 35;

export default function Cell({
  sticky,
  children,
  styleOverrides,
  draggable = true,
  fieldName,
  defaultValue,
  id,
  setQuickViewVisible,
  onClick,
  rowIndex,
  columnIndex,
  ...props
}) {
  const { onDrag, onDrop } = useActionsContext();
  const { allProducts } = useProductsContext();
  const [dragging, setDragging] = useState(false);
  const [initYPosition, setInitYPosition] = useState(0);
  const [lastIndex, setLastIndex] = useState(id);
  const [dynamicBottom, setDynamicBottom] = useState(0);
  const [height, setHeight] = useState(4);
  const { activeCol, activeRow, handleCellClick, handleCellRef, isEditing } =
    useTableNavigationContext();
  const isActive = activeCol === columnIndex && activeRow === rowIndex;
  const handleClick = () => {
    handleCellClick(rowIndex, columnIndex);
    onClick && onClick();
  };

  const style = {
    ...styleOverrides,
    outlineWidth: isActive && isEditing ? '2px' : '1px',
    borderRadius: isActive && isEditing ? '5px' : 'none',
  };

  useEffect(() => {
    if (dragging && draggable) {
      const handleMouseMove = throttle((e) => {
        let current = document.elementFromPoint(e.clientX, e.clientY);
        let closest = current?.closest(`#${fieldName.replaceAll(' ', '_')}`);
        if (closest) {
          if (closest.dataset.row) {
            setLastIndex(closest.dataset.row);
          }
        }
        const diff = initYPosition - e.clientY;
        if (diff > 0) {
          setHeight(diff);
        } else {
          setDynamicBottom(diff);
          setHeight(Math.abs(diff) + CELL_HEIGHT);
        }
      }, 100);

      const handleMouseUp = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragging(true);
        await onDrop({ lastIndex, products: allProducts });
        setHeight(4);
        setDynamicBottom(0);
        setDragging(false);
      };

      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging, fieldName, initYPosition, lastIndex, onDrop, draggable]);

  return (
    <td
      tabIndex={0}
      id={draggable ? `${fieldName.replaceAll(' ', '_')}` : ''}
      data-portable={fieldName}
      data-row={id}
      className={`table_cell ${sticky ? 'sticky' : ''}`}
      style={style}
      onClick={handleClick}
      ref={isActive ? handleCellRef : null}
      {...props}>
      {draggable && (
        <div
          id={`${id}_handle`}
          style={{
            transform: dragging ? 'none' : `translate(40%, 50%)`,
            width: dragging ? '100%' : '4px',
            opacity: dragging ? 0.3 : 1,
            height: dragging ? height : '4px',
            bottom: dragging ? dynamicBottom : 0,
          }}
          onMouseDown={(e) => {
            setDragging(true);
            e.preventDefault();
            setInitYPosition(e.clientY);
            setLastIndex(id);
            onDrag({ index: id, field: fieldName, value: defaultValue });
          }}
        />
      )}
      {children}
    </td>
  );
}
