import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { authApiClient } from '../api';

const CATEGORIES_QUERY_KEY = 'CATEGORIES_QUERY';

const getCategories = () => authApiClient.get('/tag/category').then((res) => res.data);

export const useCategories = () => {
  const { data, isLoading } = useQuery([CATEGORIES_QUERY_KEY], getCategories, {
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => {
      return authApiClient.post(`tag/category`, data.category);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CATEGORIES_QUERY_KEY]);
      },
    }
  );

  return {
    updateCategoryData: mutateAsync,
    isLoading,
  };
};
