import './Account.scss';
import { useLocation } from 'react-router-dom';

export default function AccountSub({ accountSubVisible, handleActiveTabChange, activeRoute }) {
  const profileMenu = [
    {
      name: 'Description',
      active: activeRoute === '/account',
      onClick: () => handleActiveTabChange('account'),
    },
    {
      name: 'Image gallery',
      active: activeRoute === '/account/gallery',
      onClick: () => handleActiveTabChange('account/gallery'),
    },
  ];

  return (
    <div>
      <div className={`account-submenu ${accountSubVisible ? 'show' : ''}`}>
        <h2>Profile</h2>

        <div className="account-sub__section">
          {profileMenu.map((el) => {
            return (
              <div
                key={el.name}
                className={`account-sub__item ${el.active ? 'active' : ''}`}
                onClick={el.onClick}>
                {el.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
