export const CURRENCIES = [
  {
    name: '£ GBP',
    id: 'GBP',
  },
  {
    name: '$ USD',
    id: 'USD',
  },
  {
    name: '€ EUR',
    id: 'EUR',
  },
  {
    name: 'A$ AUD',
    id: 'AUD',
  },
];

export const SUITABILITY = [
  {
    name: 'Indoor',
  },
  {
    name: 'Outdoor',
  },
];

export const getMockRowData = (supplierId, name) => {
  return {
    'Product Name': 'New product',
    SupplierId: Boolean(name) ? { _id: supplierId, name } : null,
    'Product Images': [],
    'Product Group': 'none',
    Status: 'Draft',
    Styles: [],
    Options: [],
    Description: '',
    Categories: '',
    Colour: [],
    Material: [],
    Finish: [],
  };
};
