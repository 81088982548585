export const priceGuide = [
  {
    value: '£',
    label: '£',
  },
  {
    value: '££',
    label: '££',
  },
  {
    value: '£££',
    label: '£££',
  },
  {
    value: '££££',
    label: '££££',
  },
];
