import { useDropzone } from 'react-dropzone';
import GenericButton from '../generic_button/GenericButton';
import UploadCard from './imgs/upcard.svg';
import './FileUploader.css';

export default function FileUploader({ onDrop, accept, multiple, title, onClose }) {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    onDrop,
    multiple,
  });

  return (
    <div className="fu_container">
      <h4 className="fu_title">{title}</h4>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <img src={UploadCard} alt="upload" />
        <p className="fu_text">Select files to upload</p>
        <span className="fu_subtext">(or drag files here)</span>
      </div>
      <aside className="fu_buttons">
        <GenericButton
          onClick={onClose}
          label={'Cancel'}
          backgroundColor={'white'}
          color={'black'}
        />
        <GenericButton onClick={open} label={'Upload'} backgroundColor={'black'} color={'white'} />
      </aside>
    </div>
  );
}
