import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { authApiClient } from '../api';
import { useUser } from '../api/auth';

export const ALL_PRODUCTS_QUERY = 'ALL_PRODUCTS_QUERY';
const PRODUCT_GROUPS_QUERY_KEY = 'PRODUCT_GROUPS_QUERY';
export const VARIANTS_QUERY_KEY = 'VARIANTS_QUERY';

export const getProductsData = ({ page, itemsPerPage, searchText, filters, sort }) =>
  authApiClient

    .get(`/products/filter`, {
      params: {
        filter: filters,
        sort,
        page,
        perPage: itemsPerPage,
        searchQuery: searchText,
      },
    })

    .then((res) => {
      const data = res.data;

      return { data: data.data, meta: data.meta };
    })
    .catch((err) => {
      throw err;
    });

export const useRemoveProducts = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.delete('/products', { data: { idList: data } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ALL_PRODUCTS_QUERY]);
      },
    }
  );

  return {
    removeProducts: mutateAsync,
    isLoading,
  };
};

export const useProductsData = ({ itemsPerPage, searchText, page, filters, sort, enabled }) => {
  const {
    data: result,
    isLoading,
    isPreviousData,
  } = useQuery(
    [ALL_PRODUCTS_QUERY, searchText, itemsPerPage, page, filters, sort],
    () => getProductsData({ itemsPerPage, page, searchText, filters, sort }),
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      enabled,
    }
  );

  return {
    isReady: !isLoading,
    data: result?.data ?? [],
    meta: result?.meta ?? {},
    hasNextPage: !isPreviousData && page !== result?.meta?.totalPages - 1,
    hasPreviousPage: !isPreviousData && page > 0,
    isPreviousData,
  };
};

export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => authApiClient.put(`products/${data.id}`, data.product),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PRODUCT_GROUPS_QUERY_KEY]);
        queryClient.invalidateQueries([VARIANTS_QUERY_KEY]);
        queryClient.invalidateQueries([ALL_PRODUCTS_QUERY]);
      },
    }
  );

  return {
    updateProductData: mutateAsync,
    isLoading,
  };
};

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (data) => {
      return authApiClient.post(`/products`, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ALL_PRODUCTS_QUERY]);
      },
    }
  );

  return {
    createProduct: mutateAsync,
    isLoading,
  };
};
