import SearchIcon from './imgs/search.svg';

export default function InputText({
  placeholder,
  label,
  type,
  error,
  errorMessage,
  paddingLeft,
  onChange,
  search,
  fontSize,
  inputHeight,
}) {
  return (
    <div
      style={{
        width: '100%',
        marginBottom: '18px',
      }}>
      <label
        style={{
          fontSize: '14px',
          marginBottom: '5px',
          paddingLeft: paddingLeft || '4px',
          display: !label && 'flex',
        }}>
        {label}
        <br />
        <input
          onChange={onChange || undefined}
          className="generic-input"
          style={{
            width: '100%',
            height: inputHeight || '50px',
            fontSize: fontSize || '16px',
            border: error ? '1px solid #E17B7B' : '1px solid #D5D4D4',
            borderRadius: !search ? '3px' : '3px 0px 0px 3px',
            borderRight: search ? 'none' : error ? '1px solid #E17B7B' : '1px solid #D5D4D4',
            paddingLeft: '10px',
            outline: 'none',
          }}
          type={type}
          placeholder={placeholder}
        />{' '}
        {search && (
          <img
            src={SearchIcon}
            style={{
              border: '1px solid #D5D4D4',
              borderRadius: '0px 3px 3px 0px',
              borderLeft: 'none',
              paddingRight: '10px',
            }}
          />
        )}
      </label>
      {error && (
        <div
          style={{
            width: '100%',
            fontSize: '12px',
            backgroundColor: '#E17B7B',
            borderRadius: '0px 0px 3px 3px',
            color: '#ffffff',
            padding: '4px 5px 4px 5px',
          }}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}
