import { useEffect, useState } from 'react';
// import "./Account.scss";
import { signOut } from '../../api/auth';
import { useNavigate } from 'react-router-dom';

export default function SettingsSub({ settingsVisible, handleActiveTabChange }) {
  const navigate = useNavigate();

  useEffect(() => {
    setActiveMenu('Personal details');
  }, [settingsVisible]);

  const [activeMenu, setActiveMenu] = useState('Personal details');

  const PersonalMenu = [
    {
      name: 'Personal details',
      active: false,
      onClick: () => {
        // setProfileImageGallery(false);
        setActiveMenu('Personal details');
      },
    },
  ];

  const handleSignOut = async () => {
    await signOut();
    await navigate('/');
    return navigate(0);
  };

  return (
    <div>
      <div className={`account-submenu ${settingsVisible ? 'show' : ''}`}>
        <h2>Account</h2>

        <div className="account-sub__section">
          {PersonalMenu.map((el) => {
            return (
              <div
                className={`account-sub__item ${activeMenu == el.name ? 'active' : ''}`}
                onClick={el.onClick}
                key={el.name}>
                {el.name}
              </div>
            );
          })}
        </div>
        <div className="account-sub__section">
          <div className="account-sub__item account-sub__item--error" onClick={handleSignOut}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}
