import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useCallbackRef } from '../hooks/useCallbackRef';

const TableNavigationContext = createContext();

function TableNavigationWrapper({ maxRows, maxCols, children }) {
  const [activeCol, setActiveCol] = useState(-1);
  const [activeRow, setActiveRow] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [editingLocked, setEditingLocked] = useState(false);
  const isNavigating = maxRows >= 0 && maxCols >= 0;
  const { ref: cellRef, handleRef: handleCellRef } = useCallbackRef();

  const handleCellClick = useCallback((rowIndex, columnIndex) => {
    setActiveRow(rowIndex);
    setActiveCol(columnIndex);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (isNavigating) {
        const { key } = e;
        switch (key) {
          case 'ArrowUp':
            if (!isEditing && activeRow > 0) {
              e.preventDefault();
              setActiveRow(activeRow - 1);
            }
            break;
          case 'ArrowDown':
            if (!isEditing && activeRow < maxRows) {
              e.preventDefault();
              setActiveRow(activeRow + 1);
            }
            break;
          case 'ArrowRight':
            if (!isEditing && activeCol < maxCols) {
              e.preventDefault();
              setActiveCol(activeCol + 1);
            }
            break;
          case 'Tab':
            if (!isEditing && activeCol < maxCols) {
              e.preventDefault();
              setActiveCol(activeCol + 1);
            }
            break;
          case 'ArrowLeft':
            if (!isEditing && activeCol > 0) {
              e.preventDefault();
              setActiveCol(activeCol - 1);
            }
            break;
          case 'Enter':
            const isCheckboxCell = Array.from(e?.target?.children)?.some(
              (child) => child?.className === 'checkbox_container'
            );

            if (isEditing && !editingLocked) {
              return setIsEditing(false);
            }
            if (isNavigating && !isCheckboxCell) {
              return setIsEditing(true);
            }
            break;
          case 'Escape':
            if (isEditing) {
              setIsEditing(false);
              setEditingLocked(false);
            }
            break;
          default:
            break;
        }
      }
    },
    [activeCol, activeRow, isEditing, isNavigating, maxCols, maxRows]
  );

  const handleDoubleClick = useCallback(() => {
    setIsEditing(!isEditing);
    setEditingLocked(false);
  }, [isEditing, isNavigating]);

  const handleSingleClick = useCallback(() => {
    if (!editingLocked) setIsEditing(false);
  }, [isEditing, isNavigating]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('dblclick', handleDoubleClick);
    window.addEventListener('mousedown', handleSingleClick);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('dblclick', handleDoubleClick);
      window.removeEventListener('mousedown', handleSingleClick);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (isNavigating && cellRef && !isEditing) {
      cellRef.focus();
    }
  }, [isNavigating, cellRef, isEditing]);

  return (
    <TableNavigationContext.Provider
      value={{
        activeCol,
        activeRow,
        handleCellClick,
        handleCellRef,
        isEditing,
        setIsEditing,
        setEditingLocked,
      }}>
      {children}
    </TableNavigationContext.Provider>
  );
}

const useTableNavigationContext = () => useContext(TableNavigationContext);

export { TableNavigationWrapper, useTableNavigationContext };
