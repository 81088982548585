import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

export const VARIANTS_QUERY_KEY = 'VARIANTS_QUERY';
export const PRODUCT_GROUPS_QUERY_KEY = 'PRODUCT_GROUPS_QUERY';

const getVariants = (groupId) =>
  authApiClient.get(`/productgroups/${groupId}`).then((res) => res.data);

export const useVariants = (groupId, enabled = true) => {
  const { data, isLoading, isRefetching } = useQuery(
    [VARIANTS_QUERY_KEY, groupId],
    () => getVariants(groupId),
    {
      refetchOnWindowFocus: true,
      enabled,
      select: (data) => {
        const combinedOptions = data['available_variants'].map((variant) => {
          const variantObject = variant.reduce(
            (prev, curr) => {
              if (Object.keys(curr)[0]) {
                const currentOption = Object.keys(curr)[0];
                const currentOptionValue = curr[currentOption];
                return {
                  data: [
                    ...prev.data,
                    { name: currentOption, option_values: [currentOptionValue] },
                  ],
                  tags: [...prev.tags, currentOptionValue],
                };
              } else {
                return {
                  data: [...prev.data],
                  tags: [...prev.tags],
                };
              }
            },
            { data: [], tags: [] }
          );

          return {
            name: variantObject.tags.join(' '),
            data: variantObject.data,
            tags: variantObject.tags,
          };
        });

        const selectedOptions = data?.['selected_variants']
          ?.filter((item) => item !== null)
          ?.map((variant) => variant.map((item) => item?.option_values?.[0])?.join(' '));

        const notAvailableOptions = combinedOptions.filter((option) =>
          selectedOptions.includes(option.name)
        );

        const availableOptions = combinedOptions.map((option) => {
          return !selectedOptions.includes(option.name)
            ? { ...option, disabled: false }
            : { ...option, disabled: true };
        });

        return {
          combinedOptions,
          availableOptions,
          notAvailableOptions,
          selected: data?.['selected_variants']?.filter((el) => el !== null),
        };
      },
    }
  );
  return { data, isLoading, isRefetching };
};
