import AccountSub from '../account/AccountSub';
import LeftMenu from '../leftmenu/LeftMenu';
// import Spinner from "../spinner/Spinner";
import TopBarMenu from '../top_bar_menu/TopBarMenu';
import '../../assets/style/main.scss';
import SettingsSub from '../settings/SettingsSub';

export default function Layout({
  handleActiveTabChange,
  accountSubVisible,
  settingsVisible,
  activeTab,
  activeRoute,
  setMenuNewVisible,
  menuNewVisible,
  children,
}) {
  return (
    <div style={{ display: 'flex', backgroundColor: 'white', width: '100%' }}>
      <>
        <div className="left-menu-wrapper">
          <LeftMenu
            activeTab={activeTab}
            handleActiveTabChange={handleActiveTabChange}
            accountSubVisible={accountSubVisible}
            menuNewVisible={menuNewVisible}
            setMenuNewVisible={setMenuNewVisible}
          />

          <AccountSub
            accountSubVisible={accountSubVisible}
            handleActiveTabChange={handleActiveTabChange}
            activeRoute={activeRoute}
          />

          <SettingsSub
            handleActiveTabChange={handleActiveTabChange}
            settingsVisible={settingsVisible}
          />
        </div>
        {/* {!accountSubVisible && !settingsVisible && (
          <TopBarMenu
            products={allProducts ?? []}
            rowsSelected={rowsSelected}
            menuNewVisible={menuNewVisible}
            setRowsSelected={setRowsSelected}
          />
        )} */}
      </>

      <div
        className={'main__wrapper'}
        style={{ overflow: activeTab == 'inventory' ? 'hidden' : 'auto' }}>
        {children}
      </div>
    </div>
  );
}
