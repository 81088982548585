import { useState } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Filters } from '../organizing_data';
import { useProductsContext } from '../../context/product.context';

export default function FilterButton({ icon, danger, hideSuppliers }) {
  const { filters, saveFilters } = useProductsContext();

  const [clicked, setClicked] = useState(false);

  const { ref } = useClickOutside(() => {
    setClicked(false);
  });

  const activeItemsLength = filters.length;

  return (
    <div className={`button-filter__wrapper ${activeItemsLength > 0 ? 'active' : ''}`}>
      <div
        className={`button-filter ${danger ? 'button-filter--danger' : ''} ${
          clicked ? 'active ' : ''
        }`}
        onClick={() => {
          setClicked((p) => !p);
        }}>
        <img src={icon} alt="Icon" />
        {activeItemsLength > 0 && (
          <p>{`${filters[0].name} ${activeItemsLength > 1 ? `+${activeItemsLength - 1}` : ''}`}</p>
        )}
      </div>

      {clicked && (
        <div ref={ref} className={`button-filter__dropdown`}>
          <Filters
            initialConditions={filters}
            onApply={(data) => {
              saveFilters(data);
              setClicked(false);
            }}
            hideSuppliers={hideSuppliers}
          />
        </div>
      )}
    </div>
  );
}
