export const DropdownFooter = ({ onApply, onAdd, createDisabled = false }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 13px' }}>
      <button onClick={onAdd} className="add_condition_btn" disabled={createDisabled}>
        Add condition +
      </button>
      <button onClick={onApply} className="apply_filter_btn">
        Apply
      </button>
    </div>
  );
};
