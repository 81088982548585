import Upload from '../imgs/upload.svg';

export const UploadMore = ({ onUploadMore }) => {
  return (
    <div role="button" onClick={onUploadMore} className="upload_more">
      <img alt="upload more" src={Upload} />
      Upload More
    </div>
  );
};
