import { useQuery } from '@tanstack/react-query';
import { authApiClient } from '../api';

export const PRODUCT_GROUPS_PRODUCTS_QUERY_KEY = 'PRODUCT_GROUPS_PRODUCTS_QUERY';
const getProductGroupProducts = (groupId) =>
  authApiClient.get(`/productgroups/products/${groupId}`).then((res) => res.data);

export const useProductGroupProductsData = (groupId, enabled = true) =>
  useQuery([PRODUCT_GROUPS_PRODUCTS_QUERY_KEY, groupId], () => getProductGroupProducts(groupId), {
    refetchOnWindowFocus: true,
    enabled,
  });
