import Checkbox from '../../checkbox/Checkbox';

export default function Confirmation({ showError, label, onChange }) {
  return (
    <div
      style={{
        fontSize: '14px',
        backgroundColor: '#F9F9F9',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        border: showError ? '1px solid red' : 'none',
      }}>
      <Checkbox
        variant="tertiary"
        label={label}
        id="manageOptions"
        defaultChecked={false}
        onChange={onChange}
      />
    </div>
  );
}
