import Arc from './imgs/arc.svg';

export default function Spinner({ label, styles }) {
  return (
    <div
      style={{
        marginLeft: '',
        marginTop: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...styles,
      }}>
      <img className="spinner" src={Arc} />
      <div style={{ fontSize: '12px' }}>{label}</div>
    </div>
  );
}
