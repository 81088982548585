import { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import '../../assets/style/select.scss';
import TriangleDownIcon from './imgs/triangledown.svg';
import Search from './imgs/search.svg';
import { useClickOutside } from '../../hooks/useClickOutside';

export default function SelectStyled({
  openOnEnter,
  setOpenOnEnter,
  label,
  updateProductValue,
  isAdmin,
  status,
}) {
  const [value, setValue] = useState([]);
  const { ref } = useClickOutside(() => {
    setOpenOnEnter(false);
  });

  const colourTable = [
    { value: '#DA7B7B', label: 'Rejected' },

    { value: '#BA9BE2', label: 'Ready for Review' },
    { value: '#AECE8E', label: 'Published' },
    { value: '#E7AA62', label: 'Draft' },
  ];

  useEffect(() => {
    const matchingItem = colourTable.find((item) => item.label === label);
    if (matchingItem) {
      setValue(matchingItem);
    }
  }, [label]);

  if (openOnEnter) {
    const onKeyDown = (e) => {
      // eslint-disable-next-line default-case
      switch (e.key) {
        case 'Escape':
          e.preventDefault();
          setOpenOnEnter(false);
          document.addEventListener('keydown', onKeyDown);
          return;
        case 'Tab':
          setOpenOnEnter(false);
          document.addEventListener('keydown', onKeyDown);
      }
    };
    document.addEventListener('keydown', onKeyDown);
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={Search} alt="Search Icon" />
      </components.DropdownIndicator>
    );
  };

  const styling = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: color,
  });

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'transparent' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? undefined : isSelected ? data.value : data.value,

        color: isDisabled ? '#ccc' : isSelected ? ('white' > 2 ? 'white' : 'black') : 'black',

        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? data.value : data.value) : undefined,
        },
      };
    },
    input: (styles) => ({ ...styles, ...styling('transparent') }),
    placeholder: (styles) => ({ ...styles, ...styling('transparent') }),
    singleValue: (styles, { data }) => ({ ...styles, ...styling(data.value) }),
  };

  return (
    <div className="select-value__wrapper" ref={ref}>
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <p className={`select-value`} style={{ backgroundColor: value.value }}>
          {value.label ? value.label : 'Select'}
        </p>
        {isAdmin && <img src={TriangleDownIcon} alt="Triangle Down Icon" />}
      </div>

      {openOnEnter && (
        <Select
          options={colourTable}
          menuIsOpen
          autoFocus
          className="select-wrapper"
          placeholder="Search"
          onChange={async (newValue) => {
            setValue(newValue);
            setOpenOnEnter(false);
            await updateProductValue('Status', newValue.label);
          }}
          components={{ DropdownIndicator }}
          classNamePrefix="select-wrapper"
          styles={colourStyles}
        />
      )}
    </div>
  );
}
