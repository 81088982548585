import { Toaster } from 'react-hot-toast';
import { SuccessIcon, ErrorIcon } from './parts';

export const Notification = () => {
  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerStyle={{}}
      toastOptions={{
        style: {
          padding: '10px, 9px',
          fontSize: '12px',
          color: '#fff',
        },

        // Default options for specific types
        success: {
          style: {
            backgroundColor: '#5AAC9D',
          },
          icon: <SuccessIcon />,
        },
        error: {
          style: {
            backgroundColor: '#D66060',
          },
          icon: <ErrorIcon />,
        },
      }}
    />
  );
};
