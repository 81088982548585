import { useState } from 'react';
import CloseHandle from '../../imgs/close.svg';
import TagGroup from '../../../tag_group/TagGroup';
import FieldControl from './FieldControl';
import { useFocus } from '../../../../hooks/useFocus';

export default function OptionRow({
  name = '',
  values = [],
  onOptionRemove,
  onNameChange,
  onOptionsChange,
  optionNames,
  onTagRemove,
}) {
  const [inputRef, setInputFocus] = useFocus();

  const [validationErrors, setValidationErrors] = useState({});

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '5px',
          marginBottom: '5px',
        }}>
        <FieldControl error={validationErrors?.name}>
          <input
            placeholder="Name"
            defaultValue={name}
            onChange={(e) => {
              if (Boolean(validationErrors?.name)) {
                setValidationErrors((prev) => {
                  const { name, ...rest } = prev;
                  return rest;
                });
              }
              onNameChange(e);
            }}
            onBlur={(e) => {
              if (optionNames.filter((el) => el === e.target.value).length > 1) {
                return setValidationErrors((prev) => {
                  return {
                    ...prev,
                    name: 'That option name is already in use',
                  };
                });
              }
            }}
            style={{
              width: '100px',
              maxWidth: '100px',
              padding: '8px 10px',
              borderRadius: '3px',
              border: '1px solid #D5D4D4',
              height: '40px',
              boxSizing: 'border-box',
            }}
          />
        </FieldControl>
        <FieldControl error={validationErrors?.options}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setInputFocus();
            }}
            style={{
              width: '260px',
              padding: '8px 10px',
              borderRadius: '3px',
              border: '1px solid #D5D4D4',
              height: '40px',
              boxSizing: 'border-box',
              display: 'flex',
              scrollbarWidth: 'none',
              overflow: 'auto',
            }}
            className="no-scrollbar">
            <div
              style={{
                marginRight: '5px',
                maxWidth: '180px',
                minWidth: 'min-content',
              }}>
              <TagGroup
                showCloseHandles={inputRef?.current === document.activeElement}
                backspaceRemoves
                variant="secondary"
                tags={values}
                key={values?.length}
                onTagRemove={onTagRemove}
              />
            </div>
            <input
              ref={inputRef}
              style={{
                outline: 'none',
                border: 'none',
              }}
              onChange={() => {
                if (Boolean(validationErrors?.options)) {
                  setValidationErrors((prev) => {
                    const { options, ...rest } = prev;
                    return rest;
                  });
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (values.includes(e.target.value)) {
                    setValidationErrors((prev) => {
                      const { options, ...rest } = prev;
                      return {
                        ...rest,
                        options: 'That option already exists',
                      };
                    });
                    return;
                  }
                  if (e.target.value === '') return;
                  onOptionsChange(name, e.target.value);
                  e.target.value = '';
                }
              }}
              onBlur={(e) => {
                if (e.target.value === '') return;

                if (values.includes(e.target.value)) {
                  setValidationErrors((prev) => {
                    const { options, ...rest } = prev;
                    return {
                      ...rest,
                      options: 'That option already exists',
                    };
                  });
                  return;
                }
                onOptionsChange(name, e.target.value);
                e.target.value = '';
              }}
            />
          </div>
        </FieldControl>
        <img
          src={CloseHandle}
          role="button"
          alt="remove option"
          style={{
            cursor: 'pointer',
            marginTop: '16px',
            alignSelf: 'start',
          }}
          onClick={onOptionRemove}
        />
      </div>
    </>
  );
}
